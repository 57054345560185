import React, { useState, useEffect } from 'react';
import { 
    Alert,
    Box,
    Button, 
    Container, 
    CssBaseline, 
    Paper, 
    Snackbar, 
    TextField, 
    Toolbar, 
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Pagination,
    Divider,
    IconButton,
    Tooltip,
    CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { BASE_URL } from '../../Constants.js'; // Adjust the import path as needed
import { getToken, getUserId } from '../../auth/auth.js'; // Adjust the import path as needed
console.log("BASE_URL",BASE_URL);

function LMSAddVideos() {
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [transcript, setTranscript] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [videos, setVideos] = useState([]);
    const [page, setPage] = useState(1);
    const [videosPerPage] = useState(5);
    const [expandedCourse, setExpandedCourse] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const fetchCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch courses');
            }
            const data = await response.json();
            setCourses(data);
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const fetchVideos = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/lmsvideos`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch videos');
            const data = await response.json();
            setVideos(data);
        } catch (error) {
            console.error('Error fetching videos:', error);
        }
    };

    useEffect(() => {
        fetchVideos();
        fetchCourses();
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'application/pdf' || file.type === 'text/plain')) {
            setTranscript(file);
        } else {
            setError('Please upload a PDF or TXT file');
            event.target.value = null;
        }
    };

    const handleEdit = (video) => {
        setEditMode(true);
        setSelectedVideo(video);
        setTitle(video.title);
        setUrl(video.url);
        
        // Find the course ID that matches the video's associated_course name
        const course = courses.find(c => c.course_name === video.associated_course);
        setSelectedCourse(course ? course.id : '');
        
        // If there's a transcript file, set it
        if (video.transcript_file) {
            setTranscript({ name: video.transcript_file });
        }
    };

    const handleDelete = async (videoId) => {
        if (!window.confirm('Are you sure you want to delete this video?')) return;

        setIsDeleting(true);
        try {
            const response = await fetch(`${BASE_URL}/api/lmsvideo/${videoId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });

            if (!response.ok) throw new Error('Failed to delete video');
            
            setSuccess('Video deleted successfully!');
            fetchVideos();
            resetForm();
        } catch (error) {
            setError(error.message);
        } finally {
            setIsDeleting(false);
        }
    };

    const resetForm = () => {
        setTitle('');
        setUrl('');
        setSelectedCourse('');
        setTranscript(null);
        setEditMode(false);
        setSelectedVideo(null);
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) fileInput.value = '';
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess('');

        if (!title.trim() || !url.trim() || !selectedCourse) {
            setError('Please fill in all required fields and select a course');
            setIsLoading(false);
            return;
        }

        const selectedCourseObj = courses.find(course => course.id === selectedCourse);
        const formData = new FormData();
        formData.append('title', title.trim());
        formData.append('url', url.trim());
        formData.append('who_created', getUserId());
        formData.append('associated_course', selectedCourseObj.course_name);
        
        // Format the date to MySQL datetime format (YYYY-MM-DD HH:mm:ss)
        const now = new Date();
        const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');
        formData.append('date_created', formattedDate);

        if (transcript) {
            formData.append('transcript', transcript);
        }

        for (let pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        try {
            const endpoint = editMode 
                ? `${BASE_URL}/api/lmsvideo/${selectedVideo.id}`
                : `${BASE_URL}/api/submitlmsvideo`;
            
            const method = editMode ? 'PUT' : 'POST';

            const response = await fetch(endpoint, {
                method,
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                },
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || `Failed to ${editMode ? 'update' : 'add'} video`);
            }

            setSuccess(editMode ? 'Video edited successfully!' : 'Video added successfully!');
            resetForm();
            fetchVideos();
        } catch (error) {
            console.error('Submission error:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const organizeVideosByCourse = () => {
        const organized = {};
        videos.forEach(video => {
            if (!organized[video.associated_course]) {
                organized[video.associated_course] = [];
            }
            organized[video.associated_course].push(video);
        });
        return organized;
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleAccordionChange = (course) => (event, isExpanded) => {
        setExpandedCourse(isExpanded ? course : null);
        setPage(1); // Reset to first page when changing course
    };

    const renderVideoList = () => {
        const videosByCourse = organizeVideosByCourse();
        
        return (
            <Paper sx={{ mt: 4, p: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Course Videos
                </Typography>
                
                {Object.entries(videosByCourse).map(([course, courseVideos]) => {
                    // Calculate pagination for current course
                    const totalPages = Math.ceil(courseVideos.length / videosPerPage);
                    const startIndex = (page - 1) * videosPerPage;
                    const paginatedVideos = courseVideos.slice(
                        startIndex,
                        startIndex + videosPerPage
                    );

                    return (
                        <Accordion 
                            key={course}
                            expanded={expandedCourse === course}
                            onChange={handleAccordionChange(course)}
                            sx={{ mb: 2 }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{ 
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                                }}
                            >
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    {course} ({courseVideos.length} videos)
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: 0 }}>
                                {paginatedVideos.map((video) => (
                                    <Box 
                                        key={video.id}
                                        sx={{
                                            p: 2,
                                            '&:not(:last-child)': {
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.02)'
                                            }
                                        }}
                                    >
                                        <Box sx={{ 
                                            display: 'flex', 
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                            <Box>
                                                <Typography variant="subtitle1">
                                                    {video.title}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Tooltip title="Watch Video">
                                                    <IconButton 
                                                        size="small"
                                                        color="primary"
                                                        href={video.url}
                                                        target="_blank"
                                                    >
                                                        <YouTubeIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                    <IconButton 
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => handleEdit(video)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <IconButton 
                                                        size="small"
                                                        color="error"
                                                        onClick={() => handleDelete(video.id)}
                                                        disabled={isDeleting}
                                                    >
                                                        {isDeleting ? (
                                                            <CircularProgress size={20} color="error" />
                                                        ) : (
                                                            <DeleteIcon />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                                {totalPages > 1 && (
                                    <Box sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'center',
                                        p: 2,
                                        borderTop: '1px solid rgba(0, 0, 0, 0.12)'
                                    }}>
                                        <Pagination 
                                            count={totalPages}
                                            page={page}
                                            onChange={handlePageChange}
                                            color="primary"
                                            size="small"
                                        />
                                    </Box>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Paper>
        );
    };

    return (
        <Box 
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'auto',
            }}
        >
            <CssBaseline />
            <Toolbar />
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        {editMode ? 'Edit Video' : 'Add New Video'}
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <FormControl fullWidth margin="normal" required={!editMode}>
                            <InputLabel id="associated-course-label">Associated Course</InputLabel>
                            <Select
                                labelId="associated-course-label"
                                id="associatedCourse"
                                value={selectedCourse}
                                onChange={(e) => setSelectedCourse(e.target.value)}
                                label="Associated Course"
                                required={!editMode}
                            >
                                <MenuItem value="">
                                    <em>Select a course</em>
                                </MenuItem>
                                {courses.map((course) => (
                                    <MenuItem key={course.id} value={course.id}>
                                        {course.course_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            margin="normal"
                            required={!editMode}
                            fullWidth
                            id="title"
                            label="Video Title"
                            name="title"
                            autoFocus
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required={!editMode}
                            fullWidth
                            id="url"
                            label="Video URL"
                            name="url"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                        />

                        <input
                            accept=".pdf,.txt"
                            style={{ display: 'none' }}
                            id="transcript-file"
                            type="file"
                            onChange={handleFileChange}
                            required={!editMode}
                        />
                        <label htmlFor="transcript-file">
                            <Button
                                component="span"
                                fullWidth
                                variant="outlined"
                                sx={{ mt: 2, mb: 1 }}
                            >
                                {editMode 
                                    ? (transcript ? transcript.name : 'New Transcript (PDF or TXT)')
                                    : (transcript ? transcript.name : `Upload Transcript (PDF or TXT) *`)}
                            </Button>
                        </label>

                        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Processing...' : (editMode ? 'Update Video' : 'Add Video')}
                            </Button>

                            {editMode && (
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={resetForm}
                                    color="secondary"
                                >
                                    Cancel Edit
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Paper>

                {renderVideoList()}

                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                    <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>

                <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess('')}>
                    <Alert onClose={() => setSuccess('')} severity="success" sx={{ width: '100%' }}>
                        {success}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
}

export default LMSAddVideos;
