import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyToken } from './auth';
import { toast } from 'react-toastify';

function ProtectedRoute({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuth = verifyToken();

    useEffect(() => {
        if (!isAuth) {
            navigate('/login', { replace: true, state: { from: location } });
            toast.error("Please log in to access this page", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,  // Auto close after 2 seconds
            });
        }
    }, [isAuth, navigate, location]);

    return isAuth ? children : null;
}

export default ProtectedRoute;

