import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { ToastContainer, toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';
import sortData from '../common/sortColumns';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function ManageStrains() {
  const [strainData, setStrainData] = React.useState([]); 
  const [editingId, setEditingId] = React.useState(null);
  const [originalData, setOriginalData] = React.useState({});
  const [tempData, setTempData] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [newStrainData, setNewStrainData] = React.useState({ strain_name: "", days_to_yield_inoc: "", days_to_yield_cut: "", expected_yield: "" });
  const [sortField, setSortField] = React.useState('');

  const [sortDirection, setSortDirection] = React.useState({
    strain_name: 'desc',
    days_to_yield_inoc: 'desc',
    days_to_yield_cut: 'desc',
    expected_yield: 'desc',
  }); 

  const sortedStrainData = sortData(strainData, sortField, sortDirection[sortField]);
  const token = getToken(); 

  const fetchStrains = () => {
    fetch(`${BASE_URL}/api/readstrains`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setStrainData(jsonData);
          console.log(jsonData);
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  React.useEffect(() => {
    fetchStrains(); // Call fetchstrains on initial render    
  }, []); 

  React.useEffect(() => {
    if (editingId !== null) {
      const rowData = strainData.find(strain => strain.strain_id === editingId);
      setOriginalData(rowData || {});
      setTempData(rowData || {});
    }
  }, [editingId, strainData]);

  const editStrain = (id) => {
    setEditingId(id);
  }

  const saveChanges = async (id) => {
    // Merge originalData with tempData, tempData values will overwrite originalData
    const dataToSend = {...originalData, ...tempData};

    try {
      const response = await fetch(`${BASE_URL}/api/updatestrain/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend), // the updated data
      });
  
      const result = await response.json();
  
      if (result.message === 'Data updated successfully') {
        // If update was successful
        setEditingId(null);
        setTempData({});
        fetchStrains(); // refresh data
      } else {
        // If the update failed, handle it here
        console.error('Failed to update data', result.error);
      }
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };
  
  const cancelEditing = () => {
    setEditingId(null);
    setTempData({});
  };

  const handleAddStrain = () => {
    console.log(newStrainData.strain_name);
    fetch(`${BASE_URL}/api/addstrain`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        strain_name: newStrainData.strain_name,
        days_to_yield_inoc: newStrainData.days_to_yield_inoc,
        expected_yield: newStrainData.expected_yield,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.strain_id) {
          setStrainData([...strainData, { strain_id: data.strain_id, ...newStrainData }]);
        }
        setIsAdding(false);
        setNewStrainData({ strain_name: "", days_to_yield_inoc: "", expected_yield: "" });
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const handleSort = (field) => {
    // Toggle the sort direction for the specific field
    const newDirection = sortDirection[field] === 'asc' ? 'desc' : 'asc';
  
    // Update the sort directions state
    setSortDirection({
      ...sortDirection,
      [field]: newDirection
    });
  
    // Set the sort field
    setSortField(field);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />
        <ToastContainer position="top-center" />
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>

              <Box sx={{
                display: 'flex',
                justifyContent: 'center', 
              }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  All strains
                </Typography>
              </Box>

              <TableContainer component={Paper} style={{ maxHeight: '70vh', overflow: 'auto' }}>
                <Table aria-label="strain table" stickyHeader>

                  <TableHead>
                    <TableRow>

                      <TableCell
                        onClick={() => handleSort('strain_name')}
                      >
                        Strain {sortDirection['strain_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      <TableCell 
                        onClick={() => handleSort('days_to_yield_inoc')}
                      >
                        Colonization {sortDirection['days_to_yield_inoc'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      <TableCell
                        onClick={() => handleSort('days_to_yield_cut')}
                      >
                        Cut to yield {sortDirection['days_to_yield_cut'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      <TableCell
                        onClick={() => handleSort('expected_yield')}
                      >
                        Expected yield (lbs) {sortDirection['expected_yield'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>


                      <TableCell>Edit</TableCell>
                      {/* <TableCell>Delete</TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {sortedStrainData.map((row, index) => (

                      <TableRow key={index}>

                        <TableCell>
                          {editingId === row.strain_id ? 
                            <input 
                                value={tempData?.strain_name ?? row.strain_name} 
                                onChange={e => setTempData({...tempData, strain_name: e.target.value})}
                            /> :
                            row.strain_name
                          }
                        </TableCell>

                        <TableCell>
                          {editingId === row.strain_id ? 
                            <input 
                                value={tempData?.days_to_yield_inoc ?? row.days_to_yield_inoc} 
                                onChange={e => setTempData({...tempData, days_to_yield_inoc: e.target.value})}
                            /> :
                            row.days_to_yield_inoc
                          }
                        </TableCell>

                        <TableCell>
                          {editingId === row.strain_id ? 
                            <input 
                                value={tempData?.days_to_yield_cut ?? row.days_to_yield_cut} 
                                onChange={e => setTempData({...tempData, days_to_yield_cut: e.target.value})}
                            /> :
                            row.days_to_yield_cut 
                          }
                        </TableCell>

                        <TableCell>
                          {editingId === row.strain_id ? 
                            <input 
                                value={tempData?.expected_yield ?? row.expected_yield} 
                                onChange={e => setTempData({...tempData, expected_yield: e.target.value})}
                            /> :
                            row.expected_yield
                          }
                        </TableCell>
                        
                        <TableCell>  
                          {editingId === row.strain_id ? 
                            <>
                              <Button onClick={() => saveChanges(row.strain_id)}>Save</Button>
                              <Button onClick={() => cancelEditing()}>Cancel</Button>
                            </> :
                            <>
                              <IconButton aria-label="edit" onClick={() => editStrain(row.strain_id)}>
                                <EditIcon />
                              </IconButton>
                            </>
                          }
                        </TableCell>

                      </TableRow>
                    ))} 

                    {isAdding && (
                      <TableRow>
                        <TableCell>
                          <input 
                            value={newStrainData.strain_name} 
                            onChange={e => setNewStrainData({ ...newStrainData, strain_name: e.target.value })}
                          />
                        </TableCell>
                        <TableCell>
                          <input 
                            value={newStrainData.days_to_yield_inoc} 
                            onChange={e => setNewStrainData({ ...newStrainData, days_to_yield_inoc: e.target.value })}
                          />
                        </TableCell>
                        <TableCell>
                          <input 
                            value={newStrainData.expected_yield} 
                            onChange={e => setNewStrainData({ ...newStrainData, expected_yield: e.target.value })}
                          />
                        </TableCell>
                        <TableCell>
                          <Button onClick={handleAddStrain}>Save</Button>
                          <Button onClick={() => setIsAdding(false)}>Cancel</Button>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )} 

                  </TableBody>

                </Table>
              </TableContainer>

              {/* Add strain button */}
              <Box margin="1em" display="flex" justifyContent="flex-end">
                {!isAdding && (
                  <Button 
                    variant="contained" 
                    onClick={() => setIsAdding(prev => !prev)} 
                  >
                    Add strain
                  </Button>
                )}
              </Box>
                
            </Grid>
          </Grid>
        </Container>
        
    </Box>
  );
}

export default ManageStrains;