import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

function CustomerOrderList() {

    const [customerOrderData, setCustomerOrderData] = React.useState([]); 
    const [customerOrderModalData, setCustomerOrderModalData] = React.useState([]); 
    const [expandedRows, setExpandedRows] = React.useState(new Set());

    const token = getToken(); 

    const fetchCustomerOrderData = () => { 
        fetch(`${BASE_URL}/api/readcustomerorders`, { 
          method: 'GET',  
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.text(); 
        })
        .then(data => {
          try {
            const jsonData = JSON.parse(data); 
            setCustomerOrderData(jsonData);
            console.log(jsonData); // debug
          } catch(e) {
            console.error("The response is not JSON. Data:", data);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    const fetchCustomerOrderModalData = () => { 
        fetch(`${BASE_URL}/api/readcustomerorderlines`, { 
          method: 'GET',  
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.text(); 
        })
        .then(data => {
          try {
            const jsonData = JSON.parse(data); 
            setCustomerOrderModalData(jsonData);
            console.log(jsonData); // debug
          } catch(e) {
            console.error("The response is not JSON. Data:", data);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    React.useEffect(() => {
        fetchCustomerOrderData(); 
        fetchCustomerOrderModalData();
    }, []);  

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${monthNames[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`;
    };

    const handleRowClick = (index) => {
        const newExpandedRows = new Set(expandedRows);
        if (newExpandedRows.has(index)) {
            newExpandedRows.delete(index);
        } else {
            newExpandedRows.add(index);
        }
        setExpandedRows(newExpandedRows);
    };
    
    return (
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'auto',
          }}
        
        >
        <CssBaseline />
    
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
    
            <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>
                <Table aria-label="order list table" stickyHeader>
    
                <TableHead>
                    <TableRow>
                    <TableCell>Customer</TableCell>
                    <TableCell>Farm</TableCell>
                    <TableCell>Order date</TableCell>
                    <TableCell>Total weight</TableCell>
                    <TableCell>Packed</TableCell>
                    <TableCell>OrderEase</TableCell>
                    <TableCell>Loaded</TableCell>
                    </TableRow>
                </TableHead>
    
                <TableBody>
                    {customerOrderData.map((row, index) => (
                        <React.Fragment key={index}>
                            <TableRow 
                                onClick={() => handleRowClick(index)}
                            >
                                <TableCell>{row.customer_name}</TableCell>
                                <TableCell>{row.farm_name}</TableCell>
                                <TableCell>{formatDate(row.order_date.split('T')[0])}</TableCell>
                                <TableCell>{row.total_weight}</TableCell>
                                <TableCell><Checkbox checked={row.is_packed === 1} /></TableCell>
                                <TableCell><Checkbox checked={row.order_ease === 1} /></TableCell>
                                <TableCell><Checkbox checked={row.is_loaded === 1} /></TableCell>
                            </TableRow>
                            {expandedRows.has(index) && (
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                        <Box margin={1}>
                                            {customerOrderModalData.filter(item => item.customer_order_id === row.customer_order_id).map((detail, detailIndex) => (
                                                <Box key={detailIndex} margin={1}>
                                                    {detail.strain_name}: {detail.weight} lbs
                                                </Box>
                                            ))}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}
                </TableBody>
    
                </Table>
            </TableContainer>
    
            </Container>
    
        </Box>
    );
    
}

export default CustomerOrderList;