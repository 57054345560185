import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Constants';
import { toast } from 'react-toastify';
import { getToken } from '../../auth/auth.js'; 
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import sortData from '../common/sortColumns';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import 'react-toastify/dist/ReactToastify.css';
import FormHelperText from '@mui/material/FormHelperText';
import dayjs from 'dayjs';

function ReceivedView() {
  const [receivedData, setReceivedData] = React.useState([]); 
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [sortField, setSortField] = React.useState('received_date');
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [isCutModalOpen, setIsCutModalOpen] = React.useState(false);
  const [selectedDataForCutModal, setSelectedDataForCutModal] = React.useState([]);
  const [isTossModalOpen, setIsTossModalOpen] = React.useState(false);
  const [selectedDataForTossModal, setSelectedDataForTossModal] = React.useState([]);
  const [selectedFarm, setSelectedFarm] = React.useState('');


  const [sortDirection, setSortDirection] = React.useState({
    num_of_blocks: 'desc',
    strain_name: 'desc',
    inoculation_date: 'desc',
    received_date: 'desc',
  }); 

  const filteredData = selectedFarm
  ? receivedData.filter(record => record.farm_name === selectedFarm)
  : receivedData;

  const sortedReceivedData = sortData(filteredData, sortField, sortDirection[sortField]);

  const farmNames = [...new Set(receivedData.map(record => record.farm_name))];

  const navigate = useNavigate();
  const token = getToken(); 

  const fetchReceivedData = () => { 
    fetch(`${BASE_URL}/api/readreceived`, { 
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); 
    })
    .then(data => {
      try {
        const jsonData = JSON.parse(data); 
        setReceivedData(jsonData);
      } catch(e) {
        console.error("The response is not JSON. Data:", data);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const refreshReceivedData = () => {
    fetchReceivedData();
    setSelectedRows([]);
  };

  const deleteReceivedRecord = (id) => { 
    setSelectedId(id);
    setOpenDialog(true); // open the dialog
  }

  const handleConfirmDelete = () => {
    // close the dialog first
    setOpenDialog(false);
  
    fetch(`${BASE_URL}/api/deletereceivedrecord/${selectedId}`, { 
      method: 'PUT', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        toast.success('Record successfully deleted!', {
          autoClose: 1000, // Toast notification will close in 1 second
        });
        // Refresh the data
        return fetchReceivedData(); 
      }
    })
    .catch(error => {
      console.error('Error:', error);
      toast.error('An error occurred while trying to delete the record');
    });
  }
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const editReceivedRecord = (id) => {
    navigate(`/receivededit/${id}`)
  };

  React.useEffect(() => {
    fetchReceivedData();
  }, []); 

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
  
    const dateParts = dateString.split('T')[0].split('-');
    // Create a new date object using the local time zone
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const handleSort = (field) => {
    // Toggle the sort direction for the specific field
    const newDirection = sortDirection[field] === 'asc' ? 'desc' : 'asc';
  
    // Update the sort directions state
    setSortDirection({
      ...sortDirection,
      [field]: newDirection
    });
  
    // Set the sort field
    setSortField(field);
  };

  // Function to handle the master checkbox toggle
  const handleMasterCheckboxToggle = () => {
    if (selectedRows.length === sortedReceivedData.length) {
      setSelectedRows([]); // Unselect all
    } else {
      setSelectedRows(sortedReceivedData.map((row, index) => index)); // Select all
    }
  };

  // Function to handle individual row checkbox toggle
  const handleRowCheckboxToggle = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter(row => row !== index)); // Unselect this row
    } else {
      setSelectedRows([...selectedRows, index]); // Select this row
    }
  };

  // Check if the master checkbox should be checked
  const isMasterCheckboxChecked = selectedRows.length === sortedReceivedData.length;

  function allSelectedFromSameFarm(data) {
    if (data.length > 0) {
      const firstFarmName = data[0].farm_name;
      return data.every(item => item.farm_name === firstFarmName);
    }
    return true; 
  }

  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };

  const handleCutButtonClick = () => {
    const dataForCut = selectedRows.map(index => sortedReceivedData[index]);

    if (!allSelectedFromSameFarm(dataForCut)) {
      toast.error("All selected blocks must be from the same farm.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Prevent the modal from opening
    }

    setSelectedDataForCutModal(dataForCut);
    console.log('dataForCut:',dataForCut); // debug
    setIsCutModalOpen(true);
  };

  const handleTossButtonClick = () => {
    const dataToToss = selectedRows.map(index => sortedReceivedData[index]);
    setSelectedDataForTossModal(dataToToss);
    console.log('dataToToss:', dataToToss); // debug
    setIsTossModalOpen(true);
  };

  const handleViewTossedClick = () => {
    navigate('/tossedview');
  };

  function CutModal({ open, data, onClose, onSuccessfulCut }) {
    const [cutDate, setCutDate] = React.useState(dayjs());
    const [shelf, setShelf] = React.useState('');
    const [cutDateError, setCutDateError] = React.useState('');
    const [shelfError, setShelfError] = React.useState('');
    const [editableNumOfBlocksData, setEditableNumOfBlocksData] = React.useState([]);
    const numOfBlocksRef = data.map(() => React.createRef());

    const customContentStyle = {
      width: '40vw', 
      maxWidth: 'none', 
      margin: '0 auto', 
    };

    // Initialize the editable state when component mounts or data changes
    React.useEffect(() => {
      const initializedEditableData = data.map(item => ({
        ...item,
        editableNumOfBlocks: item.num_of_blocks
      }));
      setEditableNumOfBlocksData(initializedEditableData);
    }, [data]);

    function handleCutDateChange(newCutDate) {
      setCutDate(newCutDate);
      if (newCutDate) {
        setCutDateError('');
      }
    }
  
    const handleShelfChange = (event) => {
      setShelf(event.target.value.toUpperCase());
      if (event.target.value) {
        setShelfError('');
      }
    };

    const handleNumOfBlocksChange = (event, index) => {
      const inputVal = event.target.value;
      let newValue;
    
      if (inputVal === '') {
        newValue = '';
      } else {
        newValue = Math.min(Number(inputVal), editableNumOfBlocksData[index].num_of_blocks);
      }
    
      setEditableNumOfBlocksData(prevEditableData =>
        prevEditableData.map((item, idx) =>
          idx === index ? { ...item, editableNumOfBlocks: newValue } : item
        )
      );
    };

    const onConfirm = async () => {

      const isNumOfBlocksValid = editableNumOfBlocksData.every(item => item.editableNumOfBlocks <= item.num_of_blocks);

      if (!isNumOfBlocksValid) {
        // Handle invalid data case (e.g., show an error message)
        console.error('Invalid data: Number of blocks exceeds the limit');
        return;
      }

      let isValid = true;

      if (!cutDate) {
        setCutDateError("Please select a cut date");
        isValid = false;
      } else {
        setCutDateError('');
      }
    
      if (!shelf) {
        setShelfError("Please select a shelf");
        isValid = false;
      } else {
        setShelfError('');
      }
    
      if (!isValid) return;

      const postData = editableNumOfBlocksData.map(item => ({
        received_id: item.received_id,
        num_of_blocks_cut: item.editableNumOfBlocks, 
        farm_id: item.farm_id, 
        strain_id: item.strain_id, 
        batch_shelf: shelf, 
        cut_date: cutDate, 
        inoculation_date: item.inoculation_date 
      }));

      try {
        const response = await fetch(`${BASE_URL}/api/cutinputfromreceived`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(postData),
        });
  
        if (response.ok) {
          const result = await response.json();
          console.log('Data submitted successfully:', result);
          setIsCutModalOpen(false);
          if (onSuccessfulCut) {
            onSuccessfulCut();  // Call the callback
          }
          toast.success('Cut submitted successfully!', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          const errorResult = await response.json();
          console.error('Failed to submit data:', errorResult);
        }
      } catch (error) {
        console.error('Network error when submitting data:', error);
      }
    };
  
    return (
      <>
        <style>
          {`
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            input[type=number] {
              -moz-appearance: textfield;
            }
          `}
        </style>

        <Dialog open={open} onClose={onClose} PaperProps={{ style: customContentStyle }} >
          <DialogTitle>{"Add cut blocks: "}{editableNumOfBlocksData.length > 0 && editableNumOfBlocksData[0].farm_name}</DialogTitle>
          <DialogContent>

            {/* Cut Date */}
            <Box sx={{ mt:1 }} >
              <FormControl variant="outlined" fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Cut date"
                    value={cutDate}
                    closeOnSelect={true}
                    onChange={handleCutDateChange}
                  >
                    <TextField />
                  </DatePicker>
                </LocalizationProvider>
              </FormControl>
            </Box>
            {cutDateError && <FormHelperText error>{cutDateError}</FormHelperText>}

            {/* Shelf */}
            <FormControl fullWidth sx={{ mt:2 }} >
              <TextField
                labelid="shelf-textfield-label"
                id="shelf-textfield"
                value={shelf} 
                label="Shelf"
                onChange={handleShelfChange}
                variant="outlined"
              />
            </FormControl>
            {shelfError && <FormHelperText error>{shelfError}</FormHelperText>}
    
            {/* Table */}
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
              <Table aria-label="cut blocks table">

                <TableHead>
                  <TableRow>
                    <TableCell>Strain</TableCell>
                    <TableCell align="right">Qty</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {editableNumOfBlocksData.map((item, index) => (
                    <TableRow key={index}>

                      <TableCell component="th" scope="row">
                        {item.strain_name}
                      </TableCell>

                      <TableCell align="right">
                        <TextField
                          type="number"
                          value={item.editableNumOfBlocks}
                          onChange={e => handleNumOfBlocksChange(e, index)}
                          onFocus={e => e.target.select()}
                          inputProps={{ min: 1, max: item.num_of_blocks }}
                          inputRef={numOfBlocksRef[index]}
                          sx={{ width: '3.25em', padding: '10' }}
                          InputProps={{
                            style: {
                              height: '2em', 
                            },
                          }}
                        />
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </TableContainer>

          </DialogContent>

          <DialogActions>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Button onClick={onClose} >Cancel</Button>
              <Button onClick={onConfirm} variant="contained">Confirm</Button>
            </Box>
          </DialogActions>

        </Dialog>
      </>


    );
  }

  function TossModal({ open, data, onClose, onSuccessfulToss }) {
    const [tossDate, setTossDate] = React.useState(dayjs());
    const [tossDateError, setTossDateError] = React.useState('');
    const [editableNumOfBlocksData, setEditableNumOfBlocksData] = React.useState([]);
    const [selectedReason, setSelectedReason] = React.useState('');
    const [uploadedImage, setUploadedImage] = React.useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = React.useState('');
    const numOfBlocksRef = data.map(() => React.createRef());

    const customContentStyle = {
      width: '40vw', 
      maxWidth: 'none', 
      margin: '0 auto', 
    };

    // Initialize the editable state when component mounts or data changes
    React.useEffect(() => {
      const initializedEditableData = data.map(item => ({
        ...item,
        editableNumOfBlocks: ''
      }));
      setEditableNumOfBlocksData(initializedEditableData);
    }, [data]);

    function handleTossDateChange(newTossDate) {
      setTossDate(newTossDate);
      if (newTossDate) {
        setTossDateError('');
      }
    }

    const handleReasonChange = (event) => {
      setSelectedReason(event.target.value);
    };

    const handleNumOfBlocksChange = (event, index) => {
      const inputVal = event.target.value;
      let newValue = inputVal;
    
      if (inputVal !== '' && Number(inputVal) > editableNumOfBlocksData[index].num_of_blocks) {
        newValue = editableNumOfBlocksData[index].num_of_blocks.toString();
      }
      
      setEditableNumOfBlocksData(prevEditableData =>
        prevEditableData.map((item, idx) =>
          idx === index ? { ...item, editableNumOfBlocks: newValue } : item
        )
      );
    };

    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        setUploadedImage(file);
        const reader = new FileReader();
        
        reader.onloadend = () => {
          setImagePreviewUrl(reader.result);
        };
    
        reader.readAsDataURL(file);
      }
    };

    const onConfirmToss = async () => {

      const isNumOfBlocksValid = editableNumOfBlocksData.every(item => item.editableNumOfBlocks <= item.num_of_blocks);

      if (!isNumOfBlocksValid) {
        // Handle invalid data case (e.g., show an error message)
        console.error('Invalid data: Number of blocks exceeds the limit');
        return;
      }

      let isValid = true;

      if (!tossDate) {
        setTossDateError("Please select a toss date");
        isValid = false;
      } else {
        setTossDateError('');
      } 
    
      if (!isValid) return;

      const formData = new FormData();

      editableNumOfBlocksData.forEach((item, index) => {
        formData.append(`blocks[${index}][received_id]`, item.received_id);
        formData.append(`blocks[${index}][num_of_blocks_tossed]`, item.editableNumOfBlocks); 
        formData.append(`blocks[${index}][farm_id]`, item.farm_id);
        formData.append(`blocks[${index}][strain_id]`, item.strain_id);
        formData.append(`blocks[${index}][reason]`, selectedReason); 
        formData.append(`blocks[${index}][date_tossed]`, tossDate.format('YYYY-MM-DD'));
      });
    
      if (uploadedImage) {
        formData.append('image', uploadedImage);
      }

      // Log the FormData
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
    
      try {
        const response = await fetch(`${BASE_URL}/api/wasteinputfromreceived`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}` // Replace with your token
          },
          body: formData
        });

        console.log('response:',response);
    
        if (response.ok) {
          const result = await response.json();
          console.log('Data submitted successfully:', result);
          onClose(); // Assuming onClose will close the modal and reset state
          if (onSuccessfulToss) {
            onSuccessfulToss(); // Call the callback
          }
          // Display success message
        } else {
          const errorResult = await response.json();
          console.error('Failed to submit data:', errorResult);
          // Display error message
        }
      } catch (error) {
        console.error('Network error when submitting data:', error);
        // Display network error message
      }
    };
  
    return (
      <>
        <style>
          {`
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            input[type=number] {
              -moz-appearance: textfield;
            }
          `}
        </style>

        <Dialog open={open} onClose={onClose} PaperProps={{ style: customContentStyle }} >
          <DialogTitle>{"Add blocks to toss: "}{editableNumOfBlocksData.length > 0 && editableNumOfBlocksData[0].farm_name}</DialogTitle>
          <DialogContent>

            {/* Toss Date */}
            <Box sx={{ mt:1 }} >
              <FormControl variant="outlined" fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Toss date"
                    value={tossDate}
                    closeOnSelect={true}
                    onChange={handleTossDateChange}
                  >
                    <TextField />
                  </DatePicker>
                </LocalizationProvider>
              </FormControl>
            </Box>
            {tossDateError && <FormHelperText error>{tossDateError}</FormHelperText>}

            {/* Reason */}
            <Box sx={{ mt: 2 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Reason</FormLabel>
                <RadioGroup
                  aria-label="reason"
                  name="reason"
                  value={selectedReason}
                  onChange={handleReasonChange}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <FormControlLabel value="Trich Contam" control={<Radio />} label="Trich Contam" />
                  <FormControlLabel value="Neuro Contam" control={<Radio />} label="Neuro Contam" />
                  <FormControlLabel value="Other Contam" control={<Radio />} label="Other Contam" />
                  <FormControlLabel value="Other" control={<Radio />} label="Other" />
                </RadioGroup>
              </FormControl>
            </Box>
    
            {/* Table */}
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
              <Table aria-label="cut blocks table">

                <TableHead>
                  <TableRow>
                    <TableCell>Strain</TableCell>
                    <TableCell align="right">Qty</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {editableNumOfBlocksData.map((item, index) => (
                    <TableRow key={index}>

                      <TableCell component="th" scope="row">
                        {item.strain_name}
                      </TableCell>

                      <TableCell align="right">
                        <TextField
                          type="number"
                          value={item.editableNumOfBlocks}
                          onChange={e => handleNumOfBlocksChange(e, index)}
                          onFocus={e => e.target.select()}
                          inputProps={{ min: 1, max: item.num_of_blocks }}
                          inputRef={numOfBlocksRef[index]}
                          sx={{ width: '3.25em', padding: '10' }}
                          InputProps={{
                            style: {
                              height: '2em', 
                            },
                          }}
                        />
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </TableContainer>

            {/* Image Upload */}
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleImageUpload}
                />
                <label htmlFor="raised-button-file">
                  <Button variant="outlined" component="span" fullWidth>
                    Upload Image
                  </Button>
                </label>
                {imagePreviewUrl && (
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                    <img 
                      src={imagePreviewUrl} 
                      alt="Preview" 
                      style={{ maxHeight: '100px', maxWidth: '100%', objectFit: 'contain' }} 
                    />
                  </Box>
                )}
              </FormControl>
            </Box>

          </DialogContent>

          <DialogActions>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Button onClick={onClose} >Cancel</Button>
              <Button onClick={onConfirmToss} variant="contained">Toss</Button>
            </Box>
          </DialogActions>

        </Dialog>
      </>


    );
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />
      <Toolbar />

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>

            <Box 
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, max-content) 1fr auto', // Three columns: button, title, empty space
                gap: 1, 
                alignItems: 'center',
                marginBottom: 2, 
              }}
            > 

              {/* CUT button */}
              <Button variant="contained" onClick={handleCutButtonClick}>
                CUT
              </Button>

              {/* TOSS button */}
              <Button variant="contained" onClick={handleTossButtonClick}>
                TOSS
              </Button>

              {/* Title */}
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', // Centers the title
                gridColumnStart: 3, // Title starts at the third column
                gridColumnEnd: 3, // Title ends at the third column
              }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  All blocks received
                </Typography>
              </Box>

              {/* Farm filter */}
              <FormControl fullWidth sx={{ gridColumnStart: 4, width: '145px' }}>
                <InputLabel id="farm-select-label">Filter farm</InputLabel>
                  <Select
                    labelId="farm-select-label"
                    id="farm-select"
                    value={selectedFarm}
                    label="Farm"
                    onChange={handleFarmChange}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {farmNames.map(farmName => (
                      <MenuItem key={farmName} value={farmName}>{farmName}</MenuItem>
                    ))}
                </Select>
              </FormControl>

            </Box>

            <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>
              <Table aria-label="blocks received table" stickyHeader>

                <TableHead>
                  <TableRow>

                    <TableCell>
                      <Checkbox 
                        checked={isMasterCheckboxChecked}
                        onChange={handleMasterCheckboxToggle}
                      />
                    </TableCell>

                    {/* Number of blocks */}
                    <TableCell 
                      align="right"
                      onClick={() => handleSort('num_of_blocks')}
                    >
                      Number of blocks {sortDirection['num_of_blocks'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>

                    {/* Strain */}
                    <TableCell 
                      align="left"
                      onClick={() => handleSort('strain_name')}
                    >
                      Strain {sortDirection['strain_namestrain_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>

                    {/* Farm */}
                    <TableCell 
                      align="left"
                      onClick={() => handleSort('farm_name')}
                    >
                      Farm {sortDirection['farm_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>

                    {/* Inoculation date */}
                    <TableCell 
                      align="center"
                      onClick={() => handleSort('inoculation_date')}
                    >
                      Inoculation date {sortDirection['inoculation_dateinoculation_date'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>

                    {/* Date received */}
                    <TableCell 
                      align="center"
                      onClick={() => handleSort('received_date')}
                    >
                      Date received {sortDirection['received_date'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>

                    <TableCell align="center">Edit</TableCell>
                    <TableCell align="center">Delete</TableCell>

                  </TableRow>
                </TableHead>

                <TableBody>
                  {sortedReceivedData.map((row, index) => (
                    <TableRow key={index}>

                      <TableCell>
                        <Checkbox 
                          checked={selectedRows.includes(index)}
                          onChange={() => handleRowCheckboxToggle(index)}
                        />
                      </TableCell>
                      
                      <TableCell align="center">{row.num_of_blocks}</TableCell>
                      <TableCell align="left">{row.strain_name}</TableCell>
                      <TableCell align="left">{row.farm_name}</TableCell>
                      <TableCell align="center">{formatDate(row.inoculation_date)}</TableCell>
                      <TableCell align="center">{formatDate(row.received_date)}</TableCell>

                      {/* Edit Button */}      
                      <TableCell>
                        <IconButton aria-label="edit" onClick={() => editReceivedRecord(row.received_id)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>

                      {/* Delete Button */}
                      <TableCell>
                        <IconButton aria-label="delete" onClick={() => deleteReceivedRecord(row.received_id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </TableContainer>

            <Button variant='outlined' sx={{mt:2}} onClick={handleViewTossedClick}>View Tossed</Button>

          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{"Delete from received table"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <CutModal 
        open={isCutModalOpen} 
        data={selectedDataForCutModal} 
        onClose={() => setIsCutModalOpen(false)} 
        onSuccessfulCut={refreshReceivedData}
      />

      <TossModal 
        open={isTossModalOpen} 
        data={selectedDataForTossModal} 
        onClose={() => setIsTossModalOpen(false)} 
        onSuccessfulToss={refreshReceivedData}
      />

    </Box>
  );
}

export default ReceivedView;