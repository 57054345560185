import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Paper } from '@mui/material';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';

function StrainsPerFarmLineChart({ selectedFarm }) {
  const token = getToken();
  const [data, setData] = useState([]);

  // Initial options for line chart
  const [chartOptions, setChartOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false, // This will hide the toolbar including zoom, home, and menu options
      }
    },
    xaxis: {
      categories: [] // This will hold your X-axis labels (e.g., dates, times, etc.)
    },
    yaxis: {
      title: {
        text: 'Yield'
      },
      labels: {
        formatter: (value) => {
          return value.toFixed(1);
        },
      },
    },
    stroke: {
      width: 3,
      curve: 'smooth'
    },
    legend: {
      position: 'top'
    }
  }); 

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const fetchData = async () => {
    // Adjust the URL and parameters according to your API
    try {
      console.log(selectedFarm)
      const response = await fetch(`${BASE_URL}/api/linechartstrainsperfarm?farm=${selectedFarm}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const result = await response.json();

      let strainsData = {};
      let categories = new Set();

      result.data.forEach(item => {
        if (!strainsData[item.strain_name]) {
          strainsData[item.strain_name] = [];
        }
        const formattedDate = new Date(item.flush_date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
        strainsData[item.strain_name].push({
          x: formattedDate, // Convert string date to Date object
          y: item.yield_per_block
        });
        categories.add(formattedDate);
      });
  
      const series = Object.keys(strainsData).map(strain => ({
        name: strain,
        data: strainsData[strain]
      }));
  
      setData(series);

      setChartOptions(prevOptions => ({
        ...prevOptions,
        xaxis: { categories: Array.from(categories).sort() } // Convert Set to Array and sort
      }));

    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedFarm]); // Rerun when selectedFarm changes

  return (
    <div>
      <Paper>
        <ReactApexChart options={chartOptions} series={data} type="line" height={350} />
      </Paper>
    </div>
  );
}

export default StrainsPerFarmLineChart;
