import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { getToken } from '../../auth/auth.js';
import { BASE_URL } from '../../Constants';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

function getNextSaturday() {
  const today = dayjs();
  const nextSaturday = today.day() < 6 ? today.day(6) : today.add(1, 'week').day(6);
  return nextSaturday;
}

function InventoryPredictions() { 
 
  const token = getToken(); 
  const [strains, setStrains] = useState([]);

  const [selectedDate, setSelectedDate] = useState(getNextSaturday());
  const [availableDates, setAvailableDates] = useState([]);

  // const inputRef = useRef(null); // Step 1: Create a ref
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchStrains();
  
    // Calculate the Saturdays for the next three months
    const Saturdays = [];
    let currentDate = dayjs();
    const threeMonthsLater = currentDate.add(3, 'months');
  
    while (currentDate.isBefore(threeMonthsLater)) {
      if (currentDate.day() === 6) { // Saturday
        Saturdays.push(currentDate.toDate());
      }
      currentDate = currentDate.add(1, 'day');
    }
    setAvailableDates(Saturdays);
  }, [selectedDate]);

  const fetchStrains = () => {
    // Convert the selectedDate to a format that can be sent in the URL
    const formattedDate = selectedDate.format('YYYY-MM-DD');
  
    fetch(`${BASE_URL}/api/predictedyields?selectedDate=${formattedDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data); // Add this line to log the output
      setStrains(data);
    })
    .catch(error => console.error('Error:', error));
  };
  
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    // inputRef.current.blur(); // Remove focus from the date picker
    setOpen(false);
  };

  const farms = strains.reduce((acc, curr) => {
    if (!acc[curr.farm_name]) {
      acc[curr.farm_name] = [];
    }
    acc[curr.farm_name].push(curr);
    return acc;
  }, {});

  // ***TEMPORARILY Filter out "Kingston" farm***
  const filteredFarms = Object.keys(farms).reduce((acc, currentFarm) => {
    if (currentFarm !== "Kingston") { // Exclude "Kingston" farm
      acc[currentFarm] = farms[currentFarm];
    }
    return acc;
  }, {});

  return (
    <Box 
      sx={{ 
        padding: 3, 
        marginTop: 4, 
      }}
    >

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '15px' }}>

        {/* Title */}
        <Typography variant="h6" sx={{ padding: 0 }}>
          Inventory Predictions
        </Typography>

        {/* Date Picker */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Available by:"
            value={selectedDate}
            open={open} 
            onClose={() => setOpen(false)} 
            onOpen={() => setOpen(true)} 
            autoOk
            onChange={handleDateChange} 
            textProp={(params) => <TextField {...params} />} 
            shouldDisableDate={(date) => !availableDates.find(availableDate => availableDate.toDateString() === dayjs(date).toDate().toDateString())}
          />
        </LocalizationProvider>
      </Box>

      {/* Individual Farm Stats */}
      <Grid container spacing={3}>

        {Object.keys(filteredFarms).map((farm, index) => ( //using 'filteredFarms' here to filter out Kinston, change back to 'farms' when I want to add it back
          <Grid item xs={4} key={index}>

            <Paper 
              elevation={0} 
              sx={{ 
                padding: 2, 
                borderRadius: '10px', 
                border:'1px solid #d9d9d9',
              }}
            >
              <Typography variant="body1" sx={{ fontSize: '1.2rem', mb: 1, color: '#1769AA' }}>{farm}</Typography>
              
              {farms[farm].map((strain, index) => (

                <Typography variant="body2" sx={{ fontSize: '1rem' }} key={index}>
                  {strain.strain_shorthand}: {parseFloat(strain.total_yield).toFixed(1)} lbs
                </Typography>

              ))}

            </Paper>

          </Grid>
        ))}
      </Grid>

    </Box>
  );

}

export default InventoryPredictions;