import React, { useEffect, useState, useRef } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Divider } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { BASE_URL } from '../../Constants';
import { getToken, getFarmId } from '../../auth/auth.js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const mdTheme = createTheme();

function BlockOrders() {

    const token = getToken();   
    const farmId = getFarmId();
    const navigate = useNavigate();

    const defaultDate = dayjs().add(42, 'day');
    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const [selectedFarm, setSelectedFarm] = useState(''); 
    const [farms, setFarms] = useState([]);
    const [strains, setStrains] = useState([]);
    const [strainsFetched, setStrainsFetched] = useState(false);
    const [initialDataFetched, setInitialDataFetched] = useState(false);
    const [previousOrder, setPreviousOrder] = useState([]);
    const [qty, setQty] = useState([]);
    const [isReset, setIsReset] = useState(false);
    const firstInputRef = useRef();
    const qtyInputRefs = useRef([]);
    const [shouldSyncWithPreviousOrder, setShouldSyncWithPreviousOrder] = useState(true);
    const [notes, setNotes] = useState('');
    const [totalBlocks, setTotalBlocks] = useState(0);

    const [orderLines, setOrderLines] = useState([
        { strain: '', qty: '' },
    ]);

    const fetchStrains = () => {
        return fetch(`${BASE_URL}/api/readstrains`, { 
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text(); 
            })
            .then(data => {
            try {
                const jsonData = JSON.parse(data); 
                setStrains(jsonData);
                setStrainsFetched(true);
            } catch(e) {
                console.error("The response is not JSON. Data:", data);
            }
            })
            .catch(error => {
                console.error('Error:', error);
        });
    }

    const fetchFarms = () => { 
        return fetch(`${BASE_URL}/api/readfarms`, { 
            method: 'GET', 
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.text(); 
          })
          .then(data => {
            try {
              const jsonData = JSON.parse(data); 
              setFarms(jsonData);

              // Find the farm that matches the user's farmId
              const userFarm = jsonData.find(farm => farm.farm_id === farmId);

              // If found, set that as the selectedFarm
              if (userFarm) {
                setSelectedFarm(userFarm.farm_id);
              }
            } catch(e) {
              console.error("The response is not JSON. Data:", data);
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
    }

    const calculateTotalBlocks = () => {
        const total = orderLines.reduce((acc, line) => acc + (parseInt(line.qty, 10) || 0), 0);
        setTotalBlocks(total);
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchStrains();
            await fetchFarms();
            setInitialDataFetched(true);
        };
      
        fetchData();
    }, []);

    // old useEffect
    useEffect(() => {
        if (!initialDataFetched) { // Make sure initial data is fetched
            return;
        }

        const fetchPreviousOrders = async () => {
            const newPreviousOrders = []; // new array that will hold the strain and it's previous order fetched from the server 
            
            for (const strain of strains) { //for loop - FOR each strain in the strains array ...
                const response = await fetch(`${BASE_URL}/api/readpreviousorders?farm_id=${selectedFarm}&strain_id=${strain.strain_id}`, { //gets previous order for that particular farm & strain combo
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
    
                const data = await response.text();
                let jsonData;
                
                try {
                    jsonData = JSON.parse(data);
                } catch (e) {
                    console.error("The response is not JSON. Data:", data);
                    continue;
                }
    
                const previousOrderForStrain = jsonData.previousOrder === null ? 0 : jsonData.previousOrder; //Checks if jsonData.previousOrder is null. If it is, it sets previousOrderForStrain to 0; otherwise, it uses the value from jsonData.previousOrder
                newPreviousOrders.push({ strain_id: strain.strain_id, previousOrder: previousOrderForStrain }); //Adds the fetched previousOrderForStrain and its associated strain_id to newPreviousOrders
            }
    
            setPreviousOrder(newPreviousOrders);
    
            // Check the flag and set qty accordingly
            if (shouldSyncWithPreviousOrder) { //if shouldSyncWithPreviousOrder is true, then ...
                const initialQty = newPreviousOrders.map(order => order.previousOrder); //newPreviousOrders is an array of objects that has the strain_id and previousOrder for each object. this function extracts just the previousOrder from all indices and creates a new array, initialQty with just those values
                setQty(initialQty); 
                console.log("initialOty: " + initialQty); // debug

                const newOrderLines = orderLines.map((line, index) => { //initializing newOrderLines array and mapping over orderLines array
                    return {
                        ...line, // going through each line 
                        qty: initialQty[index] //setting qty object initialQty at index
                    };
                });
                // console.log("qty: " + qty);
                setOrderLines(newOrderLines); //setting
                console.log('Updated orderLines:', newOrderLines); 
            }
        };
    
        if (strainsFetched && selectedFarm) {
            fetchPreviousOrders();
        }
    
    }, [strainsFetched, selectedFarm, strains, shouldSyncWithPreviousOrder, initialDataFetched]); 
    
    useEffect(() => {
        // Create an initial orders array based on strains
        const initialOrders = strains.map(strain => ({
            strain: strain.strain_name,
            qty: 0,
        }));
    
        setOrderLines(initialOrders);
    }, [strains]);

    useEffect(() => {
        calculateTotalBlocks();
    }, [orderLines]);

    const handleColumnToggle = () => {
        if (isReset) { //Checks if isReset is true, which means that the columns should be synchronized with previous orders ...
            setShouldSyncWithPreviousOrder(true); // sets shouldSyncWithPreviousOrder to true (just keeping track of status)
            setIsReset(false); // sets isReset to false for the next time the function is called
        } else { // if reset is not true, then ...
            setShouldSyncWithPreviousOrder(false); // sets shouldSyncWithPreviousOrder to false
            setIsReset(true); // sets isReset to true for the next time the function is called
            
            const newQty = new Array(qty.length).fill(0); //Creates a new array called newQty with the same length as the qty array and fills it with zeros
            setQty(newQty); //Sets the qty array to the same values as the newQty array

            const newOrderLines = orderLines.map((line, index) => { //initializing newOrderLines array and mapping over orderLines array
                return {
                    ...line, // going through each line 
                    qty: 0 //setting qty object to 0
                };
            });
            setOrderLines(newOrderLines); //setting
            console.log('Updated orderLines:', newOrderLines); 
    
            // Focus on the first "Qty" input field
            setTimeout(() => {
                if (firstInputRef.current) {
                    firstInputRef.current.select();
                }
            }, 0);
        }
        calculateTotalBlocks();
    };

    const handleRowToggle = (index) => {
        const prevOrderForThisRow = previousOrder[index]?.previousOrder || 0; //fetches previousOrder attribute of the previousOrder array for this index (strain)
        const newQty = [...qty]; // makes a shallow copy of the qty array and stores it in a new variable called newQty
        
        // Toggle the qty for this specific row
        newQty[index] = (newQty[index] === 0) ? prevOrderForThisRow : 0; //Checks if the current quantity (newQty[index]) is 0. If it is, it sets it to prevOrderForThisRow. If it's not, it sets it to 0

        const newOrderLines = [...orderLines]; //Makes a shallow copy of the orderLines array and stores it in a new variable called newOrderLines
        newOrderLines[index] = { //this function creates a new object that combines all the existing properties of newOrderLines[index] and also includes the new qty value...
            ...newOrderLines[index], // spread out the properties of the object 
            qty: newQty[index] //setting the qty of the object to the value that is stored in newQty[index]
        };

        setQty(newQty);
        setOrderLines(newOrderLines);
        console.log('Updated orderLines:', newOrderLines); 
    
        // Only focus and select when the value is set to 0
        if (newQty[index] === 0) {
            setTimeout(() => {
                if (qtyInputRefs.current[index]) {
                    qtyInputRefs.current[index].select();
                }
            }, 0);
        }
        calculateTotalBlocks();
    };

    const handleQtyChange = (event, index) => {
        console.log("Received event value:", event.target.value);
        const newQty = [...qty]; // copy the old qty array
        newQty[index] = event.target.value; // set the new value
        
        const newOrders = [...orderLines]; // Copy the old orders array
        newOrders[index] = {
            ...newOrders[index],
            qty: event.target.value,
            strain: strains[index].strain_name
        };
        
        setQty(newQty); // update the state
        setOrderLines(newOrders); // update the state

        calculateTotalBlocks(); 
    };
    
    const summarizeData = (data, strains, farmName) => {
        const summaries = data.block_order_lines.map(line => {
          const correspondingStrain = strains.find(strain => strain.strain_id === line.strain_id);
          const strainName = correspondingStrain ? correspondingStrain.strain_name : 'Unknown Strain';
          return `${strainName}: ${line.num_of_blocks} blocks`;
        });
      
        return { farmName, summaries };  // Including farmName in the returned object
    };

    const prepareDataForSubmission = () => { 
        console.log('Current orderLines:', orderLines);
        const formattedDate = new Date(selectedDate).toISOString().slice(0, 19).replace('T', ' ');
    
        const blockOrderLines = orderLines.map(line => {
            const correspondingStrain = strains.find(strain => strain.strain_name === line.strain);
            return {
                strain_id: correspondingStrain ? correspondingStrain.strain_id : null,
                num_of_blocks: parseInt(line.qty, 10) || 0 
            };
        }).filter(line => line.num_of_blocks !== 0);  // Filtering out lines with num_of_blocks as 0
    
        console.log('Prepared blockOrderLines:', blockOrderLines); 
    
        const blockOrder = {
          ship_date: formattedDate,
          farm_id: selectedFarm,
          order_notes: notes,
          block_order_lines: blockOrderLines
        };
        
        return blockOrder;
    };

    const handleSubmit = async () => {
        const data = prepareDataForSubmission();
        console.log('Final data to be sent:', data);
    
        const selectedFarmObj = farms.find(farm => farm.farm_id === selectedFarm);
        const foundFarmName = selectedFarmObj ? selectedFarmObj.farm_name : 'Unknown Farm';
        const { summaries } = summarizeData(data, strains, foundFarmName);
    
        try {
            const response = await fetch(`${BASE_URL}/api/submitblockorder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const responseData = await response.json();  // Parsing the server's response as JSON
    
            if (responseData.message === 'No records inserted as all quantities are zero.') {
                toast.error("Nothing selected", { autoClose: 10000 });
            } else {
                toast.success(
                    <div>
                        <div>Order successfully submitted!</div>
                        <div>Details:</div>
                        <div>Farm: {foundFarmName}</div>
                        {summaries.map((summary, index) => (
                            <div key={index}>{summary}</div>
                        ))}
                    </div>,
                    { autoClose: 10000 }
                );
                navigate('/blockorderlist');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error("Sorry, there was an error", { autoClose: 10000 });
        }
    };

    return (
        <ThemeProvider theme={mdTheme}>
        <Box
            sx={{
                backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'auto',
            }}
  
        >
            <CssBaseline />

            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper style={{ maxHeight: '90vh', overflow: 'auto' }}>

                    {/* TITLE */}
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'center', 
                            pt: 1.5,
                        }}
                    >
                        <Typography variant="h6" component="h2" gutterBottom>
                            New order request
                        </Typography>
                    </Box>

                    {/* SHIP & FARM */}
                    <Grid container spacing={0}>

                        {/* SHIP DATE */}
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Ship date"
                                    value={selectedDate}
                                    closeOnSelect={true}
                                    onChange={(date) => setSelectedDate(date)}
                                    sx={{ mt:3, ml: 8, mb: 3 }}
                                >
                                    <TextField />
                                </DatePicker>
                            </LocalizationProvider>
                        </Grid>

                        {/* FARM SELECT */}
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ mt:3, ml:8, width: '60%' }}>
                                <InputLabel>Farm</InputLabel>
                                <Select
                                    value={selectedFarm}
                                    onChange={(e) => setSelectedFarm(e.target.value)}
                                >
                                    {farms.map((farm, index) => (
                                    <MenuItem key={index} value={farm.farm_id}>  
                                        {farm.farm_name}  
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Divider />

                    <Table>

                        <TableHead>
                            <TableRow>
                            <TableCell>Strain</TableCell>
                            <TableCell>Previous order</TableCell>
                            <TableCell>
                            <Button variant="outlined" onClick={handleColumnToggle}>
                                <EastIcon fontSize='small'/>
                            </Button>
                            </TableCell>
                            <TableCell align='center'>Qty</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {strains.map((strain, index) => (
                                <TableRow key={index}>

                                    {/* STRAIN */}
                                    <TableCell >
                                        {strain.strain_name}
                                    </TableCell>

                                    {/* PREV ORDER */}
                                    <TableCell align='center'>
                                        {previousOrder.find(order => order.strain_id === strain.strain_id)?.previousOrder || 0}
                                    </TableCell>
                                    
                                    {/* ARROW BUTTON */}
                                    <TableCell>
                                        <Button variant="outlined" onClick={() => handleRowToggle(index)}>
                                            <EastIcon fontSize='small'/>
                                        </Button>
                                    </TableCell>

                                    {/* QTY */}
                                    <TableCell align='center'>
                                        <TextField
                                            type="number"
                                            sx={{ width: 70 }} 
                                            inputProps={{ style: { height: 40, padding: '0 14px' }, 
                                                ref: (el) => {
                                                    qtyInputRefs.current[index] = el;  // This is for the row-level refs
                                                    if (index === 0) {
                                                        firstInputRef.current = el;  // This is for the first row ref
                                                    }
                                                }
                                            }} 
                                            onChange={(e) => handleQtyChange(e, index)}
                                            value={qty[index] || 0}
                                            onClick={(e) => e.target.select()}
                                        />
                                    </TableCell>
                                    
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>

                    {/* Total Blocks Count */}
                    <Box sx={{ mt: 2, ml: 65 }}>
                        <Typography variant="h7">
                            Total Blocks: {totalBlocks}
                        </Typography>
                    </Box>

                    {/* NOTES */}
                    <TextField
                        fullWidth 
                        label="Notes..."
                        multiline
                        rows={2}
                        variant="outlined"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        sx={{ mt: 3, ml: 9, mr: 2, mb: 3, maxWidth: '80%' }}
                    />

                </Paper>

                {/* SUBMIT BUTTON */}
                <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2, float: 'right' }}>
                    Submit
                </Button>
                
            </Container>

        </Box>
        </ThemeProvider>
    );
}

export default BlockOrders;