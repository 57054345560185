import React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { useEffect, useState, useRef } from 'react';
import { BASE_URL } from '../Constants'; 
import hearteeLogo from '../heartee-logo.png'; // Adjust the path


const theme = createTheme();

const loginUser = async (email, password, rememberMe, url, token = null) => {
  try {
    const payload = token ? { token } : { email, password };
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();

    ['token', 'userId'].forEach(key => {
      window.localStorage.removeItem(key);
      window.sessionStorage.removeItem(key);
    });

    const storage = rememberMe ? localStorage : sessionStorage;
    storage.setItem('token', data.token);

    const decodedToken = jwt_decode(data.token);
    storage.setItem('userId', decodedToken.user_id);

    return data;
  } catch (error) {
    throw error;
  }
};

export default function LogIn() {
  const [ user, setUser ] = useState({}); 
  const [ googleLoaded, setGoogleLoaded] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const rememberMeRef = useRef(true);

  const navigate = useNavigate();

  useEffect(() => {
    rememberMeRef.current = rememberMe;
  }, [rememberMe]);

  // google logins
  async function handleCallbackResponse(response) {
    var userObject = jwt_decode(response.credential);
    console.log(userObject); // debug
    setUser(userObject);
  
    try {
      await loginUser(null, null, rememberMeRef.current, `${BASE_URL}/api/google_login`, response.credential);
      
      var storage = rememberMeRef.current ? localStorage : sessionStorage;
      var decodedToken = jwt_decode(storage.getItem('token'));
  
      if (decodedToken.farm_id) {
        navigate('/');
      } else {
        navigate('/selectfarm');
      }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      if (error.message === 'Unauthorized') {
        toast.warn('Only authorized users can log in', {
          autoClose: 1500,
          closeButton: true,
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        // your existing logic
      }
    }
  }  
  
  const initGoogle = () => {
    /* global google */
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse
    });

    // render button 
    google.accounts.id.renderButton(
      document.getElementById("signInGoogleDiv"),
      { theme: "outline", size: "large" }
    );
  };

  console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID); // debug

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.onload = () => {
      initGoogle();
      setGoogleLoaded(true);
    };
    document.body.appendChild(script); // issue

    return () => { // cleanup function
      document.body.removeChild(script);
    };
  }, []);

  //manual logins
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  
    const email = data.get('email');
    const password = data.get('password');
  
    try {
      await loginUser(email, password, rememberMe, `${BASE_URL}/api/login`);
  
      var storage = rememberMe ? localStorage : sessionStorage;
      var decodedToken = jwt_decode(storage.getItem('token'));
      console.log("Decoded user id:", decodedToken.user_id);
  
      if (decodedToken.farm_id) {
        navigate('/');
      } else {
        navigate('/selectfarm');
      } 
    } catch (error) {
      console.error('Error:', error.message);
      toast.error('Invalid email or password', {
        autoClose: false,
        closeButton: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };  

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        pt: '6em',
      }}
    >
      <Container component="main" maxWidth="xs" sx={{
        pt: 8, 
        pb: 6, 
      }}>
        <CssBaseline />
  
        <Paper elevation={3} sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: theme.spacing(3), 
        }}>
  
          <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
            Log in using Heartee email
          </Typography>
  
          <div>
            <img 
              src={hearteeLogo} 
              alt="Heartee Logo" 
              style={{ width: '100px', height: 'auto', margin: theme.spacing(2) }}
            />
          </div>
  
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
  
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: theme.spacing(2) }}>
              <div id="signInGoogleDiv"> </div> 
            </div>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
  
} 