import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { BASE_URL } from '../../Constants';
import { getToken, getFarmId } from '../../auth/auth.js';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

function NewCustomerOrder() {

  const token = getToken();   
  const farmId = getFarmId();

  const [farms, setFarms] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [initialDataFetched, setInitialDataFetched] = React.useState(false);
  const [strains, setStrains] = React.useState([]);
  const [strainWeightPairs, setStrainWeightPairs] = React.useState([{ strain: '', weight: '' }]);
  const [strainsFetched, setStrainsFetched] = React.useState(false);
  const [customerInput, setCustomerInput] = React.useState('');
  const [selectedCustomerId, setSelectedCustomerId] = React.useState(null);
  const [filteredCustomers, setFilteredCustomers] = React.useState([]);
  const [isPacked, setIsPacked] = React.useState(false);
  const [isOrderEase, setIsOrderEase] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const fetchStrains = () => {
    return fetch(`${BASE_URL}/api/readstrains`, { 
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); 
    })
    .then(data => {
      try {
        const jsonData = JSON.parse(data); 
        setStrains(jsonData);
        setStrainsFetched(true);
      } catch(e) {
        console.error("The response is not JSON. Data:", data);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const fetchCustomers = (searchQuery) => {
    return fetch(`${BASE_URL}/api/searchcustomers?search=${encodeURIComponent(searchQuery)}`, { 
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      setFilteredCustomers(data.map(customer => ({
        label: `${customer.customer_name} (${customer.farm_name})`,
        id: customer.customer_id
      })));
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const addStrainWeightPair = () => {
    setStrainWeightPairs([...strainWeightPairs, { strain: '', weight: '' }]);
  };

  const handleStrainWeightChange = (index, field, value) => {
    const newPairs = strainWeightPairs.map((pair, idx) => {
      if (idx === index) {
        return { ...pair, [field]: value || "" };
      }
      return pair;
    });
    setStrainWeightPairs(newPairs);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await fetchStrains();
      await fetchCustomers();
      setInitialDataFetched(true);
    };
  
    fetchData();
  }, []);

  const handleSubmit = async () => {

    const orderData = {
      deliveryDate: selectedDate.format('YYYY-MM-DD'), // assuming you use dayjs format
      customerId: selectedCustomerId, 
      orderDetails: strainWeightPairs.map(pair => ({ // Remap to use strain_id
        strain_id: pair.strain_id,
        weight: pair.weight
      })),
      status: {
        isPacked,
        isOrderEase,
        isLoaded
      }
    }
    console.log(orderData); // debug
  
    try {
      const response = await fetch(`${BASE_URL}/api/submitcustomerorder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(orderData)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log('Order submitted successfully:', result);
      // Optionally reset states or redirect the user
    } catch (error) {
      console.error('Failed to submit order:', error);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper style={{ maxWidth: '52vw', maxHeight: '90vh', overflow: 'auto', margin: 'auto' }}>

          <Box sx={{ display: 'flex', justifyContent: 'center', pt: 1.5 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              New customer order
            </Typography>
          </Box>

          <Grid container spacing={0}>

            {/* Delivery date */}
            <Grid item xs={12} sx={{ px: 2, mt: 3, mb: 3 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Delivery date"
                  sx={{ width: '100%' }}
                  value={selectedDate}
                  closeOnSelect={true}
                  onChange={(date) => setSelectedDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            {/* Customer */}
            <Grid item xs={12} sx={{ px: 2 }}>
              <Autocomplete
                inputValue={customerInput}
                onInputChange={(event, newInputValue) => {
                  setCustomerInput(newInputValue);
                  fetchCustomers(newInputValue);
                }}
                options={filteredCustomers}
                onChange={(event, newValue) => {
                  setSelectedCustomerId(newValue ? newValue.id : null);
                }}
                getOptionLabel={(option) => option ? option.label : ''}
                renderInput={(params) => <TextField {...params} fullWidth label="Search Customer" variant="outlined" sx={{ mb: 3 }} />}
              />
            </Grid>

            {/* Strain and Weight */}
            {strainWeightPairs.map((pair, index) => (
              <React.Fragment key={index}>
                <Grid item xs={6} sx={{ px: 2, mb: 2 }}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Strain</InputLabel>
                    <Select
                      value={pair.strain_id || ""}
                      onChange={(e) => handleStrainWeightChange(index, 'strain_id', e.target.value)}
                      displayEmpty
                    >
                      {strains.map((strainObj, idx) => (
                        <MenuItem key={idx} value={strainObj.strain_id}>{strainObj.strain_name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sx={{ px: 2 }}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Weight (lbs)"
                    value={pair.weight}
                    onChange={(e) => handleStrainWeightChange(index, 'weight', e.target.value)}
                  />
                </Grid>
              </React.Fragment>
            ))}

            {/* Add strain button */}
            <Grid item xs={12} sx={{ px: 2, mb: 2 }}>
              <Button variant="outlined" color="primary" onClick={addStrainWeightPair}>
                Add strain
              </Button>
            </Grid>

            {/* Packed */}
            <Grid item xs={3.5} sx={{ml: 4}}>
              <FormControlLabel 
                control={<Checkbox checked={isPacked} onChange={(e) => setIsPacked(e.target.checked)} />}
                label="Packed"
              />
            </Grid>

            {/* OrderEase */}
            <Grid item xs={4}>
              <FormControlLabel
                control={<Checkbox checked={isOrderEase} onChange={(e) => setIsOrderEase(e.target.checked)} />}
                label="OrderEase"
              />
            </Grid>

            {/* OrderEase */}
            <Grid item xs={3.5}>
              <FormControlLabel
                control={<Checkbox checked={isLoaded} onChange={(e) => setIsLoaded(e.target.checked)} />}
                label="Loaded"
              />
            </Grid>

          </Grid>

            {/* Submit button */}
            <Box margin="1em">
                <Button variant="contained" 
                  onClick={handleSubmit} 
                >
                  Submit
                </Button>
            </Box>
        </Paper>
      </Container>
    </Box>
  );

}

export default NewCustomerOrder;