import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { BASE_URL } from '../../Constants';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { getToken, getUserId } from '../../auth/auth.js';

function UserProfile() {
  const [userData, setUserData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [farms, setFarms] = useState([]); 
  const [selectedFarm, setSelectedFarm] = useState(''); 
  const [selectedFile, setSelectedFile] = useState(null);
  const [avatarURL, setAvatarURL] = useState(null);

  const token = getToken();
  const userId = getUserId()

  const fetchUserData = () => {
    // console.log(userId);
    fetch(`${BASE_URL}/api/readuser/${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      if (response.ok) { 
        return response.json();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    })
    .then(data => {
      const modifiedData = {
        firstName: data.first_name,
        lastName: data.last_name,
        farmName: data.farm_name,
        email: data.email,
        avatar: 'https://via.placeholder.com/150',
        location: data.farm_region
      };
      setUserData(modifiedData);
      setEditedData(modifiedData);
      setSelectedFarm(modifiedData.farmName);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }  

  //fetching user data
  useEffect(() => {
    fetchUserData(userId);
  }, [userId]);

  // Fetching farms from API when component mounts
  useEffect(() => {
    fetch(`${BASE_URL}/api/readfarms`, {
        headers: {
        Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    })
    .then(data => {
        const mappedFarms = data.map(farm => ({id: farm.farm_id, name: farm.farm_name}));
        setFarms(mappedFarms); 
        console.log(mappedFarms);
    })
    .catch(error => console.error('Error:', error));
  }, []);

  if (!userData) {
    return <div>Loading...</div>;
  }

  const handleEditClick = () => {
    setIsEditing(true);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name !== 'email' && name !== 'location') { // prevent updating email and location
      setEditedData({ ...editedData, [name]: value });
    }
  }

  const handleChangeFarm = (event) => {
    setSelectedFarm(event.target.value);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    
    // Create a URL for the selected file
    const imageURL = URL.createObjectURL(event.target.files[0]);
    setAvatarURL(imageURL);
  };

  const handleCancelClick = () => {
    setEditedData(userData);  // Revert the edited data
    setIsEditing(false);  // Exit edit mode
  };

  const handleSaveClick = () => {
    // Get the farmId of the selectedFarm
    const selectedFarmObj = farms.find(farm => farm.name === selectedFarm);
    const selectedFarmId = selectedFarmObj ? selectedFarmObj.id : null;
  
    const requestOptions = {
      method: 'PUT',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ 
        firstName: editedData.firstName, 
        lastName: editedData.lastName, 
        farmId: selectedFarmId,
      })
    };
  
    //add Auth header with token to this fetch function
    fetch(`${BASE_URL}/api/updateuser/${userId}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        setIsEditing(false);
        // Fetch updated user data
        fetchUserData();
        console.log(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Card style={{ marginTop: '2em', position: 'relative' }}>

          <div style={{ position: 'absolute', right: '10px', top: '10px' }}>
            {isEditing
              ? (
                  <>

                  <IconButton
                    onClick={handleSaveClick}
                  >
                    <SaveIcon />
                  </IconButton>

                  <IconButton
                    onClick={handleCancelClick}
                  >
                    <CancelOutlinedIcon />
                  </IconButton>

                  </>
                )
                : (
                  <IconButton
                    onClick={handleEditClick}
                  >
                    <EditIcon />
                  </IconButton>
                )
            }
          </div>

          <CardContent>

            <Box display="flex" alignItems="center">

              <div style={{ position: 'relative' }}>
                <Avatar
                  src={avatarURL || userData.avatar}
                  alt={`${userData.firstName} ${userData.lastName}`}
                  sx={{ width: 100, height: 100, marginRight: '2em' }}
                />

                {isEditing && (
                  <div style={{ position: 'absolute', top: '68px', right: '32px', cursor: 'pointer' }}>
                    <input 
                      type="file" 
                      id="fileInput" 
                      style={{ display: 'none' }} 
                      onChange={handleFileChange}
                    />
                    <label htmlFor="fileInput">
                      <span style={{
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '50%', // This makes it circular
                        width: '30px', // Adjust based on your needs
                        height: '30px', // Adjust based on your needs
                        border: '1px solid gray',
                      }}>
                        <EditIcon style={{ color: 'grey' }} />
                      </span>
                    </label>
                  </div>
                )}
              </div>
                             
              <Box>

                <Box display="flex" flexWrap="wrap">
                  {isEditing
                    ? <>
                        <TextField name="firstName" label="First Name" value={editedData.firstName} onChange={handleInputChange} />
                        <TextField name="lastName" label="Last Name" value={editedData.lastName} onChange={handleInputChange} />
                      </>
                    : <Typography variant="h5" component="h2">{`${userData.firstName} ${userData.lastName}`}</Typography>
                  }
                </Box>

                <Box display="flex" alignItems="center" sx={{ color: 'text.secondary' }}>
                  <LocationOnIcon fontSize='smaller' />
                  <Typography variant="body2" sx={{ fontSize: '0.8em' }}>{userData.location}</Typography>
                </Box><br></br>

                {isEditing
                  ? (
                      <Select
                        value={selectedFarm}
                        onChange={handleChangeFarm}
                        name="farmName"
                      >
                        {farms.map((farm) => (
                          <MenuItem key={farm.id} value={farm.name}>
                            {farm.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )
                  : <Typography color="textSecondary" gutterBottom>Farm: {userData.farmName}</Typography>
                }

                <Typography color="textSecondary" gutterBottom>Email: {userData.email}</Typography>

              </Box>
              
            </Box>
            
          </CardContent>

        </Card>
      </Container>
    </Box>
  );
  
}

export default UserProfile;
