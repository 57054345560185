import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { BASE_URL } from '../../Constants';
import { getToken, getFarmId } from '../../auth/auth.js';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';

function CustomerDetails() {
    const { customerId } = useParams();  

    const [selectedCustomer, setSelectedCustomer] = React.useState('');
    const [isEditMode, setIsEditMode] = React.useState(false);

    const token = getToken(); 

    React.useEffect(() => {
        fetchCustomersAndSetSelected();
    }, [customerId]); 

    const fetchCustomersAndSetSelected = async () => { 
        try {
          const response = await fetch(`${BASE_URL}/api/readcustomers`, { 
            method: 'GET', 
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          const customers = await response.json();
          const selectedCustomer = customers.find(customer => customer.customer_id.toString() === customerId);
          console.log({selectedCustomer});

      
          if (selectedCustomer) {
            setSelectedCustomer(selectedCustomer);
          }
        } catch (error) {
          console.error('Error:', error);
        }
    }

    React.useEffect(() => {
        fetchCustomersAndSetSelected(); 
    }, []);

    const updateCustomerDetails = async () => {
        console.log(selectedCustomer);
        try {
            const response = await fetch(`${BASE_URL}/api/updatecustomer/${selectedCustomer.customer_id}`, {
                method: 'PUT', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(selectedCustomer),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result.message); 

            fetchCustomersAndSetSelected();
            setIsEditMode(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'auto',
            }}
          
        >
            <CssBaseline />
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper 
                    sx={{ 
                        overflow: 'auto', 
                        padding: 4,
                        marginBottom: 2, 
                        maxHeight: '100vh',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between', // Aligns children (title and icon button) on opposite ends
                            alignItems: 'center', // Centers children vertically
                        }}
                    >
                        {isEditMode ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Name"
                                value={selectedCustomer.name}
                                onChange={e => setSelectedCustomer({ ...selectedCustomer, name: e.target.value })}
                            />
                        ) : (
                            <Typography variant="h5">
                                {selectedCustomer.name}
                            </Typography>
                        )}
    
                        <IconButton
                            onClick={() => {
                                if (isEditMode) {
                                    updateCustomerDetails();  // Assuming updateCustomerDetails is your function to save changes
                                }
                                setIsEditMode(!isEditMode);
                            }}
                        >
                            {isEditMode ? <SaveIcon /> : <EditIcon />}
                        </IconButton>

                    </Box>
    
                    {isEditMode ? (
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Address"
                            value={selectedCustomer.address}
                            onChange={e => setSelectedCustomer({ ...selectedCustomer, address: e.target.value })}
                            margin="normal"
                        />
                    ) : (
                        <Typography>
                            {selectedCustomer.address}
                        </Typography>
                    )}
    
                    <Box mt={4}>
                        {isEditMode ? (
                            <>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Contact"
                                    value={selectedCustomer.contact}
                                    onChange={e => setSelectedCustomer({ ...selectedCustomer, contact: e.target.value })}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Phone"
                                    value={selectedCustomer.phone}
                                    onChange={e => setSelectedCustomer({ ...selectedCustomer, phone: e.target.value })}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Email"
                                    value={selectedCustomer.email}
                                    onChange={e => setSelectedCustomer({ ...selectedCustomer, email: e.target.value })}
                                    margin="normal"
                                />
                            </>
                        ) : (
                            <>
                                <Typography>
                                    Contact: {selectedCustomer.contact}
                                </Typography>
                                <Typography>
                                    {selectedCustomer.phone}
                                </Typography>                    
                                <Typography>
                                    {selectedCustomer.email}
                                </Typography>
                            </>
                        )}
                    </Box>
    
                    <Box mt={4}>
                        {isEditMode ? (
                            <>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Delivery Information"
                                    value={selectedCustomer.delivery_notes}
                                    onChange={e => setSelectedCustomer({ ...selectedCustomer, delivery_notes: e.target.value })}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Delivery Time"
                                    value={selectedCustomer.delivery_time}
                                    onChange={e => setSelectedCustomer({ ...selectedCustomer, delivery_time: e.target.value })}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Current Dish"
                                    value={selectedCustomer.current_dish}
                                    onChange={e => setSelectedCustomer({ ...selectedCustomer, current_dish: e.target.value })}
                                    margin="normal"
                                />
                            </>
                        ) : (
                            <>
                                <Typography>
                                    Delivery information:
                                </Typography>
                                <Typography>
                                    {selectedCustomer.delivery_notes}
                                </Typography>
                                <Typography>
                                    Time: {selectedCustomer.delivery_time}
                                </Typography>                    
                                <Typography>
                                    Current dish: {selectedCustomer.current_dish}
                                </Typography>
                            </>
                        )}
                    </Box>
    
                </Paper>
            </Container>
        </Box>
    );

}

export default CustomerDetails;