import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { getUserId } from '../../auth/auth.js'; 
import { mainListItems, blockOrderListItems, extraListItems, LmsListItems, ManageListItems } from './mainListItems.js';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      height: '100%', 
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp, 
          duration: theme.transitions.duration.leavingScreen,
        }), 
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);
 
export default function NavBar({open, handleDrawerClose}) {
  const [openDashboard, setOpenDashboard] = React.useState(false);
  const [openShipments, setOpenShipments] = React.useState(false);
  const [openReceived, setOpenReceived] = React.useState(false);
  const [openCut, setOpenCut] = React.useState(false);
  const [openYield, setOpenYield] = React.useState(false);
  const [openSDW, setOpenSDW] = React.useState(false);
  // const [openTossed, setOpenTossed] = React.useState(false);
  const [hasChatAccess, setHasChatAccess] = React.useState(false);
  const [openManage, setOpenManage] = React.useState(false);

  React.useEffect(() => {
    const ALLOWED_CHAT_USER_IDS = [1, 2, 72];
    const userId = getUserId();
    setHasChatAccess(ALLOWED_CHAT_USER_IDS.includes(Number(userId)));
  }, []);

  const handleClick = (menu) => {
    switch(menu) {
      case 'Dashboard':
        setOpenDashboard(!openDashboard);
        break;
      case 'Shipments':
        setOpenShipments(!openShipments);
        break;
      case 'Received':
        setOpenReceived(!openReceived);
        break;
      case 'Cut':
        setOpenCut(!openCut);
        break;
      case 'Yield':
        setOpenYield(!openYield);
        break;
      case 'SDW':
        setOpenSDW(!openSDW);
        break;
      case 'Manage':
        setOpenManage(!openManage);
        break;
      default:
        break;
    }
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
        
      </Toolbar>
      <Divider />
      <List component="nav">
        {mainListItems({handleClick, openDashboard, openShipments, openReceived, openCut, openYield, openSDW, navBarOpen: open})}
        <Divider sx={{ my: 1 }} />
        {blockOrderListItems}
        <Divider sx={{ my: 1 }} />
        <LmsListItems /> 
        <Divider sx={{ my: 1 }} />
        <ManageListItems handleClick={handleClick} openManage={openManage} navBarOpen={open} />
        <Divider sx={{ my: 1 }} />
        {extraListItems({ hasChatAccess })}
      </List>
      <Divider />
    </Drawer>
  );
} 
