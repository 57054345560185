import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FixedSizeList as VirtualList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DeleteOutlined } from '@ant-design/icons';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function RecentActivity() {
  const token = getToken();
  const [activities, setActivities] = useState([]);
  const [averageYield, setAverageYield] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const ITEM_HEIGHT = 72; // Height of each list item including padding

  const getAverageYield = useCallback((strainName) => {
    const yieldData = averageYield.find(yieldData => yieldData.strain_name === strainName);
    return yieldData ? parseFloat(yieldData.average_yield_per_block).toFixed(1) : 'N/A';
  }, [averageYield]);

  // Memoized activity item renderer
  const ActivityItem = useCallback(({ index, style }) => {
    const activity = activities[index];
    if (!activity) return null;

    return (
      <div style={{ ...style, listStyle: 'none' }}>
        <ListItem sx={{ padding: '14px 0px' }} disablePadding>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1, marginLeft: 0 }}>
            {activity.action === 'add' && (
              <AddOutlinedIcon sx={{ ml: 1, fontSize: '2rem', color: '#00CC00', bgcolor: '#E6FFE6', borderRadius: '50%', p: 0.5 }} />
            )}
            {activity.action === 'edit' && (
              <EditOutlinedIcon sx={{ ml: 1, fontSize: '2rem', color: '#2196F3', bgcolor: '#E3F2FD', borderRadius: '50%', p: 0.5 }} />
            )}
            {activity.action === 'delete' && (
              <DeleteOutlined style={{ marginLeft: '7.5px', fontSize: '1.3rem', color: '#FF6666', backgroundColor: '#FCF0F2', borderRadius: '50%', padding: '7px' }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary={`${activity.source} - ${activity.strainName}`}
            primaryTypographyProps={{ variant: 'body2', fontFamily: 'Inter', fontWeight: 'bold' }}
            secondary={`${activity.timestamp} - ${activity.fullName}`}
          />
          <ListItemSecondaryAction>
            <Typography variant="subtitle1" noWrap>
              ~{getAverageYield(activity.strainName)} lbs
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
        {index < activities.length - 1 && <Divider />}
      </div>
    );
  }, [activities, getAverageYield]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch data in chunks
        const [activitiesResponse, yieldsResponse] = await Promise.all([
          fetch(`${BASE_URL}/api/recentactivity`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }),
          fetch(`${BASE_URL}/api/averageyields`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
        ]);

        if (!isMounted) return;

        const [activitiesData, yieldsData] = await Promise.all([
          activitiesResponse.json(),
          yieldsResponse.json()
        ]);

        if (!isMounted) return;

        // Process data in a non-blocking way
        setTimeout(() => {
          if (!isMounted) return;

          const transformedActivities = activitiesData.data.map(activity => ({
            action: activity.operation_type,
            source: `${activity.operation_type.charAt(0).toUpperCase() + activity.operation_type.slice(1)} ${activity.activity_type.charAt(0).toUpperCase() + activity.activity_type.slice(1)}`,
            strainName: activity.strain_name,
            timestamp: new Date(activity.timestamp).toLocaleString(),
            fullName: `${activity.first_name} ${activity.last_name}`,
          }));

          setActivities(transformedActivities);
          setAverageYield(yieldsData);
          setIsLoading(false);
        }, 0);

      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error);
          setError(error);
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [token]);

  if (isLoading) {
    return (
      <Paper sx={{ padding: 2, height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size={24} />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper sx={{ padding: 2, height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography color="error">Error loading activities</Typography>
      </Paper>
    );
  }

  return (
    <Paper
      elevation={0}
      sx={{
        padding: 1.5,
        height: '300px',
        borderRadius: '10px',
        border: '1px solid #d9d9d9'
      }}
    >
      <Typography variant="h6" sx={{ paddingBottom: 1, paddingLeft: 0.6, fontWeight: '500' }}>
        Recent Activity
      </Typography>

      <Box sx={{ height: 'calc(100% - 40px)', '& ul': { padding: 0 } }}>
        <AutoSizer>
          {({ height, width }) => (
            <VirtualList
              height={height}
              width={width}
              itemCount={activities.length}
              itemSize={ITEM_HEIGHT}
              style={{ padding: 0 }}
            >
              {ActivityItem}
            </VirtualList>
          )}
        </AutoSizer>
      </Box>
    </Paper>
  );
}

export default RecentActivity;