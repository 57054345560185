import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {createTheme, ThemeProvider, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText, OutlinedInput, InputAdornment } from '@mui/material';
import Button from '@mui/material/Button';  
import { styled } from '@mui/system';
import { BASE_URL } from '../../Constants';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import dayjs from 'dayjs'; 
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import { getToken, getFarmId } from '../../auth/auth.js';

dayjs.extend(utc);

const mdTheme = createTheme();

function YieldEdit() {

  const { id } = useParams(); 
  const token = getToken(); 

  // const [numOfBlocks, setNumOfBlocks] = React.useState();
  // const [localNumOfBlocks, setLocalNumOfBlocks] = React.useState(); // Keep a local copy of the state
  const numOfBlocksRef = React.useRef();
  const [blockSource, setBlockSource] = React.useState(''); 
  const [strain, setStrain] = React.useState('');
  const [strainName, setStrainName] = React.useState('');
  const [farm, setFarm] = React.useState('');
  const [farms, setFarms] = React.useState({});
  const [selectedFarmId, setSelectedFarmId] = React.useState('');
  const [flushNum, setFlushNum] = React.useState(1);
  const [weight, setWeight] = React.useState('');
  const [localWeight, setLocalWeight] = React.useState(''); // Keep a local copy of the state
  const weightRef = React.useRef();
  const [inoculationDate, setInoculationDate] = React.useState(null);
  const [cutDate, setCutDate] = React.useState(null);
  const [harvestDate, setHarvestDate] = React.useState(dayjs());
  const [blockSourceMappings, setBlockSourceMappings] = React.useState({});
  const [strainMappings, setStrainMappings] = React.useState({});

  const ContentWrapper = styled('div')({
    flex: '1', // To ensure content takes up remaining space
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetching flush record data
        const response = await fetch(`${BASE_URL}/api/getflushrecord/${id}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
  
        // Fetching mappings data
        const responseMappings = await fetch(`${BASE_URL}/api/mappings`);
        if (!responseMappings.ok) {
            throw new Error(`HTTP error! status: ${responseMappings.status}`);
        }
        const mappingsData = await responseMappings.json();
  
        const strainsMap = mappingsData.strain.reduce((acc, strain) => {
            acc[strain.strain_id] = strain.strain_name;
            return acc;
        }, {});

        const farmsMap = mappingsData.farm.reduce((acc, farm) => {
          acc[farm.farm_id] = farm.farm_name;
          return acc;
        }, {});

        setStrainMappings(strainsMap);
        setFarms(farmsMap);
  
        // Set the fields with the existing values
        // setNumOfBlocks(data.num_of_blocks);
        // setLocalNumOfBlocks(data.num_of_blocks);
        setStrain(data.strain_id);
        setStrainName(strainsMap[data.strain_id]);
        setFarm(data.farm_id);
        setSelectedFarmId(data.farm_id);
        // setFlushNum(data.flush_number);
        setWeight(data.flush_weight);
        setLocalWeight(data.flush_weight);
        // setInoculationDate(dayjs.utc(data.inoculation_date));
        // setCutDate(dayjs.utc(data.cut_date));
        setHarvestDate(dayjs.utc(data.flush_date));
        
      } catch (err) {
        console.error(err);
      }
    };
  
    fetchData();
  }, [id]);

  // const handleChangeNumOfBlocks = (event) => {
  //   setLocalNumOfBlocks(event.target.value);
  // };

  // const handleBlurNumOfBlocks = () => {
  //   setNumOfBlocks(localNumOfBlocks);
  // };

  // React.useEffect(() => {
  //   numOfBlocksRef.current.focus();
  //   if (numOfBlocksRef.current) {
  //     numOfBlocksRef.current.addEventListener('focus', () => {
  //         numOfBlocksRef.current.select();
  //     });
  // }
  // }, [localNumOfBlocks]);
  
  const handleChangeStrain = (event) => {
    setStrain(Number(event.target.value));
    setStrainName(strainMappings[event.target.value]);
  };
   
  const handleChangeFlushNum = (event) => {
    setFlushNum(parseInt(event.target.value));
  };
  
  const handleChangeWeight = (event) => {
    setLocalWeight(event.target.value);
  };

  const handleBlurWeight = () => {
    setWeight(localWeight); // Sync with state when the input field is out of focus
  };

  React.useEffect(() => {
    weightRef.current.focus();
    if (weightRef.current) {
      weightRef.current.addEventListener('focus', () => {
        weightRef.current.select();
      });
  }
  }, [localWeight]);

  const handleInoculationDateChange = (date) => {
    setInoculationDate(date);
  };

  const handleCutDateChange = (date) => {
    setCutDate(date);
  };

  const handleHarvestDateChange = (date) => {
    setHarvestDate(date);
  };

  const handleSubmit = async () => { 

    const data = {
        // numOfBlocks: localNumOfBlocks,
        strain: strain,        
        farm: selectedFarmId,
        // flushNum: flushNum,
        weight: localWeight,
        harvestDate: harvestDate.format('YYYY-MM-DD'),
      };
  
    try {
      const response = await fetch(`${BASE_URL}/api/updateflush/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      console.log(responseData);
      if (response.ok) {
        toast.success('Update successful! Redirecting...', {
          onClose: () => window.location.href = "/yieldview", // Redirects to the home page after the toast message
          autoClose: 1000, // Toast notification will close in 1 second
        });
      } else {
        toast.error('Update failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <CssBaseline />
        <ToastContainer position="top-center" />

          <Toolbar />
          <ContentWrapper>
            <h1></h1>

            {/* Farm */}
            <Box margin="1em" sx={{width:'200px'}}>
              <InputLabel>Farm</InputLabel>
              <Select 
                sx={{ width: '100%' }}
                value={selectedFarmId}
                onChange={(e) => setSelectedFarmId(e.target.value)}
              >
                {Object.entries(farms).map(([farmId, farmName]) => (
                  <MenuItem key={farmId} value={farmId}>  
                    {farmName}  
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {/* Num of blocks */}
            {/* <Box margin="1em">
              <TextField
                type="number"
                id="numOfBlocks"
                value={localNumOfBlocks}
                onChange={handleChangeNumOfBlocks} 
                onBlur={handleBlurNumOfBlocks} 
                label="Number of blocks"
                variant="outlined"
                fullWidth
                inputProps={{ min: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                inputRef={numOfBlocksRef}
              />
            </Box> */}

            {/* Strain */}
            <Box margin="1em">
              <FormControl component="fieldset">
                <FormLabel component="legend">Strain</FormLabel>
                <RadioGroup
                  aria-label="strain"
                  value={strain}
                  onChange={handleChangeStrain}
                  >
                  {Object.entries(strainMappings).map(([id, name]) => (
                    <FormControlLabel key={id} value={id} control={<Radio />} label={name} />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>

            {/* Flush number */}
            {/* <Box margin="1em">
              <FormControl component="fieldset">
                <FormLabel component="legend">Flush number</FormLabel>
                <RadioGroup
                  aria-label="flushNum"
                  value={flushNum}
                  onChange={handleChangeFlushNum}
                  >
                  <FormControlLabel value={1} control={<Radio />} label="1" />
                  <FormControlLabel value={2} control={<Radio />} label="2" />
                  <FormControlLabel value={3} control={<Radio />} label="3" />
                </RadioGroup>
              </FormControl>
            </Box> */}

            {/* Weight */}
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <OutlinedInput
                  id="weight"
                  value={localWeight}
                  onChange={handleChangeWeight}
                  onBlur={handleBlurWeight}
                  endAdornment={<InputAdornment position="end">lbs</InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight', min: 1, inputMode: 'numeric', pattern: '[0-9]*'
                  }}
                  inputRef={weightRef}
                />
                <FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText>
            </FormControl>

            {/* Inoculation date */}
            {/* <Box margin="1em">
                <FormControl variant="outlined" fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker label="Inoculation date" 
                      value={inoculationDate}
                      closeOnSelect={true}
                      onChange={handleInoculationDateChange}
                      textField={<TextField />}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                </FormControl>
            </Box> */}

            {/* Cut date */}
            {/* <Box margin="1em">
              <FormControl variant="outlined" fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker 
                    label="Cut date" 
                    value={cutDate} 
                    closeOnSelect={true}
                    onChange={handleCutDateChange}>
                    <TextField />
                  </DatePicker>
                </LocalizationProvider>
              </FormControl>
            </Box> */}

            {/* Harvest date */}
            <Box margin="1em">
              <FormControl variant="outlined" fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Harvest date"
                    value={harvestDate}
                    closeOnSelect={true}
                    onChange={handleHarvestDateChange}
                    textField={<TextField />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>

            {/* Submit button */}
            <Box margin="1em">
                <Button variant="contained" 
                onClick={handleSubmit}
                >Submit</Button>
            </Box>
        </ContentWrapper>

      </Box>
    </ThemeProvider>
  );
}

export default YieldEdit;