import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Paper, TextField, TableContainer, Table, TableBody, TableHead, TableCell, TableRow, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js'; 
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; 
import { DateRangePicker } from '@mui/x-date-pickers-pro';

function MatrixTable() {

  const [farmData, setFarmData] = React.useState([]);
  const [selectedFarm, setSelectedFarm] = React.useState('');
  const [matrixData, setMatrixData] = React.useState([]); 
  const [dateFilter, setDateFilter] = React.useState('YTD'); 
  const [selectedMonth, setSelectedMonth] = React.useState(null);
  const [customDateRange, setCustomDateRange] = React.useState([null, null]); 
  const [openDatePicker, setOpenDatePicker] = React.useState(false);

  const token = getToken(); 

  const fetchFarms = () => { 
    fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setFarmData(jsonData);
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const fetchMatrixData = async () => { 
    try {

        let queryParams = new URLSearchParams();

        // Add farm filter
        if (selectedFarm) {
            queryParams.append('farmName', selectedFarm);
        }

        if (dateFilter === 'YTD') {
            queryParams.append('ytd', 'true');
        } else if (dateFilter === 'SpecificMonth' && selectedMonth) {
            queryParams.append('month', selectedMonth.toISOString().slice(0, 7)); // Sending the month in 'YYYY-MM' format
        }
        // } else if (dateFilter === 'DateRange' && customDateRange[0] && customDateRange[1]) {
        //     const startDate = customDateRange[0].toISOString().slice(0, 10); // Format as 'YYYY-MM-DD'
        //     const endDate = customDateRange[1].toISOString().slice(0, 10); // Format as 'YYYY-MM-DD'
        //     queryParams.append('startDate', startDate);
        //     queryParams.append('endDate', endDate);
        // }
  
        const url = `${BASE_URL}/api/readmatrixdata?${queryParams}`;
    
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const result = await response.json();
        setMatrixData(result);
    
      } catch (error) {
        console.error('Error fetching data', error);
      }
  }

  React.useEffect(() => {
    fetchFarms();
    fetchMatrixData();
  }, []); 

  React.useEffect(() => {
    fetchMatrixData();
  }, [selectedFarm, dateFilter, selectedMonth]); 

  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };

  const handleMonthChange = (newMonth) => {
    if (!newMonth) return;
  
    const isNewMonthDifferent = !selectedMonth || newMonth.getMonth() !== selectedMonth.getMonth();
    const isNewYearDifferent = !selectedMonth || newMonth.getFullYear() !== selectedMonth.getFullYear();
  
    // Determine if only the year part has changed (and not the month)
    const isOnlyYearChanged = isNewYearDifferent && !isNewMonthDifferent;
  
    // Update the selectedMonth state. If only the year has changed, adjust the year part only.
    if (isOnlyYearChanged) {
      const updatedDate = new Date(selectedMonth);
      updatedDate.setFullYear(newMonth.getFullYear());
      setSelectedMonth(updatedDate);
    } else {
      setSelectedMonth(newMonth);
    }
  
    // Fetch new data only if the month has actually changed or it's a new selection
    if (isNewMonthDifferent) {
      fetchMatrixData();
    }
  };

  return (
      <Box>
        <Box>

            <Box display="flex" justifyContent="flex-start" alignItems="center" padding={1} gap={2}>

              {/* Title */}
              <Typography variant="h6">
                  Yield matrix
              </Typography>

              {/* Farm Filter Dropdown */}
              <FormControl  
                sx={{ minWidth: '23%', maxWidth: '23%', }}
              >
                <InputLabel id="farm-select-label">Filter farm</InputLabel>
                <Select
                  labelId="farm-select-label"
                  id="farm-select"
                  value={selectedFarm}
                  label="Farm"
                  onChange={handleFarmChange}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {farmData.map((farm, index) => (
                    <MenuItem key={`${farm.farm_name}-${index}`} value={farm.farm_name}>{farm.farm_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Date Filter Dropdown */}
              <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="date-filter-label">Date Filter</InputLabel>
                  <Select
                      labelId="date-filter-label"
                      id="date-filter-select"
                      value={dateFilter}
                      label="Date Filter"
                      onChange={handleDateFilterChange}
                  >
                      <MenuItem value="YTD">Year to Date</MenuItem>
                      <MenuItem value="SpecificMonth">Month</MenuItem>
                      <MenuItem value="DateRange">Date Range</MenuItem>
                  </Select>
              </FormControl>

              {/* Conditional Month Picker */}
              {dateFilter === 'SpecificMonth' && (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['year', 'month']}
                            label="Month"
                            openTo="month"
                            value={selectedMonth} 
                            onChange={handleMonthChange}
                            onOpen={() => setOpenDatePicker(true)}
                            onClose={() => setOpenDatePicker(false)}
                            open={openDatePicker}
                            textField={(params) => (
                            <TextField
                                {...params}
                                onClick={() => {
                                if (!openDatePicker) {
                                    setOpenDatePicker(true); // This will open the DatePicker when the TextField is clicked
                                }
                                }}
                            />
                            )}
                        />
                    </LocalizationProvider>
                  </>
              )}

            </Box>

            <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>
                <Table aria-label="blocks tossed table" stickyHeader>

                    <TableHead>
                        <TableRow>

                            {/* Strain */}
                            <TableCell 
                                align="left" 
                                style={{ fontWeight: 'bold', backgroundColor: '#E3F2FD' }}
                            >
                                Strain
                            </TableCell>

                            {/* Blocks received */}
                            <TableCell 
                                align="right" 
                                style={{ fontWeight: 'bold', backgroundColor: '#E3F2FD' }}
                            >
                                Blocks received
                            </TableCell>

                            {/* Predicted yield */}
                            <TableCell 
                                align="right" 
                                style={{ fontWeight: 'bold', backgroundColor: '#E3F2FD' }}
                            >
                                Predicted yield
                            </TableCell>

                            {/* Actual yield */}
                            <TableCell 
                                align="right" 
                                style={{ fontWeight: 'bold', backgroundColor: '#E3F2FD' }}
                            >
                                Actual yield
                            </TableCell>

                            {/* Waste */}
                            <TableCell 
                                align="right" 
                                style={{ fontWeight: 'bold', backgroundColor: '#E3F2FD' }}
                            >
                                Waste
                            </TableCell>

                            {/* Variance */}
                            <TableCell 
                                align="right" 
                                style={{ fontWeight: 'bold', backgroundColor: '#E3F2FD' }}
                            >
                                Variance
                            </TableCell>

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {matrixData.map((row, index) => (
                            <TableRow key={index}>
                            
                                <TableCell align="left">{row.strain_name}</TableCell>
                                <TableCell align="right">{row.blocks_received}</TableCell>
                                <TableCell align="right">{Number(row.predicted_yield).toFixed(2)}</TableCell>
                                <TableCell align="right">{Number(row.actual_yield).toFixed(2)}</TableCell>
                                <TableCell align="right">{row.waste}</TableCell>
                                <TableCell align="right">{Number(row.variance).toFixed(2)}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>

        </Box>

      </Box>
  );
}

export default MatrixTable;