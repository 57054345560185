import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl'; 
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'; 
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js'; 
import sortData from '../common/sortColumns';
import { toast } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { FormHelperText } from '@mui/material';
import SDWGroup from '../common/SDWGroup'
import dayjs from 'dayjs'; 

function TossedView() {
  const [tossedData, setTossedData] = React.useState([]); 
  const [selectedFarm, setSelectedFarm] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState('');
  const [selectedId, setSelectedId] = React.useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [editingRecord, setEditingRecord] = React.useState(null);
  const [farms, setFarms] = React.useState([]);
  const [isFarmValid, setIsFarmValid] = React.useState(true);
  const [editingFarm, setEditingFarm] = React.useState({ id: '', name: '' });
  const [editingDate, setEditingDate] = React.useState(null);

  const farmNames = [...new Set(tossedData.map(record => record.farm_name))];

  const filteredData = selectedFarm
  ? tossedData.filter(record => record.farm_name === selectedFarm)
  : tossedData;

  const sortedTossedData = sortData(filteredData);

  const token = getToken(); 

  const fetchTossedData = () => { 
    fetch(`${BASE_URL}/api/readtossed`, { 
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); 
    })
    .then(data => {
      try {
        const jsonData = JSON.parse(data); 
        setTossedData(jsonData);
        console.log(jsonData);
      } catch(e) {
        console.error("The response is not JSON. Data:", data);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const fetchFarms = () => { 
    return fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setFarms(jsonData);
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  React.useEffect(() => {
    fetchTossedData();
    fetchFarms();
  }, []); 

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
  
    const dateParts = dateString.split('T')[0].split('-');
    // Create a new date object using the local time zone
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };

  const handleClickOpenImage = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenDialog(true);
  };

  const handleCloseImage = () => {
    setOpenDialog(false);
  };

  const deleteTossed = (id) => {
    console.log('In deleteSDW with id:',id); // debug
    setSelectedId(id);
    setOpenDeleteDialog(true); // open the dialog
  }

  const handleConfirmDelete = () => {
    // close the dialog first
    setOpenDeleteDialog(false);
  
    fetch(`${BASE_URL}/api/deletetossedrecord/${selectedId}`, {
      method: 'PUT', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        toast.success('Record successfully deleted!', {
          autoClose: 1000, 
        });
        // Refresh the data
        return fetchTossedData(); 
      }
    })
    .catch(error => {
      console.error('Error:', error);
      toast.error('An error occurred while trying to delete the record');
    });
  }
  
  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  }

  const handleClickOpenEdit = (record) => {
    setEditingRecord({
      tossed_id: record.blocks_wasted_id,
      farm_name: record.farm_name,
      farm_id: record.farm_id,
      strain_name: record.strain_name,
      description: record.description,
      waste_image: record.waste_image,
      who_created: record.who_created,
      sdwTypeTwo: 'blocks',
      reason: record.reason,
      num_of_blocks: record.num_of_blocks.toString(),
      date_tossed: record.date_tossed,
    });
    setEditingDate(formatDateForEdit(record.date_tossed));
    setEditingFarm(record.farm_name);
    setOpenEditDialog(true);
  };

  const handleCloseEdit = () => {
      setOpenEditDialog(false);
      setEditingRecord(null);
  };

  const handleEditChange = (index, updatedState) => {
    console.log(`handleEditChange for index ${index}:`, updatedState);
    setEditingRecord(prevState => {
      let newState = { ...prevState };
  
      if ('strain' in updatedState) {
        newState.strain_name = updatedState.strain;
      }
  
      if ('farm_name' in updatedState) {
        const selectedFarm = farms.find(farm => farm.farm_name === updatedState.farm_name);
        if (selectedFarm) {
          newState.farm_id = selectedFarm.farm_id;
          newState.farm_name = selectedFarm.farm_name;
        }
      }
  
      if ('numOfBlocks' in updatedState) {
        newState.num_of_blocks = updatedState.numOfBlocks.toString();
      }
  
      return { ...newState, ...updatedState };
    });
  };

  const handleSubmitEdit = async () => {
    try {
      const formData = new FormData();
      const targetTable = editingRecord.sdwTypeTwo === 'blocks' ? 'blocksWastedTable' : 'blocksSDWTable';

      const dataToSend = {
        tossed_id: editingRecord.tossed_id,
        farm_name: editingRecord.farm_name,
        farm_id: editingRecord.farm_id,
        strain_name: editingRecord.strain_name,
        date_tossed: editingRecord.date_tossed,
        numOfBlocks: parseFloat(editingRecord.num_of_blocks),
        reason: editingRecord.reason,
        description: editingRecord.description,
        who_created: editingRecord.who_created,
        targetTable,
      };

      console.log({ dataToSend }); // debug
      formData.append('data', JSON.stringify(dataToSend));

      // Handle image upload
      if (editingRecord.sdwImage instanceof File) {
        formData.append('image', editingRecord.sdwImage, editingRecord.sdwImage.name);
        console.log('Appending new sdwImage:', editingRecord.sdwImage.name);
      } else if (editingRecord.waste_image && !editingRecord.waste_image.startsWith('data:image')) {
        // If waste_image is a File or Blob (not a base64 string)
        formData.append('image', editingRecord.waste_image, 'waste_image');
        console.log('Appending existing waste_image');
      } else {
        console.log('No new image to upload, keeping existing image');
      }
      console.log('handleSubmitEdit - editingRecord before sending:', editingRecord); // debug
      console.log('FormData contents:', [...formData.entries()]);
       
      const response = await fetch(`${BASE_URL}/api/sdwedit`, {
        method: 'PUT',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to update record');
      }
  
      const result = await response.json();
      console.log('Update successful:', result); // debug
  
      handleCloseEdit();
      fetchTossedData(); // Refresh the data
    } catch (error) {
      console.error('Error updating record:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const formatDateForEdit = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return dayjs(date.toUTCString().slice(0, -4));
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
        <CssBaseline />

          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>

                <Box 
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, max-content) 1fr auto', // Three columns: button, title, empty space
                    gap: 1, 
                    alignItems: 'center',
                    marginBottom: 2, 
                  }}
                > 

                  {/* Title */}
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', // Centers the title
                      gridColumnStart: 3, // Title starts at the third column
                      gridColumnEnd: 3, // Title ends at the third column
                    }}
                  >
                    <Typography variant="h6" component="h2" gutterBottom>
                      All blocks tossed
                    </Typography>
                  </Box>

                  {/* Farm filter */}
                  <FormControl fullWidth sx={{ gridColumnStart: 4, width: '145px' }}>
                      <InputLabel id="farm-select-label">Filter farm</InputLabel>
                      <Select
                          labelId="farm-select-label"
                          id="farm-select"
                          value={selectedFarm}
                          label="Farm"
                          onChange={handleFarmChange}
                      >
                          <MenuItem value="">
                            <em>All</em>
                          </MenuItem>
                          {farmNames.map(farmName => (
                            <MenuItem key={farmName} value={farmName}>{farmName}</MenuItem>
                          ))}
                      </Select>
                  </FormControl>

                </Box>

                <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>
                  <Table aria-label="blocks tossed table" stickyHeader>

                    <TableHead>
                      <TableRow>

                        {/* Who added */}
                        <TableCell 
                          align="left"
                          // onClick={() => handleSort('num_of_blocks')}
                        >
                          Who added 
                          {/* {sortDirection['num_of_blocks'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} */}
                        </TableCell>

                        {/* Number of blocks */}
                        <TableCell 
                          align="right"
                          // onClick={() => handleSort('num_of_blocks')}
                        >
                          Number of blocks 
                          {/* {sortDirection['num_of_blocks'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} */}
                        </TableCell>

                        {/* Strain */}
                        <TableCell 
                          align="left"
                          // onClick={() => handleSort('strain_name')}
                        >
                          Strain 
                          {/* {sortDirection['strain_namestrain_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} */}
                        </TableCell>

                        {/* Farm */}
                        <TableCell 
                          align="left"
                          // onClick={() => handleSort('farm_name')}
                        >
                          Farm 
                          {/* {sortDirection['farm_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} */}
                        </TableCell>

                        {/* Date Tossed */}
                        <TableCell 
                          align="center"
                          // onClick={() => handleSort('inoculation_date')}
                        >
                          Date tossed 
                          {/* {sortDirection['inoculation_dateinoculation_date'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} */}
                        </TableCell>

                        {/* Reason */}
                        <TableCell 
                            align="center"
                            // onClick={() => handleSort('received_date')}
                        >
                            Reason
                            {/* {sortDirection['received_date'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} */}
                        </TableCell>

                        {/* Description */}
                        <TableCell 
                            align="center"
                            // onClick={() => handleSort('description')}
                        >
                            Description
                            {/* {sortDirection['description'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} */}
                        </TableCell>

                        {/* Image */}
                        <TableCell 
                          align="center"
                          // onClick={() => handleSort('received_date')}
                        >
                          Image
                          {/* {sortDirection['received_date'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} */}
                        </TableCell>

                        {/* Edit Button */}
                        <TableCell 
                          align="center"
                        >
                          Edit
                        </TableCell>

                        {/* Delete Button */}
                        <TableCell 
                          align="center"
                        >
                          Delete
                        </TableCell>

                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {sortedTossedData.map((row, index) => (
                        <TableRow key={index}>
                        
                        <TableCell align="center">{row.who_created}</TableCell>
                        <TableCell align="center">{row.num_of_blocks}</TableCell>
                        <TableCell align="left">{row.strain_name}</TableCell>
                        <TableCell align="left">{row.farm_name}</TableCell>
                        <TableCell align="center">{formatDate(row.date_tossed)}</TableCell>
                        <TableCell align="center">{row.reason}</TableCell>
                        <TableCell align="center">{row.description}</TableCell>

                        <TableCell align="center">
                          {row.waste_image ? (
                            <img 
                              src={row.waste_image}
                              alt="Waste" 
                              style={{ width: '100px', height: 'auto', cursor: 'pointer' }}
                              onClick={() => handleClickOpenImage(row.waste_image)}
                            />
                          ) : (
                            'No image'
                          )}
                        </TableCell>

                        {/* Edit Button */}
                        <TableCell>  
                          <IconButton aria-label="edit" onClick={() => handleClickOpenEdit(row)}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>

                        {/* Delete Button */}
                        <TableCell>  
                          <IconButton aria-label="delete" onClick={() => deleteTossed(row.blocks_wasted_id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>

                        </TableRow>
                      ))}
                    </TableBody>

                  </Table>
                </TableContainer>

                <Dialog
                  onClose={handleCloseImage}
                  aria-labelledby="customized-dialog-title"
                  open={openDialog}
                >
                    <IconButton
                      aria-label="close"
                      onClick={handleCloseImage}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                    </IconButton>
                  <img src={selectedImage} alt="Enlarged" style={{ width: '100%' }} />
                </Dialog>

                <Dialog
                  open={openDeleteDialog}
                  onClose={handleCloseDialog}
                >
                  <DialogTitle>{"Delete from table"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to delete this record?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog}>
                      Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openEditDialog} onClose={handleCloseEdit} maxWidth="sm" >
                  <DialogTitle>Edit Record</DialogTitle>
                  <DialogContent>
                    {editingRecord && (
                      <>
                        {/* DATE & FARM */}
                        <Grid container spacing={1} justifyContent="center" sx={{ mt: 1 }}>

                          {/* DATE */}
                          <Grid item xs={4} sx={{mb:4, ml: 10}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker 
                                label="Date" 
                                value={editingDate} 
                                closeOnSelect={true}
                                onChange={(newDate) => {
                                  setEditingDate(newDate);
                                  handleEditChange(0, { date_tossed: newDate.format('YYYY-MM-DD') });
                                }}
                              >
                              <TextField />
                              </DatePicker>
                            </LocalizationProvider>
                          </Grid>

                          {/* FARM SELECT */}
                          <Grid item xs={5} sx={{mb:4}}>
                            <FormControl fullWidth sx={{ width: '75%' }}>
                              <InputLabel>Farm</InputLabel>
                              <Select
                                value={editingFarm}
                                onChange={(e) => {
                                  const selectedFarm = farms.find(farm => farm.farm_name === e.target.value);
                                  setEditingFarm(e.target.value);
                                  setIsFarmValid(true);
                                  handleEditChange(0, { farm_name: e.target.value });
                                }}
                                error={!isFarmValid}
                              >
                                {farms.map((farm, index) => (
                                  <MenuItem key={index} value={farm.farm_name}>  
                                    {farm.farm_name}  
                                  </MenuItem>
                                ))}
                              </Select>
                              {!isFarmValid && (
                                <FormHelperText error>
                                  Please select a farm 
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>

                        </Grid>

                        <SDWGroup
                          index={0}
                          firstStrain={editingRecord.strain_name}
                          // state={editingRecord}
                          state={{
                            ...editingRecord,
                            numOfBlocks: editingRecord.num_of_blocks,
                          }}
                          onChange={handleEditChange}
                          isOpen={true}
                          style={{ maxWidth: '400px' }}
                          sdw_image={editingRecord.waste_image}
                          disableSDWTypeTwo={true}
                        />

                        <Box mt={2}>
                          <Button onClick={handleCloseEdit}>Cancel</Button>
                          <Button onClick={handleSubmitEdit} color="primary">
                            Save Changes
                          </Button>
                        </Box>

                      </>
                    )}
                  </DialogContent>
                </Dialog>

              </Grid>
            </Grid>
        </Container>

    </Box>
  );
}

export default TossedView;