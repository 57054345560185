import React, { useState, useEffect, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, Box, FormControl, FormLabel, TextField, FormControlLabel, Checkbox, MenuItem, Select, FormHelperText, OutlinedInput, InputAdornment, Accordion, AccordionSummary, InputLabel, AccordionDetails, Typography, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';
import stringSimilarity from 'string-similarity';

const mdTheme = createTheme();

const ContentWrapper = styled('div')({});

const StyledAccordion = styled(Accordion)(({ theme, isHighlighted }) => ({
  borderRadius: '12px !important',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  backgroundColor: isHighlighted ? '#d5ebd0' : 'white',
  transition: 'all 0.3s ease-in-out',
  width: '100%',
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  borderRadius: '12px',
  '&.Mui-expanded': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    transition: 'all 0.2s',
    '&:hover': {
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    '&.Mui-focused': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
  },
}));

const cleanStrainName = (strain) => {
    return strain
        .toLowerCase()
        .replace(/['".,\/#!$%\^&\*;:{}=\-_`~()]/g, "") // Remove special characters
        .replace(/s\b/g, '')  // Remove trailing 's' (plurals)
        .replace(/\s+/g, ' ') // Normalize spaces
        .trim();
};

const findBestMatchingStrain = (inputStrain, availableStrains) => {
    if (!inputStrain || !availableStrains?.length) return null;

    const cleanedInput = cleanStrainName(inputStrain);
    
    // First try exact match after cleaning
    const exactMatch = availableStrains.find(
        strain => cleanStrainName(strain.strain_name) === cleanedInput
    );
    if (exactMatch) return exactMatch;

    // If no exact match, try fuzzy matching
    const strainNames = availableStrains.map(s => cleanStrainName(s.strain_name));
    const { bestMatch } = stringSimilarity.findBestMatch(cleanedInput, strainNames);

    // Only accept matches above a certain similarity threshold
    if (bestMatch.rating > 0.6) { // You can adjust this threshold
        return availableStrains[bestMatch.targetIndex];
    }

    // If no good match found, try partial matching
    const partialMatch = availableStrains.find(strain => 
        cleanStrainName(strain.strain_name).includes(cleanedInput) ||
        cleanedInput.includes(cleanStrainName(strain.strain_name))
    );
    
    return partialMatch || null;
};

function YieldGroup({ 
    index, 
    firstStrain, 
    state, 
    onChange, 
    highlight, 
    isOpen 
}) {

    const { strain = firstStrain, weight = 0, blackBag = false, ipsCheck = false } = state;
    const [localWeight, setLocalWeight] = React.useState(null); // Keep a local copy 
    const weightRef = useRef();
    const [strains, setStrains] = useState([]);

    const [isAccordionOpen, setAccordionOpen] = useState(true);
    const [isHighlighted, setIsHighlighted] = useState(false);

    const [weightError, setWeightError] = useState(false);

    const token = getToken(); 

    // Synchronize localWeight with weight prop
    useEffect(() => {
        if (weight !== localWeight) {
            setLocalWeight(weight);
        }
    }, [weight]);

    //strain mappings
    useEffect(() => {
        const fetchStrains = async () => {
            try {
            const response = await fetch(`${BASE_URL}/api/mappings`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                }
            });
        
            const data = await response.json();
            setStrains(data.strain);
            } catch (error) {
            console.error('Failed to fetch strains', error);
            }
        };
        
        fetchStrains();
    }, []);  

    const handleChangeStrain = (event) => {
        const newStrain = event.target.value;
        onChange(index, { ...state, strain: newStrain }); 
    }  

    // const handleChangeWeight = (event) => {
    //     const value = event.target.value;
    //     if (value === "0") {
    //       setWeightError(true);
    //     } else {
    //       setWeightError(false);
    //     }
    //     setLocalWeight(value);
    // };  

    // const handleBlurWeight = () => {
    //     if (localWeight === "0") {
    //       setWeightError(true);
    //     } else {
    //       setWeightError(false);
    //       onChange(index, { ...state, weight:localWeight });
    //     }
    // };

    const handleChangeWeight = (event) => {
        const value = event.target.value;
        setWeightError(value === "0");
        setLocalWeight(value);
        onChange(index, { ...state, weight: value });
    };
    
    const handleBlurWeight = () => {
        setWeightError(localWeight === "0");
        onChange(index, { ...state, weight: localWeight });
    };

    const handleBlackBagCheck = (event) => {
        const newBlackBag = event.target.checked;
        onChange(index, { ...state, blackBag: newBlackBag }); 
        console.log(newBlackBag); // debug
    }

    const handleIPSCheck = (event) => {
        const newIPSCheck = event.target.checked;
        onChange(index, {...state, ipsCheck: newIPSCheck }); 
        console.log(newIPSCheck); // debug
    }
      
    // useEffect(() => {
    //   setLocalWeight(localWeight);
    // }, [localWeight]);

    useEffect(() => {
        if (highlight) {
            setIsHighlighted(true);
            setTimeout(() => {
                setIsHighlighted(false);
            }, 250);
        }
    }, [highlight]);

    useEffect(() => {
        setAccordionOpen(isOpen);
    }, [isOpen]);

    // Select the content of the weight input when focused
    useEffect(() => {
        if (weightRef.current) {
            weightRef.current.addEventListener('focus', () => {
                weightRef.current.select();
            });
        }
    }, []);

    
    // useEffect(() => {
    //     console.log(`YieldGroup ${index} received props:`, state);
    // }, [index, state]);

    // Update the strain matching effect
    useEffect(() => {
        if (strain && strains.length > 0) {
            const matchingStrain = findBestMatchingStrain(strain, strains);
            
            if (matchingStrain && matchingStrain.strain_name !== strain) {
                // Update with the matched strain name
                onChange(index, { ...state, strain: matchingStrain.strain_name });
                
                // Optionally log the match for debugging
                console.log(`Matched "${strain}" to "${matchingStrain.strain_name}"`);
            }
        }
    }, [strain, strains]);

    return (
        <Box sx={{ 
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        }}>
            <StyledAccordion
                expanded={isAccordionOpen}
                onChange={() => setAccordionOpen(!isAccordionOpen)}
                isHighlighted={isHighlighted}
                sx={{ width: '100%' }}
            >
                <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography sx={{ fontWeight: 500 }}>
                        <span style={{ color: '#1976d2' }}>{strain}</span>
                        <span style={{ marginLeft: 8 }}>
                            weight: <strong>{localWeight || '0'}</strong> lbs
                        </span>
                        <br />
                        <span style={{ color: blackBag ? '#1976d2' : 'text.secondary' }}>
                            Black bags: {blackBag ? 'Yes' : 'No'}
                        </span>
                        <span style={{ marginLeft: 8, color: ipsCheck ? '#1976d2' : 'text.secondary' }}>
                            IPS: {ipsCheck ? 'Yes' : 'No'}
                        </span>
                    </Typography>
                </StyledAccordionSummary>

                <AccordionDetails sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        {/* Strain */}
                        <Grid item xs={12}>
                            <StyledFormControl fullWidth>
                                <FormLabel component="legend" sx={{ mb: 1 }}>Strain</FormLabel>
                                <Select
                                    value={strain}
                                    onChange={handleChangeStrain}
                                    defaultValue={firstStrain}
                                >
                                    {strains.map((strainObj, index) => (
                                        <MenuItem key={index} value={strainObj.strain_name}>
                                            {strainObj.strain_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </StyledFormControl>
                        </Grid>

                        {/* Weight */}
                        <Grid item xs={12}>
                            <StyledFormControl fullWidth>
                                <InputLabel 
                                    htmlFor="weight"
                                    sx={{ 
                                        textDecoration: 'none',
                                        '&.Mui-focused': {
                                            textDecoration: 'none'
                                        }
                                    }}
                                >
                                    Weight
                                </InputLabel>
                                <OutlinedInput
                                    id="weight"
                                    value={localWeight || ''}
                                    onChange={handleChangeWeight}
                                    onBlur={handleBlurWeight}
                                    endAdornment={<InputAdornment position="end">lbs</InputAdornment>}
                                    error={weightError}
                                    inputRef={weightRef}
                                    label="Weight"
                                    inputProps={{
                                        'aria-label': 'weight',
                                        min: 0.01,
                                        step: "any",
                                        inputMode: 'decimal',
                                        type: 'number'
                                    }}
                                />
                                {weightError && (
                                    <FormHelperText error>
                                        Weight cannot be 0!
                                    </FormHelperText>
                                )}
                            </StyledFormControl>
                        </Grid>

                        {/* Checkboxes */}
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', gap: 3 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={blackBag}
                                            onChange={handleBlackBagCheck}
                                            color="primary"
                                        />
                                    }
                                    label="Black bags"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={ipsCheck}
                                            onChange={handleIPSCheck}
                                            color="primary"
                                        />
                                    }
                                    label="IPS"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </StyledAccordion>
        </Box>
    );
}

export default YieldGroup;