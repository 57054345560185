import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js'; 
import sortData from '../common/sortColumns';
import { toast } from 'react-toastify';
import SDWGroup from '../common/SDWGroup'
import dayjs from 'dayjs'; 
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { FormHelperText } from '@mui/material';

function SDWView() {
  const [sdwData, setSDWData] = React.useState([]); 
  const [selectedFarm, setSelectedFarm] = React.useState('');
  const [openImageDialog, setOpenImageDialog] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState('');
  const [selectedId, setSelectedId] = React.useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [editingRecord, setEditingRecord] = React.useState(null);
  const [farms, setFarms] = React.useState([]);
  const [isFarmValid, setIsFarmValid] = React.useState(true);
  const [editingFarm, setEditingFarm] = React.useState({ id: '', name: '' });
  const [editingDate, setEditingDate] = React.useState(null);

  const farmNames = [...new Set(sdwData.map(record => record.farm_name))];

  const filteredData = selectedFarm
  ? sdwData.filter(record => record.farm_name === selectedFarm)
  : sdwData;

  const sortedWasteData = sortData(filteredData);

  const token = getToken(); 

  const fetchSDWData = () => { 
    fetch(`${BASE_URL}/api/readsdw`, { 
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); 
    })
    .then(data => {
      try {
        const jsonData = JSON.parse(data); 
        setSDWData(jsonData);
        console.log(jsonData);
      } catch(e) {
        console.error("The response is not JSON. Data:", data);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const fetchFarms = () => { 
    return fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setFarms(jsonData);
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  React.useEffect(() => {
    fetchSDWData();
    fetchFarms();
  }, []); 

  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
  
    const dateParts = dateString.split('T')[0].split('-');
    // Create a new date object using the local time zone
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const handleClickOpenImage = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenImageDialog(true);
  };

  const handleCloseImage = () => {
    setOpenImageDialog(false);
  };

  const deleteSDW = (id) => {
    console.log('In deleteSDW with id:',id); // debug
    setSelectedId(id);
    setOpenDeleteDialog(true); // open the dialog
  }

  const handleConfirmDelete = () => {
    // close the dialog first
    setOpenDeleteDialog(false);
  
    fetch(`${BASE_URL}/api/deletesdwrecord/${selectedId}`, {
      method: 'PUT', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        toast.success('Record successfully deleted!', {
          autoClose: 1000, 
        });
        // Refresh the data
        return fetchSDWData(); 
      }
    })
    .catch(error => {
      console.error('Error:', error);
      toast.error('An error occurred while trying to delete the record');
    });
  }
  
  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  }

  const handleClickOpenEdit = (record) => {
    setEditingRecord({
      sdw_id: record.sdw_id,
      farm_name: record.farm_name,
      strain_name: record.strain_name,
      sdw_date: record.sdw_date,
      weight: record.weight.toString(),
      sdwType: record.sdw_type,
      description: record.description,
      sdw_image: record.sdw_image,
      who_created: record.who_created,
      sdwTypeTwo: 'fresh',
      reason: '',
    });
    setEditingDate(formatDateForEdit(record.sdw_date));
    setEditingFarm(record.farm_name);
    setOpenEditDialog(true);
  };

  const handleCloseEdit = () => {
      setOpenEditDialog(false);
      setEditingRecord(null);
  };

  const handleEditChange = (index, updatedState) => {
    console.log(`handleEditChange for index ${index}:`, updatedState);
    setEditingRecord(prevState => {
      if ('strain' in updatedState) {
        updatedState.strain_name = updatedState.strain;
        delete updatedState.strain;
      }
      // Handle farm updates
      if ('farm_id' in updatedState && 'farm_name' in updatedState) {
        return {
          ...prevState,
          farm_id: updatedState.farm_id,
          farm_name: updatedState.farm_name
        };
      }
      return {...prevState, ...updatedState};
    });
  };

  const handleSubmitEdit = async () => {
    try {
      const formData = new FormData();
      const targetTable = editingRecord.sdwTypeTwo === 'blocks' ? 'blocksWastedTable' : 'blocksSDWTable';

      const dataToSend = {
        sdw_id: editingRecord.sdw_id,
        farm_name: editingRecord.farm_name,
        farm_id: editingRecord.farm_id,
        strain_name: editingRecord.strain_name,
        sdw_date: editingRecord.sdw_date,
        weight: parseFloat(editingRecord.weight),
        sdwType: editingRecord.sdwType,
        description: editingRecord.description,
        who_created: editingRecord.who_created,
        reason: editingRecord.reason,
        targetTable,
      };
  
      formData.append('data', JSON.stringify(dataToSend));
  
    // Check for both sdwImage and sdw_image
    if (editingRecord.sdwImage instanceof File) {
      formData.append('image', editingRecord.sdwImage);
    } else if (editingRecord.sdw_image instanceof File) {
      formData.append('image', editingRecord.sdw_image);
    }
      console.log('handleSubmitEdit - editingRecord before sending:', editingRecord); // debug
      console.log('FormData contents:', [...formData.entries()]);
       
      const response = await fetch(`${BASE_URL}/api/sdwedit`, {
        method: 'PUT',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to update record');
      }
  
      const result = await response.json();
      // console.log('Update successful:', result); // debug
  
      handleCloseEdit();
      fetchSDWData(); // Refresh the data
    } catch (error) {
      console.error('Error updating record:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const formatDateForEdit = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return dayjs(date.toUTCString().slice(0, -4));
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />

        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

          {/* Title and farm filter */}
          <Box 
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, max-content) 1fr auto', // Three columns: button, title, empty space
              gap: 1, 
              alignItems: 'center',
              marginBottom: 2, 
            }}
          > 

            {/* Title */}
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', // Centers the title
                gridColumnStart: 3, // Title starts at the third column
                gridColumnEnd: 3, // Title ends at the third column
              }}
            >
              <Typography variant="h6" component="h2" gutterBottom>
                Samples, donated or other wasted blocks
              </Typography>
            </Box>

            {/* Farm filter */}
            <FormControl fullWidth sx={{ gridColumnStart: 4, width: '145px' }}>
              <InputLabel id="farm-select-label">Filter farm</InputLabel>
              <Select
                labelId="farm-select-label"
                id="farm-select"
                value={selectedFarm}
                label="Farm"
                onChange={handleFarmChange}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {farmNames.map(farmName => (
                  <MenuItem key={farmName} value={farmName}>{farmName}</MenuItem>
                ))}
              </Select>
            </FormControl>

          </Box>

          {/* Table */}
          <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>
            <Table aria-label="blocks tossed table" stickyHeader>

              <TableHead>
                <TableRow>

                  {/* Who added */}
                  <TableCell 
                    align="left"
                  >
                    Who added 
                  </TableCell>

                  {/* Strain */}
                  <TableCell 
                    align="left"
                  >
                    Strain 
                  </TableCell>

                  {/* Farm */}
                  <TableCell 
                    align="left"
                  >
                    Farm 
                  </TableCell>

                  {/* Date Tossed */}
                  <TableCell 
                    align="center"
                  >
                    Date  
                  </TableCell>

                  {/* Weight */}
                  <TableCell 
                    align="center"
                  >
                    Weight  
                  </TableCell>

                  {/* Reason */}
                  <TableCell 
                    align="center"
                  >
                    Reason
                  </TableCell>

                  {/* Description */}
                  <TableCell 
                    align="center"
                  >
                    Description
                  </TableCell>

                  {/* Image */}
                  <TableCell 
                    align="center"
                  >
                    Image
                  </TableCell>

                  {/* Edit Button */}
                  <TableCell 
                    align="center"
                  >
                    Edit
                  </TableCell>

                  {/* Delete Button */}
                  <TableCell 
                    align="center"
                  >
                    Delete
                  </TableCell>

                </TableRow>
              </TableHead>

              <TableBody>
                {sortedWasteData.map((row, index) => (
                  <TableRow key={index}>
                  
                  <TableCell align="left">{row.who_created}</TableCell>
                  <TableCell align="left">{row.strain_name}</TableCell>
                  <TableCell align="left">{row.farm_name}</TableCell>
                  <TableCell align="center">{formatDate(row.sdw_date)}</TableCell>
                  <TableCell align="center">{row.weight}</TableCell>
                  <TableCell align="center">{row.sdw_type}</TableCell>
                  <TableCell align="center">{row.description}</TableCell>

                  {/* Image */}
                  <TableCell align="center">
                    {row.sdw_image ? (
                      <img 
                        src={row.sdw_image}
                        alt="Image" 
                        style={{ width: '100px', height: 'auto', cursor: 'pointer' }} 
                        onClick={() => handleClickOpenImage(row.sdw_image)}
                      />
                    ) : (
                      'No image'
                    )}
                  </TableCell>

                  {/* Edit Button */}
                  <TableCell>  
                    <IconButton 
                      aria-label="edit" 
                      onClick={() => handleClickOpenEdit(row)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>

                  {/* Delete Button */}
                  <TableCell>  
                    <IconButton aria-label="delete" onClick={() => deleteSDW(row.sdw_id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>

                  </TableRow>
                ))}
              </TableBody>

            </Table>
          </TableContainer>

          <Dialog
            onClose={handleCloseImage}
            aria-labelledby="customized-dialog-title"
            open={openImageDialog}
          >
              <IconButton
                aria-label="close"
                onClick={handleCloseImage}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              </IconButton>
            <img src={selectedImage} alt="Enlarged" style={{ width: '100%' }} />
          </Dialog>

          <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDialog}
          >
            <DialogTitle>{"Delete from table"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this record?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={openEditDialog} onClose={handleCloseEdit} maxWidth="sm" >
            <DialogTitle>Edit Record</DialogTitle>
            <DialogContent>
              {editingRecord && (
                <>
                  {/* DATE & FARM */}
                  <Grid container spacing={1} justifyContent="center" sx={{ mt: 1 }}>

                    {/* DATE */}
                    <Grid item xs={4} sx={{mb:4, ml: 10}}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                          label="Date" 
                          value={editingDate} 
                          closeOnSelect={true}
                          onChange={(newDate) => {
                            setEditingDate(newDate);
                            handleEditChange(0, { sdw_date: newDate.format('YYYY-MM-DD') });
                          }}
                        >
                        <TextField />
                        </DatePicker>
                      </LocalizationProvider>
                    </Grid>

                    {/* FARM SELECT */}
                    <Grid item xs={5} sx={{mb:4}}>
                    <FormControl fullWidth sx={{ width: '75%' }}>
                      <InputLabel>Farm</InputLabel>
                      <Select
                        value={editingFarm}
                        onChange={(e) => {
                          const selectedFarm = farms.find(farm => farm.farm_name === e.target.value);
                          setEditingFarm(e.target.value);
                          setIsFarmValid(true);
                          handleEditChange(0, { farm_name: e.target.value });
                        }}
                        error={!isFarmValid}
                      >
                        {farms.map((farm, index) => (
                          <MenuItem key={index} value={farm.farm_name}>  
                            {farm.farm_name}  
                          </MenuItem>
                        ))}
                      </Select>
                      {!isFarmValid && (
                        <FormHelperText error>
                          Please select a farm 
                        </FormHelperText>
                      )}
                    </FormControl>
                    </Grid>

                  </Grid>

                  <SDWGroup
                    index={0}
                    firstStrain={editingRecord.strain_name}
                    state={editingRecord}
                    onChange={handleEditChange}
                    isOpen={true}
                    style={{ maxWidth: '400px' }}
                    sdw_image={editingRecord.sdw_image}
                    disableSDWTypeTwo={true}
                  />
                  <Box mt={2}>
                    <Button onClick={handleCloseEdit}>Cancel</Button>
                    <Button onClick={handleSubmitEdit} color="primary">
                      Save Changes
                    </Button>
                  </Box>
                </>
              )}
            </DialogContent>
          </Dialog>

        </Container> 

    </Box>
  );
}

export default SDWView;