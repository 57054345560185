import React from 'react';
import Box from '@mui/material/Box';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function NewYieldPerBlock() {
    const token = getToken(); 

    const [yieldPerBlock, setYieldPerBlock] = React.useState([]);

    const fetchYieldPerBlock = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/getyieldperblock`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const data = await response.json();
            setYieldPerBlock(data);
            // console.log(data); // debug
        } catch (error) {
            console.error('Failed to fetch recent activities:', error);
        }
    };

    React.useEffect(() => {
        fetchYieldPerBlock();
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <Box>
            <Paper>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Strain</TableCell>
                                <TableCell>Farm</TableCell>
                                <TableCell>Yield/Block</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {yieldPerBlock.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.strain_name}</TableCell>
                                    <TableCell>{row.farm_name}</TableCell>
                                    <TableCell>{row.average_yield_per_block.toFixed(2)}</TableCell>
                                    <TableCell>{formatDate(row.start_date)}</TableCell>
                                    <TableCell>{formatDate(row.end_date)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Typography>
                SELECT <br></br>
                    s.strain_name,<br></br>
                    f.farm_name,<br></br>
                    SUM(fl.flush_weight) / SUM(br.num_of_blocks) AS average_yield_per_block,<br></br>
                    br.inoculation_date AS start_date,<br></br>
                    DATE_ADD(br.inoculation_date, INTERVAL s.days_to_yield_inoc DAY) AS end_date<br></br>
                FROM<br></br>
                    flush fl<br></br>
                JOIN<br></br>
                    blocks_received br ON fl.strain_id = br.strain_id AND fl.farm_id = br.farm_id<br></br>
                JOIN<br></br>
                    strain s ON fl.strain_id = s.strain_id<br></br>
                JOIN<br></br>
                    farm f ON fl.farm_id = f.farm_id<br></br>
                WHERE<br></br>
                    fl.flush_date BETWEEN br.inoculation_date AND DATE_ADD(br.inoculation_date, INTERVAL s.days_to_yield_inoc DAY)<br></br>
                GROUP BY<br></br>
                    s.strain_name,<br></br>
                    f.farm_name,<br></br>
                    br.inoculation_date,<br></br>
                    s.days_to_yield_inoc;<br></br>
            </Typography>
        </Box>
    );
}

export default NewYieldPerBlock;
