import React from 'react';
import {
  Box,
  CssBaseline,
  Container,
  Toolbar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox
} from '@mui/material';
import { BASE_URL } from '../../Constants';
import { getToken, getFarmId } from '../../auth/auth.js';

function OrdersWeekly() {
  const token = getToken();
  const farmId = getFarmId();

  const [customers, setCustomers] = React.useState([]);
  const [weeks, setWeeks] = React.useState([]);
  const [months, setMonths] = React.useState({});
  const [weeklyOrders, setWeeklyOrders] = React.useState({});
  const [contactedWeeks, setContactedWeeks] = React.useState({});
  const [changedContactedWeeks, setChangedContactedWeeks] = React.useState({});
  const [isEditMode, setIsEditMode] = React.useState(false);

  const fetchCustomers = () => {
    return fetch(`${BASE_URL}/api/readcustomers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      setCustomers(data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const fetchWeeklyOrders = () => {
    if (weeks.length > 0) {
      const startWeek = weeks[0].start;
      const endWeek = weeks[weeks.length - 1].start;

      fetch(`${BASE_URL}/api/getweeklyorders?startDate=${startWeek}&endDate=${endWeek}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => response.json())
      .then(data => {
        const weeklyOrdersByCustomer = mapWeeklyDataToCustomers(data);
        setWeeklyOrders(weeklyOrdersByCustomer);
        // console.log({ data }) // debug
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
  };

  const fetchContactedWeeks = () => {
    return fetch(`${BASE_URL}/api/getcontactedweeks`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => response.json())
    .then(data => {
      const contactedWeeksByCustomer = mapContactedWeeksToCustomers(data);
      setContactedWeeks(contactedWeeksByCustomer);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const generateWeeks = () => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - currentDate.getDay() - 28); // Go back 4 weeks to the start of a week
    let weeksArr = [];
    let monthCounts = {};

    for (let i = 0; i < 12; i++) { // Generate weeks for about 3 months
      let startOfWeek = new Date(currentDate);
      let endOfWeek = new Date(currentDate);
      endOfWeek.setDate(endOfWeek.getDate() + 6); // End of the week
      let monthKey = startOfWeek.toLocaleString('default', { month: 'long' });
      weeksArr.push({
        start: startOfWeek.toISOString().split('T')[0],
        end: endOfWeek.toISOString().split('T')[0],
        month: monthKey
      });
      monthCounts[monthKey] = (monthCounts[monthKey] || 0) + 1;
      currentDate.setDate(currentDate.getDate() + 7); // Next week
    }
    setWeeks(weeksArr);
    setMonths(monthCounts);
  };

  const mapWeeklyDataToCustomers = (weeklyOrdersData) => {
    const weeklyOrdersByCustomer = {};

    weeklyOrdersData.forEach(order => {
      const { customer_id, order_date } = order;

      // Find the week range the order_date falls into
      const orderWeek = weeks.find(week => {
        const orderDate = new Date(order_date);
        const startDate = new Date(week.start);
        const endDate = new Date(week.end);
        return orderDate >= startDate && orderDate <= endDate;
      });

      if (orderWeek) {
        if (!weeklyOrdersByCustomer[customer_id]) {
          weeklyOrdersByCustomer[customer_id] = {};
        }
        weeklyOrdersByCustomer[customer_id][orderWeek.start] = true;
      }
    });
    return weeklyOrdersByCustomer;
  };

  const mapContactedWeeksToCustomers = (contactedWeeksData) => {
    const contactedWeeksByCustomer = {};
  
    contactedWeeksData.forEach(contact => {
      const { customer_id, week_start_date } = contact;
  
      if (!contactedWeeksByCustomer[customer_id]) {
        contactedWeeksByCustomer[customer_id] = {};
      }
      contactedWeeksByCustomer[customer_id][week_start_date] = true;
    });
  
    return contactedWeeksByCustomer;
  };

  React.useEffect(() => {
    fetchCustomers();
    generateWeeks();
  }, []);

  React.useEffect(() => {
    if (weeks.length > 0) {
      fetchWeeklyOrders();
      fetchContactedWeeks();
    }
  }, [weeks]);

  const toggleEditMode = () => {
    setIsEditMode(prevMode => !prevMode);
  };

  const handleCheckboxChange = (customerId, week_start_date) => {
    setChangedContactedWeeks(prev => {
      const isInitiallyChecked = weeklyOrders[customerId]?.[week_start_date] || contactedWeeks[customerId]?.[week_start_date];
      const isCurrentlyChecked = prev[customerId]?.[week_start_date] ?? isInitiallyChecked;
  
      const updatedState = {
        ...prev,
        [customerId]: {
          ...prev[customerId],
          [week_start_date]: !isCurrentlyChecked
        }
      };
  
      if (!updatedState[customerId][week_start_date]) {
        updatedState[customerId][week_start_date] = false;
      }
  
      console.log("Checkbox state changed:", updatedState);
      return updatedState;
    });
  };
  
  const handleSave = () => {
    const updates = [];
    const deletes = [];
  
    Object.keys(changedContactedWeeks).forEach(customerId => {
      Object.keys(changedContactedWeeks[customerId]).forEach(week_start_date => {
        if (changedContactedWeeks[customerId][week_start_date]) {
          updates.push({
            customer_id: customerId,
            week_start_date: week_start_date,
            contacted_or_ordered: true
          });
        } else {
          deletes.push({
            customer_id: customerId,
            week_start_date: week_start_date
          });
        }
      });
    });
  
    // First, handle updates (inserts/updates)
    const updatePromise = updates.length > 0 ? fetch(`${BASE_URL}/api/updatecontactedweeks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(updates)
    }) : Promise.resolve();
  
    // Then, handle deletes (soft deletes)
    const deletePromise = deletes.length > 0 ? fetch(`${BASE_URL}/api/deletecontactedweeks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(deletes)
    }) : Promise.resolve();
  
    Promise.all([updatePromise, deletePromise])
      .then(([updateResponse, deleteResponse]) => {
        if (updateResponse && !updateResponse.ok) {
          throw new Error(`HTTP error! status: ${updateResponse.status}`);
        }
        if (deleteResponse && !deleteResponse.ok) {
          throw new Error(`HTTP error! status: ${deleteResponse.status}`);
        }
        return Promise.all([updateResponse ? updateResponse.json() : null, deleteResponse ? deleteResponse.json() : null]);
      })
      .then(([updateData, deleteData]) => {
        console.log('Successfully updated', updateData);
        console.log('Successfully deleted', deleteData);
        setIsEditMode(false);
        fetchContactedWeeks();
        setChangedContactedWeeks({});
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    >
      <CssBaseline />
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <TableContainer component={Paper} sx={{ maxHeight: '80vh', overflow: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow key="months-row">
                {Object.entries(months).map(([month, count], index, arr) => (
                  <TableCell
                    key={month}
                    align="center"
                    colSpan={index === arr.length - 1 ? count + 1 : count}
                    sx={{
                      borderRight: index === arr.length - 1 ? 'none' : '1px solid',
                      borderColor: 'divider',
                      backgroundColor: 'white',
                      position: 'sticky',
                      top: 0, // Stick the month headers at the very top
                      zIndex: 2 // Higher z-index ensures this layer is above the week rows
                    }}
                  >
                    {month}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow key="weeks-row">
                <TableCell
                  sx={{
                    borderRight: '1px solid',
                    borderColor: 'divider',
                    position: 'sticky',
                    top: 48, // Adjust this value based on the actual height of your month header
                    backgroundColor: 'white',
                    zIndex: 1
                  }}
                ></TableCell>
                {weeks.map((week, index) => (
                  <TableCell
                    key={week.start}
                    align="center"
                    sx={{
                      borderRight: '1px solid',
                      borderColor: 'divider',
                      position: 'sticky',
                      top: 48, // Same value as the empty cell to align correctly
                      backgroundColor: 'white',
                      zIndex: 1
                    }}
                  >
                    {week.start.split('-')[2]}-{week.end.split('-')[2]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            
            <TableBody>
              {customers.map((customer) => (
                <TableRow key={customer.customer_id}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderRight: '1px solid',
                      borderColor: 'divider'
                    }}
                  >
                    {customer.name}
                  </TableCell>
                  {weeks.map((week, index) => (
                    <TableCell
                      key={`${customer.customer_id}-${week.start}`} // Ensuring unique key
                      align="center"
                      sx={{ 
                        borderRight: '1px solid',
                        borderColor: 'divider'
                      }}
                    >
                      <Checkbox
                        checked={
                          isEditMode
                            ? (changedContactedWeeks[customer.customer_id]?.[week.start] ?? 
                              (weeklyOrders[customer.customer_id]?.[week.start] || 
                              contactedWeeks[customer.customer_id]?.[week.start])) || 
                              false
                            : (weeklyOrders[customer.customer_id]?.[week.start] || 
                              contactedWeeks[customer.customer_id]?.[week.start]) || 
                              false
                        }
                        onChange={() => handleCheckboxChange(customer.customer_id, week.start)}
                        disabled={!isEditMode}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>

          </Table>
        </TableContainer>
        
        {/* Edit/Save button */}
        <Box margin="1em" display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={isEditMode ? handleSave : toggleEditMode}>
            {isEditMode ? "Save" : "Edit"}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default OrdersWeekly;
