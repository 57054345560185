import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { FormControl, InputLabel, Select, MenuItem, Box, Button } from '@mui/material';
import { BASE_URL } from '../Constants';
import { useNavigate } from 'react-router-dom';
import { getToken, getUserId } from './auth.js';

const mdTheme = createTheme();

function UpdateFarm() {

  const [farms, setFarms] = useState([]); // array to hold farm data from API
  const [selectedFarm, setSelectedFarm] = useState(''); // state to hold the value of the selected farm

  const navigate = useNavigate();
  const token = getToken();
  const currentUser = getUserId();

  // Fetching farms from API when component mounts
  useEffect(() => {
      fetch(`${BASE_URL}/api/readfarms`, {
        headers: {
          Authorization: `Bearer ${token}` 
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const mappedFarms = data.map(farm => ({id: farm.farm_id, name: farm.farm_name}));
        setFarms(mappedFarms);
      })
      .catch(error => console.error('Error:', error));
  }, []);

  // Handler function for farm selection 
  const handleChangeFarm = (event) => {
    setSelectedFarm(event.target.value);
  };

  const handleSubmit = () => {
    const requestOptions = {
      method: 'PUT',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ farm_id: selectedFarm, who_created: currentUser })
    };

    fetch(`${BASE_URL}/api/updatefarm`, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log(data);

        // Navigate to the home page after successful submission
        navigate('/');
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' 
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
      >
        <CssBaseline />

          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <FormControl variant="outlined" fullWidth sx={{minWidth: 260}}>
              <InputLabel id="farm-label">Your farm</InputLabel>
              <Select
                labelId="farm-label"
                id="farm"
                value={selectedFarm}
                onChange={handleChangeFarm}
                label="Farm"
              >
                {farms.map(farm => (
                  <MenuItem key={farm.id} value={farm.id}>
                    {farm.name}
                  </MenuItem>
                ))}
              </Select><br></br>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Select farm
              </Button>
            </FormControl>
          </Container>

      </Box>
    </ThemeProvider>
  );
}

export default UpdateFarm;