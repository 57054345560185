import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Select from'@mui/material/Select';
import MenuItem from'@mui/material/MenuItem';
import InputLabel from'@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';  
import Typography from '@mui/material/Typography';
import { BASE_URL } from '../../Constants';
import { getToken, getUserId } from '../../auth/auth.js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const mdTheme = createTheme();

const ContentWrapper = styled('div')({
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  });

function AddUser() {

  const [farms, setFarms] = useState([]); // array to hold farm data from API

  const [selectedFarm, setSelectedFarm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const token = getToken(); 
  const userId = getUserId(); 

  // Fetching farms from API when component mounts
  useEffect(() => {
    fetch(`${BASE_URL}/api/readfarms`, {
      headers: {
        Authorization: `Bearer ${token}` 
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      const mappedFarms = data.map(farm => ({id: farm.farm_id, name: farm.farm_name}));
      setFarms(mappedFarms);
    })
    .catch(error => console.error('Error:', error));
  }, []);

  // Handler function for farm selection 
  const handleChangeFarm = (event) => {
    setSelectedFarm(event.target.value);
  };

  // Email validation utility function
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  function isValidPassword(password) {
    if (password === "") return true;

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  }

  const handleSubmit = () => {
    if (!isValidEmail(email)) {
        toast.error('Invalid Email', {
          autoClose: 3000,  
          position: toast.POSITION.TOP_CENTER,
        });
        return;
    }  

    if (password !== "" && !isValidPassword(password)) {
        toast.error('Invalid password. It must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.', {
          autoClose: false,
          closeButton: true,
          position: toast.POSITION.TOP_CENTER,
        });
        return; // Return early to prevent sending request
    }

    const userData = {
      // selectedFarm,
      firstName,
      lastName,
      email,
      password,
      userId,
    };

    fetch(`${BASE_URL}/api/adduser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization':   `Bearer ${token}`
      },
      body: JSON.stringify(userData),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to add user');
      }
    })
    .then((data) => {
      console.log('Success:', data);
        navigate('/manageusers');
        toast.success('Success!', {
            autoClose: 2000,  // Auto close after 2 seconds
            position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((error) => {
        console.error('Error:', error);
    });

    //add email to allow list?
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'auto',
            pt: '2em', 

          }}
          
      >
        <CssBaseline />

          <Toolbar />
          <ContentWrapper>
            
            <Box sx={{
              display: 'flex',
              justifyContent: 'center', 
            }}>
              <Typography color="#1976D2" variant="h6" component="h2" gutterBottom>
                New user details
              </Typography>
            </Box>

            <Paper 
              sx={{ 
                padding: '1em',
                maxWidth: '500px',
              }}>

              {/* Farm */}
              {/* <Box margin="1em">
                <FormControl fullWidth>
                  <InputLabel id="farm-label">Farm</InputLabel>
                  <Select
                    labelId="farm-label"
                    id="farm"
                    value={selectedFarm}
                    onChange={handleChangeFarm}
                    label="Farm"
                  >
                    {farms.map(farm => (
                      <MenuItem key={farm.id} value={farm.id}>
                        {farm.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box> */}

              {/* First Name */}
              <Box margin="1em">
                  <TextField
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
              </Box>

              {/* Last Name */}
              <Box margin="1em">
                <TextField
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Box>

              {/* Email */}
              <Box margin="1em">
                <TextField
                  label="Email"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Box>

              {/* Password */}
              <Box margin="1em">
                <TextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Box>
            
            </Paper>

            {/* Submit button */}
            <Button sx= {{ marginTop: '20px' }} variant="contained" color="primary" onClick={handleSubmit}>
                Create user
            </Button>

          </ContentWrapper>

      </Box>
    </ThemeProvider>
  );
}

export default AddUser;