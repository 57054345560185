import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Constants';
import { toast } from 'react-toastify';
import { getToken } from '../../auth/auth.js';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import sortData from '../common/sortColumns';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormLabel from '@mui/material/FormLabel';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function ShipmentView() {
  const [shipmentData, setShipmentData] = React.useState([]); 
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [sortField, setSortField] = React.useState('shipment_date');
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [modalData, setModalData] = React.useState(null);
  const [modalShipmentDetails, setModalShipmentDetails] = React.useState([]);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [farms, setFarms] = React.useState([]);
  const [strains, setStrains] = React.useState([]); 
  const [selectedFarm, setSelectedFarm] = React.useState('');

  const [sortDirection, setSortDirection] = React.useState({
    num_of_blocks: 'desc',
    strain_name: 'desc',
    inoculation_date: 'desc',
    shipment_date: 'desc',
  }); 

  const filteredData = selectedFarm
  ? shipmentData.filter(record => record.farm_name === selectedFarm)
  : shipmentData;

  const farmNames = [...new Set(shipmentData.map(record => record.farm_name))];

  const sortedShipmentData = sortData(filteredData, sortField, sortDirection[sortField]);

  const navigate = useNavigate();
  const token = getToken();

  const fetchShipmentData = () => { 
    fetch(`${BASE_URL}/api/readshipment`, { 
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); 
    })
    .then(data => {
      try {
        const jsonData = JSON.parse(data); 
        setShipmentData(jsonData);
      } catch(e) {
        console.error("The response is not JSON. Data:", data);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const fetchModalData = () => { 
    fetch(`${BASE_URL}/api/readmodalshipmentdetails`, { 
      method: 'GET',  
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); 
    })
    .then(data => {
      try {
        const jsonData = JSON.parse(data); 
        setModalData(jsonData);
      } catch(e) {
        console.error("The response is not JSON. Data:", data);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const fetchFarms = () => { 
    return fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setFarms(jsonData);
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const fetchStrains = () => {
    fetch(`${BASE_URL}/api/readstrains`, { 
      method: 'GET', 
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); 
    })
    .then(data => {
      try {
        const jsonData = JSON.parse(data); 
        setStrains(jsonData);
      } catch(e) {
        console.error("The response is not JSON. Data:", data);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  React.useEffect(() => {
    fetchShipmentData();    
    fetchModalData();   
    fetchFarms();
    fetchStrains();
  }, []); 

  const deleteShipmentRecord = (id) => { 
    setSelectedId(id);
    setOpenDialog(true); // open the dialog
  }

  const handleConfirmDelete = () => {
    // close the dialog first
    setOpenDialog(false);

  
    fetch(`${BASE_URL}/api/deleteshipmentrecord/${selectedId}`, { 
      method: 'PUT', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        toast.success('Record successfully deleted!', {
          autoClose: 1000, // Toast notification will close in 1 second
        });
        // Refresh the data
        return fetchShipmentData(); 
      }
    })
    .catch(error => {
      console.error('Error:', error);
      toast.error('An error occurred while trying to delete the record');
    });
  }
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';

    const dateParts = dateString.split('T')[0].split('-');
    // Create a new date object using the local time zone
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const handleSort = (field) => {
    // Toggle the sort direction for the specific field
    const newDirection = sortDirection[field] === 'asc' ? 'desc' : 'asc';
  
    // Update the sort directions state
    setSortDirection({
      ...sortDirection,
      [field]: newDirection
    });
  
    // Set the sort field
    setSortField(field);
  };

  const handleClickOpen = (row) => {
    console.log("Clicked row data: ", row); 
    const filteredData = modalData.filter(item => item.shipment_id === row.shipment_id);

    // Log the filtered data to see if farm_id is included
    console.log("Filtered data: ", filteredData);

    setModalShipmentDetails(filteredData);
    setOpen(true);

    // If filteredData contains any records, take farm_id from the first record
    if (filteredData.length > 0) {
        const farmId = filteredData[0].farm_id;
        setSelectedRow({
        ...row,
        farm_id: farmId
        });
    } else {
        setSelectedRow({
        ...row
        });
    }
    // Store filteredData in a variable to be used in the Ship button onClick
    row.modalShipmentDetails = filteredData;
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditMode(false);
  };  

  const handleSelectedRowChange = (e, fieldName) => {
    if (fieldName === 'farm_id') {
        const selectedFarmId = e.target.value;
        const farmObj = farms.find(farm => farm.farm_id == selectedFarmId); // using double equals because `e.target.value` returns a string
        setSelectedRow(prevState => ({
            ...prevState,
            farm_id: selectedFarmId,
            farm_name: farmObj.farm_name
        }));
    } else {
        setSelectedRow(prevState => ({
            ...prevState,
            [fieldName]: e.target.value
        }));
    }
  };

  const handleEditChange = (eventOrValue, shipmentLineId, fieldName) => {
    let newModalShipmentDetails = [...modalShipmentDetails];
    let value = eventOrValue;
  
    // Determine if the parameter is an event or a direct value
    if (eventOrValue.target && eventOrValue.target.value !== undefined) {
      value = eventOrValue.target.value;
    }
  
    // Find the item by shipment_line_id and update the value based on the field type
    const itemIndex = newModalShipmentDetails.findIndex(item => item.shipment_line_id === shipmentLineId);
    if (itemIndex !== -1) {
      if (fieldName === 'num_of_blocks') {
        newModalShipmentDetails[itemIndex][fieldName] = parseInt(value, 10);
      } else {
        newModalShipmentDetails[itemIndex][fieldName] = value;
      }
    }
  
    setModalShipmentDetails(newModalShipmentDetails);
  };
  
  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };
  
  const handleSubmitEdits = async () => {
    // Filter out invalid records first
    const validShipmentDetails = modalShipmentDetails.filter(detail => {
      const strainIsValid = strains.some(s => s.strain_name === detail.strain_name);
      const qtyIsValid = detail.num_of_blocks > 0;
      const inoculationIsValid = detail.inoculation_date != null;
    
      console.log(`Strain valid: ${strainIsValid}, Qty valid: ${qtyIsValid}, Inoculation valid: ${inoculationIsValid}`); // Debug each condition
    
      return strainIsValid && qtyIsValid && inoculationIsValid;
    });
    
    if (validShipmentDetails.length !== modalShipmentDetails.length) {
      console.log("Invalid details:", modalShipmentDetails.filter(detail => !validShipmentDetails.includes(detail)));
      toast.error('Some records are incomplete or invalid. Please correct them before submitting.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    // Use the filtered valid details to create the update payload
    const updatedModalShipmentDetails = validShipmentDetails.map(detail => {
      const strain = strains.find(s => s.strain_name === detail.strain_name);
      return {
          ...detail,
          strain_id: strain ? strain.strain_id : null, // Ensure you handle null if strain is not found
          inoculation_date: detail.inoculation_date ? new Date(detail.inoculation_date).toISOString() : null
      };
    });
  
    const payload = {
      farmId: selectedRow.farm_id,
      shipDate: selectedRow.shipment_date,
      shipmentDetails: updatedModalShipmentDetails
    };
  
    console.log({payload}); // debug
  
    const response = await fetch(`${BASE_URL}/api/updateshipment/${selectedRow.shipment_id}`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });
  
    if (response.ok) {
      const result = await response.json();
      console.log(result.message);
      setIsEditMode(false);
  
      fetchShipmentData();
      fetchModalData();
      toast.success('Shipment details updated successfully!');
    } else {
      console.error("Failed to update shipment details.");
      toast.error('Failed to update shipment details.');
    }
  };
  
  const handleAddStrain = () => {
    const shipmentId = modalShipmentDetails.length > 0 ? modalShipmentDetails[0].shipment_id : null; 
    const farmId = modalShipmentDetails.length > 0 ? modalShipmentDetails[0].farm_id : null; 
    
    const newStrain = {
      shipment_id: shipmentId,
      farm_id: farmId,
      strain_name: '', 
      num_of_blocks: 0,
      isNew: true
    };
    
    setModalShipmentDetails([...modalShipmentDetails, newStrain]);
  };

  const handleRemoveStrain = (shipmentLineIdToRemove) => {
    const updatedModalShipmentDetails = modalShipmentDetails.map(item => {
      if (item.shipment_line_id === shipmentLineIdToRemove) {
        return { ...item, isDeleted: true };
      }
      return item;
    });

    setModalShipmentDetails(updatedModalShipmentDetails);
  };

  const handleReceive = (row) => {
    const modalDetails = modalData.filter(item => item.shipment_id === row.shipment_id);
    console.log({ modalShipmentDetails });
    console.log({ modalDetails })
    navigate('/addreceived', { 
      state: { 
        shipmentDetails: row, 
        modalShipmentDetails: modalDetails, // Assuming this is the data you want to pass
      } 
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>

            <Box 
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, max-content) 1fr auto', // Three columns: button, title, empty space
                gap: 1, 
                alignItems: 'center',
                marginBottom: 2, 
              }}
            >

              {/* Title */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', // Centers the title
                  gridColumnStart: 3, // Title starts at the third column
                  gridColumnEnd: 3, // Title ends at the third column
                }}
              >
                <Typography variant="h6" component="h2" gutterBottom>
                  All shipments
                </Typography>
              </Box>

              {/* Farm filter */}
              <FormControl fullWidth sx={{ gridColumnStart: 4, width: '145px' }}>
                <InputLabel id="farm-select-label">Filter farm</InputLabel>
                  <Select
                    labelId="farm-select-label"
                    id="farm-select"
                    value={selectedFarm}
                    label="Farm"
                    onChange={handleFarmChange}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {farmNames.map(farmName => (
                      <MenuItem key={farmName} value={farmName}>{farmName}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>
              <Table aria-label="blocks shipped table" stickyHeader>
                
                <TableHead>
                  <TableRow>

                    {/* Date shipped */}
                    <TableCell 
                      align="center"
                      onClick={() => handleSort('shipment_date')}
                    >
                      Date shipped {sortDirection['shipment_date'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>

                    {/* Farm */}
                    <TableCell 
                      align="left"
                      onClick={() => handleSort('farm_name')}
                    >
                      Farm {sortDirection['farm_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>

                    {/* Total blocks */}
                    <TableCell 
                      align="right"
                      onClick={() => handleSort('total_num_of_blocks')}
                    >
                      Total blocks {sortDirection['total_num_of_blocks'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </TableCell>

                    <TableCell align="center">Notes</TableCell>
                    <TableCell align="center">Receive</TableCell>
                    {/* <TableCell align="center">Edit</TableCell> */}
                    <TableCell align="center">Delete</TableCell>
                    
                  </TableRow>
                </TableHead> 

                <TableBody>
                  {sortedShipmentData.map((row, index) => (
                    <TableRow key={index} onClick={() => handleClickOpen(row)}>
                      <TableCell align="center">{formatDate(row.shipment_date)}</TableCell>
                      <TableCell align="left">{row.farm_name}</TableCell>
                      <TableCell align="center">{row.total_num_of_blocks}</TableCell>
                      <TableCell align="right">{row.shipment_notes}</TableCell>

                      {/* Receive Button */}
                      <TableCell>  
                        <Button 
                            variant="contained" 
                            onClick={(e) => {
                                e.stopPropagation();
                                handleReceive(row, row.modalShipmentDetails);
                            }}
                        >
                            Receive
                        </Button>
                      </TableCell>

                      {/* Delete Button */} 
                      <TableCell>  
                        <IconButton aria-label="delete" onClick={() => deleteShipmentRecord(row.shipment_id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                      
                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </TableContainer>

          </Grid>
        </Grid>
      </Container>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {isEditMode ? (
            <div>
              <label>Farm: </label>
              <select 
                value={selectedRow ? selectedRow.farm_id : ''} 
                onChange={(e) => handleSelectedRowChange(e, 'farm_id')}
              >
                {farms.map((farm, index) => (
                  <option key={index} value={farm.farm_id}>{farm.farm_name}</option>
                ))}
              </select>
            </div>
          ) : (
            selectedRow ? selectedRow.farm_name : ''
          )}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {isEditMode ? (
              <div>
                <label>Ship Date: </label>
                <input 
                  type="date" 
                  value={selectedRow ? new Date(selectedRow.shipment_date).toISOString().split('T')[0] : ''} 
                  onChange={(e) => handleSelectedRowChange(e, 'shipment_date')} 
                />
              </div>
            ) : (
              `Ship Date: ${selectedRow ? formatDate(selectedRow.shipment_date) : ''}`
            )}
          </DialogContentText>

          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {modalShipmentDetails.filter(item => !item.isDeleted).map((item) => (
                  <TableRow key={item.shipment_line_id}>

                    {/* X Icon to remove strain */}
                    {isEditMode && (
                      <TableCell>
                        <IconButton 
                          onClick={() => handleRemoveStrain(item.shipment_line_id)} 
                          aria-label="remove strain"
                        >
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    )}

                    {/* Strain */}
                    <TableCell>
                      {isEditMode ? (
                        <>
                          {item.isNew ? ( 
                            <FormControl fullWidth variant="outlined">
                              <FormLabel component="legend">Strain</FormLabel>
                              <Select
                                sx={{ width: '150px'}}
                                value={item.strain_name}
                                onChange={(e) => handleEditChange(e, item.shipment_line_id, 'strain_name')}
                              >
                                {strains.map((strain) => (
                                  <MenuItem key={strain.strain_id} value={strain.strain_name}>
                                    {strain.strain_name}
                                  </MenuItem>
                                ))}
                              </Select>

                              {/* Inoculation Date */}
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                sx={{ mt: 1, width: '150px'}}
                                label="Inoc date"
                                value={item.inoculation_date}
                                closeOnSelect={true}
                                onChange={(newDate) => handleEditChange(newDate, item.shipment_line_id, 'inoculation_date')}
                                renderInput={(params) => <TextField {...params} />}
                              />
                              </LocalizationProvider>
                            </FormControl>
                          ) : (
                            item.strain_name
                          )}
                        </>
                      ) : (
                        item.strain_name // Optionally show inoculation date in view mode
                      )}
                    </TableCell>
                    
                    {/* QTY */}
                    <TableCell>
                      {isEditMode ? (
                        <FormControl fullWidth variant="outlined">
                          <FormLabel component="legend">Qty</FormLabel>
                          <TextField 
                            sx={{ maxWidth: 75 }}
                            type="number" 
                            value={item.num_of_blocks.toString()} 
                            onChange={(e) => handleEditChange(e, item.shipment_line_id, 'num_of_blocks')} 
                            inputProps={{ autoFocus: true }}
                            onFocus={(event) => event.target.select()}
                          />
                        </FormControl>
                      ) : (
                        item.num_of_blocks
                      )}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {isEditMode && (
            <Box 
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '15px'
              }}
            >
              <Button variant="contained" color="primary" onClick={handleAddStrain}>
                Add strain
              </Button>
            </Box>
          )}


        </DialogContent>

        <DialogActions>
          {isEditMode ? (
            <Button onClick={handleSubmitEdits}>Save</Button>
          ) : (
            <Button onClick={() => setIsEditMode(true)}>Edit</Button>
          )}
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>

      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{"Delete from shipment table"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

export default ShipmentView;