import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Paper, Grid, Typography, Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@mui/material';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';
import sortData from '../common/sortColumns';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

function InoculationSchedule() {
  const [inoculationScheduleData, setInoculationScheduleData] = React.useState([]); 
  const [selectedFarm, setSelectedFarm] = React.useState('');

  const [sortDirection, setSortDirection] = React.useState({
    num_of_blocks: 'desc',
    strain_name: 'desc',
    inoculation_date: 'desc',
    shipment_date: 'desc',
  }); 

  const filteredData = selectedFarm
  ? inoculationScheduleData.filter(record => record.FarmNames === selectedFarm)
  : inoculationScheduleData;

  const farmNames = [...new Set(inoculationScheduleData.map(record => record.FarmNames))];

  const sortedInoculationScheduleData = sortData(filteredData);

  const token = getToken(); 

  const fetchInoculationScheduleData = () => { 
    fetch(`${BASE_URL}/api/readinoculationschedule`, { 
      method: 'GET',  
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); 
    })
    .then(data => {
      try {
        const jsonData = JSON.parse(data); 
        setInoculationScheduleData(jsonData);
        console.log(jsonData);
      } catch(e) {
        console.error("The response is not JSON. Data:", data);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  useEffect(() => {
      fetchInoculationScheduleData();    
  }, []); 

  const isToday = (dateString) => {
    const localDate = new Date(dateString);
    const today = new Date();
  
    // Adjust for the time zone offset from UTC
    const timeZoneOffset = today.getTimezoneOffset() * 60000;
    const localMidnight = new Date(today.setHours(0, 0, 0, 0) - timeZoneOffset);
  
    return (
      localDate >= localMidnight &&
      localDate < new Date(localMidnight.getTime() + 86400000)
    );
  };
  
  // format date bc of JavaScript time zone conversions
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return `${monthNames[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`;
  };

  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />

        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}> 
              <Grid item xs={12} md={12} lg={12}>

                <Box 
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, max-content) 1fr auto', // Three columns: button, title, empty space
                    gap: 1, 
                    alignItems: 'center',
                    marginBottom: 2, 
                  }}
                >

                  {/* Title */}
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', // Centers the title
                      gridColumnStart: 3, // Title starts at the third column
                      gridColumnEnd: 3, // Title ends at the third column
                    }}
                  >
                    <Typography variant="h6" component="h2" gutterBottom>
                      Inoculation Schedule
                    </Typography>
                  </Box>

                  {/* Farm filter */}
                  <FormControl fullWidth sx={{ gridColumnStart: 4, width: '145px' }}>
                    <InputLabel id="farm-select-label">Filter farm</InputLabel>
                      <Select
                        labelId="farm-select-label"
                        id="farm-select"
                        value={selectedFarm}
                        label="Farm"
                        onChange={handleFarmChange}
                      >
                        <MenuItem value="">
                          <em>All</em>
                        </MenuItem>
                        {farmNames.map(farmName => (
                          <MenuItem key={farmName} value={farmName}>{farmName}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>

                <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>
                  <Table aria-label="incoculation schedule table" stickyHeader>

                    <TableHead>
                      <TableRow>
                        <TableCell>Inoculation Date</TableCell>
                        <TableCell>Strain</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Farm</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {sortedInoculationScheduleData.map((row, index) => (
                        <TableRow key={index} sx={{ backgroundColor: isToday(row.InoculationDate) ? '#E6FFE6' : 'inherit' }}>
                          
                          <TableCell>
                            {formatDate(row.InoculationDate.split('T')[0])}
                          </TableCell>

                          <TableCell>
                            {row.StrainName}
                          </TableCell>

                          <TableCell>  
                            {row.TotalNumberOfBlocks}
                          </TableCell>

                          <TableCell>  
                            {row.FarmNames}
                          </TableCell>
                        </TableRow>
                      ))} 
                    </TableBody>

                  </Table>
                </TableContainer>

              </Grid>
          </Grid>
        </Container>

    </Box>
  );
}

export default InoculationSchedule;