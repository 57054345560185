import React, { Suspense } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { getUserId } from '../../auth/auth.js';

const mdTheme = createTheme();

// Lazy load components
const InventoryBarChart = React.lazy(() => import('./InventoryBarChart'));
const WastePieChart = React.lazy(() => import('./WastePieChart'));
const RecentActivity = React.lazy(() => import('./RecentActivity'));

// Loading placeholder
const LoadingPlaceholder = () => (
  <Paper sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
    <Typography>Loading...</Typography>
  </Paper>
);

function Dashboard() {
  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
        }}
      >
        <CssBaseline />
        <Toolbar />

        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}> 
            <Grid item xs={12}> 
              <Suspense fallback={<LoadingPlaceholder />}>
                <InventoryBarChart />
              </Suspense>
            </Grid>
            <Grid item xs={12}> 
              <Suspense fallback={<LoadingPlaceholder />}>
                <WastePieChart />
              </Suspense>
            </Grid>
            <Grid item xs={12}>
              <Suspense fallback={<LoadingPlaceholder />}>
                <RecentActivity />
              </Suspense>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;