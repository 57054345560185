import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Constants';
import { toast } from 'react-toastify';
import { getToken } from '../../auth/auth.js';
import sortData from '../common/sortColumns';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { json } from 'd3';

function CutView() {
  const [cutData, setCutData] = React.useState([]); 
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedFarm, setSelectedFarm] = React.useState('');

  const [sortDirection, setSortDirection] = React.useState({
    num_of_blocks: 'desc',
    strain_name: 'desc',
    inoculation_date: 'desc',
    shipment_date: 'desc',
  }); 

  const filteredData = selectedFarm
  ? cutData.filter(record => record.farm_name === selectedFarm)
  : cutData;

  const farmNames = [...new Set(cutData.map(record => record.farm_name))];

  const sortedCutData = sortData(filteredData);


  const navigate = useNavigate();
  const token = getToken(); 

  const fetchCutData = () => { 
    fetch(`${BASE_URL}/api/readcut`, { 
      method: 'GET',  
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); 
    })
    .then(data => {
      try {
        const jsonData = JSON.parse(data); 
        setCutData(jsonData);
        console.log(jsonData); // debug
      } catch(e) {
        console.error("The response is not JSON. Data:", data);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const deleteCutRecord = (id) => { 
    setSelectedId(id);
    setOpenDialog(true); // open the dialog
  }

  const handleConfirmDelete = () => {
    // close the dialog first
    setOpenDialog(false);
  
    fetch(`${BASE_URL}/api/deletecutrecord/${selectedId}`, { 
      method: 'PUT', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        toast.success('Record successfully deleted!', {
          autoClose: 1000, // Toast notification will close in 1 second
        });
        // Refresh the data
        return fetchCutData(); 
      }
    })
    .catch(error => {
      console.error('Error:', error);
      toast.error('An error occurred while trying to delete the record');
    });
  }
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const editCutRecord = (id) => {
    navigate(`/cutedit/${id}`);
  };

  React.useEffect(() => {
    fetchCutData(); // Call fetchCutData on initial render    
  }, []); 

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
  
    const dateParts = dateString.split('T')[0].split('-');
    // Create a new date object using the local time zone
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };

  return (
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
        }}
      
      >
        <CssBaseline />
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                
                <Box 
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, max-content) 1fr auto', // Three columns: button, title, empty space
                    gap: 1, 
                    alignItems: 'center',
                    marginBottom: 2, 
                  }}
                >

                  {/* Title */}
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', // Centers the title
                      gridColumnStart: 3, // Title starts at the third column
                      gridColumnEnd: 3, // Title ends at the third column
                    }}
                  >
                    <Typography variant="h6" component="h2" gutterBottom>
                      All cut blocks
                    </Typography>
                  </Box>

                  {/* Farm filter */}
                  <FormControl fullWidth sx={{ gridColumnStart: 4, width: '145px' }}>
                    <InputLabel id="farm-select-label">Filter farm</InputLabel>
                      <Select
                        labelId="farm-select-label"
                        id="farm-select"
                        value={selectedFarm}
                        label="Farm"
                        onChange={handleFarmChange}
                      >
                        <MenuItem value="">
                          <em>All</em>
                        </MenuItem>
                        {farmNames.map(farmName => (
                          <MenuItem key={farmName} value={farmName}>{farmName}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>

                <TableContainer component={Paper}>

                  <Table sx={{ minWidth: 650 }} aria-label="simple table">

                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Number of blocks</TableCell>
                        <TableCell align="left">Strain</TableCell>
                        <TableCell align="left">Farm</TableCell>
                        <TableCell align="left">Shelf</TableCell>
                        <TableCell align="center">Inoculation date</TableCell>
                        <TableCell align="center">Cut date</TableCell>
                        <TableCell align="center">Edit</TableCell>
                        <TableCell align="center">Delete</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {sortedCutData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="right">{row.num_of_blocks}</TableCell>
                          <TableCell align="left">{row.strain_name}</TableCell>
                          <TableCell align="left">{row.farm_name}</TableCell>
                          <TableCell align="left">{row.batch_shelf ? row.batch_shelf : "N/A"}</TableCell>
                          <TableCell align="center">{formatDate(row.inoculation_date)}</TableCell>
                          <TableCell align="center">{formatDate(row.cut_date)}</TableCell>

                          <TableCell>  {/* Edit Button */}
                            <IconButton aria-label="edit" onClick={() => editCutRecord(row.cut_id)}>
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>  {/* Delete Button */}
                            <IconButton aria-label="delete" onClick={() => deleteCutRecord(row.cut_id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  
                  </Table>
                </TableContainer>

              </Grid>
            </Grid>
          </Container>

        <Dialog
          open={openDialog} 
          onClose={handleCloseDialog}
        >
          <DialogTitle>{"Delete from cut table"}</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this record?
            </DialogContentText>
          </DialogContent>

          <DialogActions>

            <Button onClick={handleCloseDialog}>
              Cancel
            </Button>

            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
              Delete
            </Button>

          </DialogActions>

        </Dialog>

      </Box>
  );
}

export default CutView;