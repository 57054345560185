// REACT IMPORTS
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

// AUTH IMPORTS
// import SignUp from './auth/SignUp';
import LogIn from './auth/LogIn';
import UpdateFarm from './auth/UpdateFarm';
import ProtectedRoute from './auth/ProtectedRoute'; 

// NAV BAR IMPORTS
import { NavBarProvider, NavBarContext } from './components/navigation/NavBarContext';
import TopBar from './components/navigation/TopBar';
import NavBar from './components/navigation/NavBar';

// MAIN PAGE IMPORTS
import Dashboard from './components/dashboard/Dashboard';
import DashboardOld from './components/dashboard/DashboardOld';
import ShipmentInput from './components/shipments/ShipmentInput';
import ShipmentView from './components/shipments/ShipmentView';
import ShipmentEdit from './components/shipments/ShipmentEdit';
import ReceivedInput from './components/received/ReceivedInput';
import ReceivedView from './components/received/ReceivedView';
import ReceivedEdit from './components/received/ReceivedEdit';
import CutInput from './components/cut/CutInput';
import CutView from './components/cut/CutView';
import CutEdit from './components/cut/CutEdit';
import YieldInput from './components/yield/YieldInput'; 
import YieldView from './components/yield/YieldView';
import YieldEdit from './components/yield/YieldEdit';
import UserProfile from './components/user/UserProfile';
import UserEdit from './components/user/UserEdit';
import TossedView from './components/tossed/TossedView';
import SDWInput from './components/sdw/SDWInput';
import SDWView from './components/sdw/SDWView';
import LMSNewCourseForm from './components/lms/LMSAddCourse';
// MANAGE PAGE IMPORTS 
import ManageUsers from './components/manage/ManageUsers';
import AddUser from './components/user/AddUser';
import ManageRegions from './components/manage/ManageRegions';
import ManageFarms from './components/manage/ManageFarms';
import ManageStrains from './components/manage/ManageStrains';
import ManageSources from './components/manage/ManageSources';
import ManageCustomers from './components/manage/ManageCustomers';

// ORDER IMPORTS
import BlockOrders from './components/orders/BlockOrders';
import OrderList from './components/orders/OrderList';
import InoculationSchedule from './components/orders/InoculationSchedule';

// CUSTOMER IMPORTS
import CustomerDetails from './components/customers/CustomerDetails';
import NewCustomerOrder from './components/customers/NewCustomerOrder';
import CustomerOrderList from './components/customers/CustomerOrderList';
import OrdersWeekly from './components/customers/OrdersWeekly';

// FARM DASHBOARD IMPORTS
import FarmDashboard from './components/farms/FarmDashboard';

// AI IMPORTS
import ChatAI from './components/ai-chat/ChatAI';

//LMS IMPORTS
import LMSHome from './components/lms/LMSHome';
import LMSVideoActivityLog from './components/lms/LMSVideoActivityLog';
import LMSNewCourse from './components/lms/lmsAddVideos';
import LMSAddCourse from './components/lms/LMSAddCourse';


const mdTheme = createTheme();

function App() {
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavBarProvider>
          <Router>
            <NavigationWrapper />
          </Router>
        </NavBarProvider>
      </Box>
      <ToastContainer position="top-center"/>
    </ThemeProvider>
  );
}

function NavigationWrapper() {
  const location = useLocation();
  const { toggleNavBar, openNavBar } = React.useContext(NavBarContext);
  const noNavRoutes = ["/login", "/signup"]; 

  const prevPathname = React.useRef(location.pathname);

  // This effect will run whenever the location changes
  React.useEffect(() => {
    // If the location pathname has changed and the NavBar is open, close it
    if (prevPathname.current !== location.pathname && openNavBar) {
      toggleNavBar();
    }
    // Update the previous pathname for the next render
    prevPathname.current = location.pathname;
  }, [location.pathname, toggleNavBar, openNavBar]); // Notice the dependency on location.pathname

  return (
    <>
      {!noNavRoutes.includes(location.pathname) && <TopBarContainer />} 
      {!noNavRoutes.includes(location.pathname) && <NavBarContainer />}

      <Routes>
        <Route path="/login" element={<LogIn />} />
        {/* <Route path="/signup" element={<SignUp /> } /> */}
        <Route path="/selectfarm" element={<UpdateFarm />} />
        <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/userprofile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
        <Route path="/useredit/:id" element={<ProtectedRoute><UserEdit /></ProtectedRoute>} />
        <Route path="/addshipment" element={<ProtectedRoute><ShipmentInput /></ProtectedRoute>} /> 
        <Route path="/shipmentview" element={<ProtectedRoute><ShipmentView /></ProtectedRoute>} /> 
        <Route path="/shipmentedit/:id" element={<ProtectedRoute><ShipmentEdit /></ProtectedRoute>} />
        <Route path="/addreceived" element={<ProtectedRoute><ReceivedInput /></ProtectedRoute>} /> 
        <Route path="/receivedview" element={<ProtectedRoute><ReceivedView /></ProtectedRoute>} /> 
        <Route path="/receivededit/:id" element={<ProtectedRoute><ReceivedEdit /></ProtectedRoute>} />
        <Route path="/addcut" element={<ProtectedRoute><CutInput /></ProtectedRoute>} /> 
        <Route path="/cutview" element={<ProtectedRoute><CutView /></ProtectedRoute>} /> 
        <Route path="/cutedit/:id" element={<ProtectedRoute><CutEdit /></ProtectedRoute>} />
        <Route path="/addyield" element={<ProtectedRoute><YieldInput /></ProtectedRoute>} />
        <Route path="/yieldview" element={<ProtectedRoute><YieldView /></ProtectedRoute>} />
        <Route path="/yieldedit/:id" element={<ProtectedRoute><YieldEdit /></ProtectedRoute>} />
        <Route path="/manageusers" element={<ProtectedRoute><ManageUsers /></ProtectedRoute>} />
        <Route path="/manageregions" element={<ProtectedRoute><ManageRegions /></ProtectedRoute>} />
        <Route path="/managefarms" element={<ProtectedRoute><ManageFarms /></ProtectedRoute>} />
        <Route path="/managestrains" element={<ProtectedRoute><ManageStrains /></ProtectedRoute>} />
        <Route path="/managesources" element={<ProtectedRoute><ManageSources /></ProtectedRoute>} />
        <Route path="/managecustomers" element={<ProtectedRoute><ManageCustomers /></ProtectedRoute>} />
        <Route path="/adduser" element={<ProtectedRoute><AddUser /></ProtectedRoute>} />
        <Route path="/blockorder" element={<ProtectedRoute><BlockOrders /></ProtectedRoute>} />
        <Route path="/blockorderlist" element={<ProtectedRoute><OrderList /></ProtectedRoute>} />
        <Route path="/inoculationschedule" element={<ProtectedRoute><InoculationSchedule /></ProtectedRoute>} />
        <Route path="/farm/:farmId" element={<ProtectedRoute><FarmDashboard /></ProtectedRoute>} />
        <Route path="/chatai" element={<ProtectedRoute><ChatAI /></ProtectedRoute>} />
        <Route path="/tossedview" element={<ProtectedRoute><TossedView /></ProtectedRoute>} />
        <Route path="/sdwview" element={<ProtectedRoute><SDWView /></ProtectedRoute>} />
        <Route path="/addsdw" element={<ProtectedRoute><SDWInput /></ProtectedRoute>} />
        <Route path="/customer/:customerId" element={<ProtectedRoute><CustomerDetails /></ProtectedRoute>} />
        <Route path="/customerorder" element={<ProtectedRoute><NewCustomerOrder /></ProtectedRoute>} />
        <Route path="/customerorderlist" element={<ProtectedRoute><CustomerOrderList /></ProtectedRoute>} />
        <Route path="/weeklyorders" element={<ProtectedRoute><OrdersWeekly /></ProtectedRoute>} />
        <Route path="/olddashboard" element={<ProtectedRoute><DashboardOld /></ProtectedRoute>} />
        <Route path="/lmshome" element={<ProtectedRoute><LMSHome /></ProtectedRoute>} />
        <Route path="/lmsactivitylog" element={<ProtectedRoute><LMSVideoActivityLog /></ProtectedRoute>} />
        <Route path="/LMSAddVideos" element={<ProtectedRoute><LMSNewCourse /></ProtectedRoute>} />
        <Route path="/lmsaddcourse" element={<ProtectedRoute><LMSAddCourse /></ProtectedRoute>} />
      </Routes>
    </>
  )
}

function TopBarContainer() {
  const { openNavBar, toggleNavBar } = React.useContext(NavBarContext);
  return <TopBar open={openNavBar} toggleDrawer={toggleNavBar} />;
}

function NavBarContainer() {
  const { openNavBar, toggleNavBar } = React.useContext(NavBarContext);
  return <NavBar open={openNavBar} handleDrawerClose={toggleNavBar} />;
}

export default App;