import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {createTheme, ThemeProvider, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText, OutlinedInput, InputAdornment } from '@mui/material';
import Button from '@mui/material/Button';  
import { styled } from '@mui/system';
import { BASE_URL } from '../../Constants';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import dayjs from 'dayjs'; 
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import { getToken } from '../../auth/auth.js';


dayjs.extend(utc);

const mdTheme = createTheme();

function CutEdit() {

  const { id } = useParams();  // get the id from the url parameters

  const [numOfBlocks, setNumOfBlocks] = React.useState();
  const [localNumOfBlocks, setLocalNumOfBlocks] = React.useState(); // Keep a local copy of the state
  const numOfBlocksRef = React.useRef();
  const [strain, setStrain] = React.useState('');
  const [inoculationDate, setInoculationDate] = React.useState(null);
  const [cutDate, setCutDate] = React.useState(null);
  const [strainMappings, setStrainMappings] = React.useState({});
  const [strainName, setStrainName] = React.useState('');

  const ContentWrapper = styled('div')({
    flex: '1', // To ensure content takes up remaining space
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetching cut record data
        const response = await fetch(`${BASE_URL}/api/getcutrecord/${id}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
  
        // Fetching mappings data
        const responseMappings = await fetch(`${BASE_URL}/api/mappings`);
        if (!responseMappings.ok) {
            throw new Error(`HTTP error! status: ${responseMappings.status}`);
        }
        const mappingsData = await responseMappings.json();
  
        // Reduce the mappings to a map where the key is the ID and the value is the name
        const strainsMap = mappingsData.strain.reduce((acc, strain) => {
            acc[strain.strain_id] = strain.strain_name;
            return acc;
        }, {});
  
        // Set strain mappings
        setStrainMappings(strainsMap);
  
        // Set the fields with the existing values
        setNumOfBlocks(data.num_of_blocks);
        setLocalNumOfBlocks(data.num_of_blocks);
        setStrain(data.strain_id);
        setStrainName(strainsMap[data.strain_id]);
        setInoculationDate(dayjs.utc(data.inoculation_date));
        setCutDate(dayjs.utc(data.cut_date));
      } catch (err) {
        console.error(err);
      }
    };
  
    fetchData();
  }, [id]);  // Run when the id changes  

  const handleChangeNumOfBlocks = (event) => {
    setLocalNumOfBlocks(event.target.value);
  };

  const handleBlurNumOfBlocks = () => {
    setNumOfBlocks(localNumOfBlocks);
  };

  React.useEffect(() => {
    numOfBlocksRef.current.focus();
  }, [localNumOfBlocks]);
  
  const handleChangeStrain = (event) => {
    setStrain(Number(event.target.value));
    setStrainName(strainMappings[event.target.value]);
  };

  const handleInoculationDateChange = (date) => {
    setInoculationDate(date);
  };

  const handleCutDateChange = (date) => {
    setCutDate(date);
  };

  const handleSubmit = async () => { 
    const token = getToken(); 

    const data = {
        numOfBlocks: localNumOfBlocks,
        strain: strain, 
        inoculationDate: inoculationDate.format('YYYY-MM-DD'),
        cutDate: cutDate.format('YYYY-MM-DD'),
      };
  
    try {
      const response = await fetch(`${BASE_URL}/api/updatecut/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      console.log(responseData);
      if (response.ok) {
        toast.success('Update successful! Redirecting...', {
          onClose: () => window.location.href = "/cutview", // Redirects to the home page after the toast message
          autoClose: 1000, // Toast notification will close in 1 second
        });
      } else {
        toast.error('Update failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
        }}

      >
        <CssBaseline />
        <ToastContainer position="top-center" />

          <Toolbar />
          <ContentWrapper>
            <h1></h1>

            {/* Num of blocks */}
            <Box margin="1em">
              <TextField
                type="number"
                id="numOfBlocks"
                value={localNumOfBlocks}
                onChange={handleChangeNumOfBlocks} 
                onBlur={handleBlurNumOfBlocks} 
                label="Number of blocks"
                variant="outlined"
                fullWidth
                inputProps={{ min: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                inputRef={numOfBlocksRef}
              />
            </Box>

            {/* Strain */}
            <Box margin="1em">
              <FormControl component="fieldset">
                <FormLabel component="legend">Strain</FormLabel>
                <RadioGroup
                  aria-label="strain"
                  value={strain}
                  onChange={handleChangeStrain}
                  >
                  {Object.entries(strainMappings).map(([id, name]) => (
                    <FormControlLabel key={id} value={id} control={<Radio />} label={name} />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>

            {/* Inoculation date */}
            <Box margin="1em">
                <FormControl variant="outlined" fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker label="Inoculation date" 
                      value={inoculationDate}
                      closeOnSelect={true}
                      onChange={handleInoculationDateChange}
                      textField={<TextField />}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                </FormControl>
            </Box>

            {/* Cut date */}
            <Box margin="1em">
              <FormControl variant="outlined" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker 
                        label="Date cut" 
                        value={cutDate} 
                        closeOnSelect={true}
                        onChange={handleCutDateChange}>
                        <TextField />
                      </DatePicker>
                  </LocalizationProvider>
              </FormControl>
            </Box>

            {/* Submit button */}
            <Box margin="1em">
                <Button variant="contained" 
                onClick={handleSubmit}
                >Submit</Button>
            </Box>
        </ContentWrapper>

      </Box>
    </ThemeProvider>
  );
}

export default CutEdit;