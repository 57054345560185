import React, { useState, useEffect }  from 'react';
import { styled } from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import SDWGroup from '../common/SDWGroup'
import _ from 'lodash';
import dayjs from 'dayjs'; 
import { toast } from 'react-toastify';
import { BASE_URL } from '../../Constants';
import { getToken, getFarmId } from '../../auth/auth.js';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';  
import { FormHelperText } from '@mui/material';
import Typography from '@mui/material/Typography';  
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import pica from 'pica';

const ContentWrapper = styled('div')({
    flex: '1', // To ensure content takes up remaining space
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
});

function SWDInput() {

  const token = getToken(); 
  const farmId = getFarmId();
  const navigate = useNavigate();
  
  const [sdwDate, setSDWDate] = React.useState(dayjs());
  const [strainMappings, setStrainMappings] = React.useState({});
  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState('');
  const [sdwGroupRefs, setSDWGroupRefs] = useState([React.createRef()]);
  const [firstStrain, setFirstStrain] = useState(''); 
  const [isFarmValid, setIsFarmValid] = useState(true);

  const [openIndex, setOpenIndex] = useState(0);

  const [sdwGroups, setSDWGroups] = useState([{ 
    description: '',
    sdwType: 'Samples',
    sdwTypeTwo: 'blocks',
    sdwImage: '',
    numOfBlocks: 1, 
    strain: firstStrain, 
    weight: null,
    reason:'Trich Contam',
  }]);

  const [weightErrors, setWeightErrors] = useState(sdwGroups.map(() => false));

  //for mappings
  React.useEffect(() => {
    const fetchMappings = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/mappings`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
  
        const sourceMappings = {};
        data.source.forEach((record) => sourceMappings[record.source_name] = record.source_id);
  
        const strainMappings = {};
        data.strain.forEach((record) => strainMappings[record.strain_name] = record.strain_id);
        setStrainMappings(strainMappings);

        // Update the first strain value
        if (data.strain.length > 0) {
          setFirstStrain(data.strain[0].strain_name);
        }
      } catch (error) {
        console.error("An error occurred while fetching the mappings:", error);
      }
    };
    fetchMappings();
  }, []); 

  const fetchFarms = () => { 
    return fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setFarms(jsonData);

          // Find the farm that matches the user's farmId
          const userFarm = jsonData.find(farm => farm.farm_id === farmId);

          // If found, set that as the selectedFarm
          if (userFarm) {
            setSelectedFarm(userFarm.farm_id);
          }
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    if (firstStrain) {  // Make sure firstStrain is not null or undefined
      setSDWGroups(prevYieldGroups => {
        const updatedYieldGroups = [...prevYieldGroups];
        updatedYieldGroups[0].strain = firstStrain;
        return updatedYieldGroups;
      });
    }
  }, [firstStrain]); 

  useEffect(() => {
    const fetchData = async () => {
      await fetchFarms();
    };
    fetchData();
  }, []);

  const addSDWGroup = () => { 
    setSDWGroups(yieldGroups => {
      const newYieldGroups = [...yieldGroups, {
        numOfBlocks: 1, 
        strain: firstStrain, 
        weight: null,
        sdwType: 'Samples',
        reason:'Trich Contam',
        sdwTypeTwo: 'blocks',
      }];
      setSDWGroupRefs(yieldGroupRefs => [...yieldGroupRefs, React.createRef()]);
      return newYieldGroups;
    });
    setOpenIndex(sdwGroups.length);
  }  

  function removeSDWGroup(index) {
    setSDWGroups(sdwGroups.filter((_, i) => i !== index));
  } 

  const handleSDWGroupChange = (index, newSDWGroupState) => {
    setSDWGroups(prevSDWGroups => {
        const newSDWGroups = [...prevSDWGroups]; // Create a copy of the yieldGroups state
        newSDWGroups[index] = { ...newSDWGroups[index], ...newSDWGroupState }; // Replace the state at the given index with the new state
        return newSDWGroups; // Set the new state
    });
  }
  
  const handleSDWDateChange = (date) => {
    setSDWDate(date);
  };
  
  const handleSubmit = async () => {
    const farm_id = selectedFarm;  

    if (!selectedFarm) {
      setIsFarmValid(false);
      return;  // Exit the function if no farm is selected
    }
    setIsFarmValid(true);

    // Check for weight errors
    let hasWeightErrors = false;
    sdwGroups.forEach((group, index) => {
      if (group.sdwTypeTwo === 'fresh' && (!group.weight || parseFloat(group.weight) <= 0)) {
        setWeightErrors(prev => {
          const newErrors = [...prev];
          newErrors[index] = true;
          return newErrors;
        });
        hasWeightErrors = true;
      }
    });
  
    if (hasWeightErrors) {
      toast.error('Please enter valid weights for all fresh waste entries.', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;  // Exit the function if there are weight errors
    }

    // Create a temporary storage for resized images as Blobs
    const resizedImages = await Promise.all(sdwGroups.map(async (group, index) => {
      if (group.sdwImage && group.sdwImage instanceof File) {
        const blob = await resizeImage(group.sdwImage);  // Assuming resizeImage returns a Blob
        return { index, blob };
      }
      return { index, blob: group.sdwImage };  // Return original if no resizing needed or not a file
    }));
  
    const formData = new FormData();
    
    sdwGroups.forEach((group, index) => {

      console.log(index, group.sdwType, group.sdwTypeTwo, group.weight); // debug

      const groupData = {
        strain: strainMappings[group.strain],  
        farm: farm_id,
        numOfBlocks: group.numOfBlocks,
        weight: group.weight,
        sdwDate: sdwDate.format('YYYY-MM-DD'),
        sdwType: group.sdwType,
        targetTable: group.sdwTypeTwo === 'blocks' ? 'blocksWastedTable' : group.sdwTypeTwo === 'fresh' ? 'blocksSDWTable' : null,
        description: group.description,
        reason: group.reason,
      };
  
      // Append the group data as a stringified JSON under a specific key
      formData.append(`data[${index}]`, JSON.stringify(groupData));
      const resizedImage = resizedImages.find(img => img.index === index).blob;
      if (resizedImage) {
        formData.append(`image[${index}]`, resizedImage, `group${index}Image.jpg`);
      }
    });
  
    try {
      const response = await fetch(`${BASE_URL}/api/sdwinput`, { 
        method: 'POST',
        body: formData, 
        headers: {
          'Authorization': `Bearer ${token}`, // Do not set 'Content-Type' when using FormData
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.success) {
        navigate('/sdwview');
        toast.success('Success!', {
          autoClose: 2000,  
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error("An error occurred while submitting the data:", error);
    }
  };

  const resizeImage = async (file) => {
    const reader = new FileReader();
  
    return new Promise((resolve, reject) => {
      reader.onload = async (event) => {
        const img = new Image();
        img.onload = async () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          // Set desired dimensions
          const maxWidth = 800; // Adjust according to your requirements
          const maxHeight = 600; // Adjust according to your requirements
          const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
          const width = img.width * ratio;
          const height = img.height * ratio;
  
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
  
          const resizer = pica();
          const resizedCanvas = await resizer.resize(img, canvas);
          const blob = await resizer.toBlob(resizedCanvas, 'image/jpeg', 0.90);
  
          console.log(`Original size: ${Math.round(file.size / 1024)} KB`);
          blob.arrayBuffer().then(buffer => {
            console.log(`Resized size: ${Math.round(buffer.byteLength / 1024)} KB`);
          });
  
          resolve(blob);
        };
        img.src = event.target.result;
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  };

  return (
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
        }}
      
      >
        <CssBaseline />

          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <ContentWrapper>

                {/* DATE & FARM */}
                <Grid container spacing={1} justifyContent="center">

                    {/* DATE */}
                    <Grid item xs={4} sx={{mb:4, ml: 10}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker 
                            label="Date" 
                            value={sdwDate} 
                            closeOnSelect={true}
                            onChange={handleSDWDateChange}>
                            <TextField />
                            </DatePicker>
                        </LocalizationProvider>
                    </Grid>

                    {/* FARM SELECT */}
                    <Grid item xs={5} sx={{mb:4}}>
                        <FormControl fullWidth sx={{ width: '70%' }}>
                            <InputLabel>Farm</InputLabel>
                            <Select
                              value={selectedFarm}
                              onChange={(e) => {
                                setSelectedFarm(e.target.value);
                                setIsFarmValid(true);  // Reset the validation when a new selection is made
                              }}
                              error={!isFarmValid}
                            >
                            {farms.map((farm, index) => (
                                <MenuItem key={index} value={farm.farm_id}>  
                                {farm.farm_name}  
                                </MenuItem>
                            ))}
                            </Select>
                            {!isFarmValid && (
                              <FormHelperText error>
                                Please select a farm 
                              </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>

                </Grid>

                {/* ACCORDION */}
                {sdwGroups.map((sdwGroup, index) =>
                  <Box
                    key={index}
                    ref={sdwGroupRefs[index]} 
                    display="flex"
                    alignItems="center"
                    marginBottom="1em"
                  >
                    <SDWGroup 
                      index={index} 
                      firstStrain={firstStrain}
                      state={sdwGroup} 
                      isOpen={index === openIndex}
                      onToggle={() => setOpenIndex(index === openIndex ? -1 : index)}
                      onChange={(index, newSDWGroupState) => handleSDWGroupChange(index, newSDWGroupState)} 
                      highlight={index === sdwGroups.length - 1} 
                      weightError={weightErrors[index]}
                      setWeightError={(error) => {
                        setWeightErrors(prev => {
                          const newErrors = [...prev];
                          newErrors[index] = error;
                          return newErrors;
                        });
                      }}
                    />
                
                    {/* Remove button */}
                    {sdwGroups.length > 1 && (
                      <Button 
                        onClick={() => removeSDWGroup(index)} 
                        variant="outlined"
                        sx={{
                        minWidth: "initial", 
                        width: 40, 
                        height: 40, 
                        borderRadius: "50%", // this is what makes it a circle
                        padding: 0, 
                        display: "flex", 
                        justifyContent: "center",
                        alignItems: "center",
                        }}
                      >
                        <Typography variant="h5">-</Typography>
                      </Button>
                    )}
                  </Box>
                )}

                {/* Add button */}
                <Button onClick={addSDWGroup} variant="outlined">+Add</Button> 

                {/* Submit button */}
                <Box margin="1em">
                  <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                </Box>

            </ContentWrapper>
          </Container>

      </Box>
  );
}

export default SWDInput;