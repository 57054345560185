import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { format, parseISO } from 'date-fns';
import Divider from '@mui/material/Divider';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { keyframes } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { getToken } from '../../auth/auth.js';
import { BASE_URL } from '../../Constants';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PushPinIcon from '@mui/icons-material/PushPin';
import ArchiveIcon from '@mui/icons-material/Archive';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import Badge from '@mui/material/Badge';


// Define animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const slideIndicator = keyframes`
  from { transform: scaleX(0.7); opacity: 0.8; }
  to { transform: scaleX(1); opacity: 1; }
`;

const StyledBadge = ({ count, children }) => (
  <Badge
    badgeContent={count}
    color="primary"
    sx={{
      '& .MuiBadge-badge': {
        right: -3,
        top: 3,
        border: (theme) => `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        height: '18px',
        minWidth: '18px',
        fontSize: '0.75rem',
      },
    }}
  >
    {children}
  </Badge>
);

function ChatHistory({ threads, onThreadSelect, currentThreadId }) {
  const [editingThreadId, setEditingThreadId] = useState(null);
  const [editTitle, setEditTitle] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedThread, setSelectedThread] = useState(null);
  const token = getToken();
  const [currentTab, setCurrentTab] = useState('active');
  const [archivedThreads, setArchivedThreads] = useState([]);

  useEffect(() => {
    const fetchArchivedThreads = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/archivedthreads`, {
          headers: {
            'Authorization': `Bearer ${getToken()}`
          }
        });
        
        if (!response.ok) throw new Error('Failed to fetch archived threads');
        
        const data = await response.json();
        setArchivedThreads(data.threads);
      } catch (error) {
        console.error('Error fetching archived threads:', error);
        toast.error('Failed to load archived chats');
      }
    };

    // Fetch archived threads on mount and when switching to archived tab
    fetchArchivedThreads();
  }, []); // Remove currentTab dependency to fetch on mount

  const handleEditClick = (thread) => {
    setEditingThreadId(thread.threadId);
    setEditTitle(thread.title);
  };

  const handleCancelEdit = () => {
    setEditingThreadId(null);
    setEditTitle('');
  };

  const handleSaveTitle = async (threadId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/updatethreadtitle/${threadId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ title: editTitle })
      });

      const data = await response.json();
      
      if (data.success) {
        // Update the local state through the parent component
        const updatedThreads = threads.map(thread => 
          thread.threadId === threadId ? { ...thread, title: editTitle } : thread
        );
        onThreadSelect(threadId, updatedThreads);
        setEditingThreadId(null);
        toast.success('Title updated successfully');
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error updating title:', error);
      toast.error('Failed to update title');
    }
  };

  const formatDate = (timestamp) => {
    const date = typeof timestamp === 'number' 
      ? new Date(timestamp * 1000)
      : parseISO(timestamp);
    return format(date, 'MMM d, yyyy h:mm a');
  };

  const handleMenuClick = (event, thread) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedThread(thread);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedThread(null);
  };

  const handlePinConversation = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/pinthread/${selectedThread.threadId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Failed to pin/unpin thread');

      // Toggle the pinned status locally
      const updatedThreads = threads.map(t => 
        t.threadId === selectedThread.threadId 
          ? { ...t, isPinned: !t.isPinned }
          : t
      ).sort((a, b) => {
        // Sort pinned threads first, then by date
        if (a.isPinned && !b.isPinned) return -1;
        if (!a.isPinned && b.isPinned) return 1;
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      onThreadSelect(currentThreadId, updatedThreads);
      handleMenuClose();
    } catch (error) {
      console.error('Error pinning/unpinning thread:', error);
    }
  };

  const handleArchive = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/archivethread/${selectedThread.threadId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to archive thread');
      }

      // Remove the archived thread from the local state
      const updatedThreads = threads.filter(t => t.threadId !== selectedThread.threadId);

      // Update archived threads count by adding the newly archived thread
      setArchivedThreads(prev => [...prev, selectedThread]);

      // If the archived thread was the current thread and there are other threads available,
      // select the first thread from the updated list
      if (selectedThread.threadId === currentThreadId && updatedThreads.length > 0) {
        onThreadSelect(updatedThreads[0].threadId, updatedThreads);
      } else {
        // If it wasn't the current thread or there are no other threads,
        // just update the threads list
        onThreadSelect(currentThreadId, updatedThreads);
      }

      handleMenuClose();
    } catch (error) {
      console.error('Error archiving thread:', error);
    }
  };

  const handleRestore = async (event, thread) => {
    event.stopPropagation();
    try {
      const response = await fetch(`${BASE_URL}/api/restorethread/${thread.threadId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Failed to restore thread');

      // Remove from archived threads
      setArchivedThreads(prev => prev.filter(t => t.threadId !== thread.threadId));
      
      // Fetch all threads again to ensure proper sorting
      const threadsResponse = await fetch(`${BASE_URL}/api/openai/threads`, {
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      });
      
      if (!threadsResponse.ok) throw new Error('Failed to fetch updated threads');
      
      const data = await threadsResponse.json();
      const sortedThreads = data.threads
        .map(t => ({
          threadId: t.chatbot_thread_id,
          createdAt: t.created_at,
          lastMessage: t.last_message || 'New Chat',
          title: t.title || 'New Chat',
          isPinned: t.is_pinned || false
        }))
        .sort((a, b) => {
          if (a.isPinned && !b.isPinned) return -1;
          if (!a.isPinned && b.isPinned) return 1;
          const dateA = typeof a.createdAt === 'string' ? Date.parse(a.createdAt) : a.createdAt * 1000;
          const dateB = typeof b.createdAt === 'string' ? Date.parse(b.createdAt) : b.createdAt * 1000;
          return dateB - dateA;
        });

      onThreadSelect(thread.threadId, sortedThreads);
      setCurrentTab('active');
    } catch (error) {
      console.error('Error restoring thread:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const menuItems = currentTab === 'active' ? (
    <>
      <MenuItem onClick={handlePinConversation}>
        <PushPinIcon sx={{ 
          mr: 1.5, 
          fontSize: 18,
          transform: selectedThread?.isPinned ? 'rotate(45deg)' : 'none'
        }} />
        <Typography variant="body2">
          {selectedThread?.isPinned ? 'Unpin' : 'Pin'}
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => {
        handleEditClick(selectedThread);
        handleMenuClose();
      }}>
        <EditIcon sx={{ mr: 1.5, fontSize: 18 }} />
        <Typography variant="body2">Edit Name</Typography>
      </MenuItem>
      <MenuItem onClick={handleArchive}>
        <ArchiveIcon sx={{ mr: 1.5, fontSize: 18 }} />
        <Typography variant="body2">Archive</Typography>
      </MenuItem>
    </>
  ) : null;

  return (
    <Paper 
      elevation={6}
      sx={{ 
        width: '300px',
        height: '85vh',
        marginRight: '20px',
        marginTop: '60px',
        overflow: 'hidden',
        backgroundColor: (theme) => 
          theme.palette.mode === 'light' 
            ? 'rgba(255, 255, 255, 0.9)' 
            : 'rgba(45, 52, 54, 0.9)',
        borderRadius: '16px',
        backdropFilter: 'blur(10px)',
        border: '1px solid',
        borderColor: (theme) => 
          theme.palette.mode === 'light' 
            ? 'rgba(255, 255, 255, 0.3)' 
            : 'rgba(255, 255, 255, 0.1)',
        position: 'relative',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          padding: '20px',
          borderBottom: '2px solid',
          borderColor: (theme) => 
            theme.palette.mode === 'light' 
              ? 'rgba(0, 0, 0, 0.1)' 
              : 'rgba(255, 255, 255, 0.1)',
          fontWeight: 700,
          color: (theme) => theme.palette.mode === 'light' ? '#2d3436' : '#f8f9fa',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? 'rgba(255, 255, 255, 0.9)'
              : 'rgba(45, 52, 54, 0.9)',
          backdropFilter: 'blur(10px)',
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }}
      >
        <ChatBubbleOutlineIcon 
          sx={{ 
            animation: `${pulse} 2s infinite ease-in-out`,
            color: (theme) => theme.palette.primary.main,
          }} 
        />
        Chat History
      </Typography>

      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        variant="fullWidth"
        sx={{
          borderBottom: '1px solid',
          borderColor: (theme) => 
            theme.palette.mode === 'light' 
              ? 'rgba(0, 0, 0, 0.06)' 
              : 'rgba(255, 255, 255, 0.06)',
          '& .MuiTab-root': {
            minHeight: '56px',
            textTransform: 'none',
            fontSize: '0.95rem',
            fontWeight: 500,
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? 'rgba(25, 118, 210, 0.04)'
                  : 'rgba(144, 202, 249, 0.04)',
            },
            '&.Mui-selected': {
              fontWeight: 600,
            },
          },
          '& .MuiTabs-indicator': {
            height: '3px',
            borderRadius: '2px',
            animation: `${slideIndicator} 0.3s ease-out`,
            background: (theme) => 
              `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          },
        }}
      >
        <Tab 
          icon={
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              color: (theme) => 
                currentTab === 'active' 
                  ? theme.palette.primary.main 
                  : theme.palette.text.secondary,
            }}>
              <ChatBubbleOutlineOutlinedIcon 
                sx={{ 
                  fontSize: 20,
                  transition: 'transform 0.2s ease',
                  transform: currentTab === 'active' ? 'scale(1.1)' : 'scale(1)',
                }} 
              />
              <Typography 
                variant="body2"
                sx={{ 
                  fontWeight: currentTab === 'active' ? 600 : 400,
                }}
              >
                Active Chats
              </Typography>
            </Box>
          }
          value="active"
          sx={{ 
            opacity: currentTab === 'active' ? 1 : 0.7,
          }}
        />
        <Tab 
          icon={
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              color: (theme) => 
                currentTab === 'archived' 
                  ? theme.palette.primary.main 
                  : theme.palette.text.secondary,
            }}>
              <StyledBadge count={archivedThreads.length}>
                <ArchiveOutlinedIcon 
                  sx={{ 
                    fontSize: 20,
                    transition: 'transform 0.2s ease',
                    transform: currentTab === 'archived' ? 'scale(1.1)' : 'scale(1)',
                  }} 
                />
              </StyledBadge>
              <Typography 
                variant="body2"
                sx={{ 
                  fontWeight: currentTab === 'archived' ? 600 : 400,
                }}
              >
                Archived
              </Typography>
            </Box>
          }
          value="archived"
          sx={{ 
            opacity: currentTab === 'archived' ? 1 : 0.7,
          }}
        />
      </Tabs>

      <Box
        sx={{
          height: '2px',
          background: (theme) =>
            theme.palette.mode === 'light'
              ? 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.03) 50%, rgba(255,255,255,0) 100%)'
              : 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.03) 50%, rgba(0,0,0,0) 100%)',
          marginBottom: '8px',
        }}
      />

      <List 
        sx={{ 
          padding: '8px',
          height: 'calc(100% - 180px)',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: (theme) => 
              theme.palette.mode === 'light' 
                ? 'rgba(0, 0, 0, 0.2)' 
                : 'rgba(255, 255, 255, 0.2)',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: (theme) => 
              theme.palette.mode === 'light' 
                ? 'rgba(0, 0, 0, 0.3)' 
                : 'rgba(255, 255, 255, 0.3)',
          },
        }}
      >
        <Box
          sx={{
            paddingBottom: '16px',
            animation: `${fadeIn} 0.2s ease-out`,
          }}
        >
          {(currentTab === 'active' ? threads : archivedThreads).map((thread, index) => (
            <React.Fragment key={thread.threadId}>
              <ListItem 
                button 
                onClick={() => {
                  if (!editingThreadId && currentTab === 'active') {
                    onThreadSelect(thread.threadId);
                  }
                }}
                disableRipple={editingThreadId === thread.threadId}
                sx={{
                  borderRadius: '12px',
                  mb: 0.5,
                  animation: `${fadeIn} 0.2s ease-out`,
                  animationFillMode: 'backwards',
                  animationDelay: `${index * 0.05}s`,
                  backgroundColor: currentThreadId === thread.threadId 
                    ? (theme) => theme.palette.mode === 'light' 
                      ? 'rgba(25, 118, 210, 0.1)' 
                      : 'rgba(144, 202, 249, 0.1)'
                    : 'transparent',
                  '&:hover': {
                    backgroundColor: (theme) => 
                      editingThreadId === thread.threadId
                        ? 'transparent'
                        : theme.palette.mode === 'light' 
                          ? 'rgba(25, 118, 210, 0.05)' 
                          : 'rgba(144, 202, 249, 0.05)',
                  },
                  transition: 'background-color 0.15s ease',
                  position: 'relative',
                  overflow: 'hidden',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '4px',
                    height: '100%',
                    backgroundColor: 'primary.main',
                    opacity: currentThreadId === thread.threadId ? 1 : 0,
                    transition: 'opacity 0.15s ease',
                  },
                }}
              >
                {editingThreadId === thread.threadId ? (
                  // Edit mode
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <TextField
                      value={editTitle}
                      onChange={(e) => setEditTitle(e.target.value)}
                      size="small"
                      fullWidth
                      autoFocus
                      onClick={(e) => e.stopPropagation()}
                    />
                    <IconButton 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSaveTitle(thread.threadId);
                      }}
                      size="small"
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCancelEdit();
                      }}
                      size="small"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ) : (
                  // Display mode
                  <>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {thread.isPinned && (
                            <PushPinIcon sx={{ 
                              fontSize: 16,
                              color: 'primary.main',
                              transform: 'rotate(-45deg)'
                            }} />
                          )}
                          <Typography
                            sx={{
                              fontWeight: currentThreadId === thread.threadId ? 600 : 400,
                              color: (theme) => theme.palette.mode === 'light' ? '#2d3436' : '#f8f9fa',
                              fontSize: '0.95rem',
                            }}
                          >
                            {thread.title || 'New Chat'}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Typography
                          variant="caption"
                          sx={{
                            color: (theme) => theme.palette.mode === 'light' ? '#636e72' : '#b2bec3',
                            fontSize: '0.75rem',
                          }}
                        >
                          {formatDate(thread.createdAt)}
                        </Typography>
                      }
                      sx={{
                        ml: 1,
                        wordWrap: 'break-word',
                        '& .MuiTypography-root': {
                          whiteSpace: 'normal'
                        }
                      }}
                    />
                    {currentTab === 'active' ? (
                      <IconButton 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMenuClick(e, thread);
                        }}
                        size="small"
                      >
                        <MoreVertIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={(e) => handleRestore(e, thread)}
                        size="small"
                        sx={{
                          transition: 'transform 0.2s ease',
                          '&:hover': {
                            transform: 'scale(1.1)',
                            color: 'primary.main'
                          }
                        }}
                      >
                        <UnarchiveIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    )}
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      onClick={(e) => e.stopPropagation()}
                      onMouseDown={(e) => e.stopPropagation()}
                      slotProps={{
                        paper: {
                          sx: {
                            minWidth: '180px',
                            borderRadius: '8px',
                            mt: 0.5,
                            boxShadow: (theme) => 
                              theme.palette.mode === 'light'
                                ? '0 1px 4px rgba(0,0,0,0.02)'
                                : '0 1px 4px rgba(0,0,0,0.02)',
                            backgroundColor: (theme) => 
                              theme.palette.mode === 'light'
                                ? '#ffffff'
                                : '#2d3436',
                          },
                          onClick: (e) => e.stopPropagation(),
                        }
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      {menuItems}
                    </Menu>
                  </>
                )}
              </ListItem>
              {index < threads.length - 1 && (
                <Divider 
                  variant="middle" 
                  sx={{ 
                    my: 0.5,
                    opacity: 0.5,
                  }} 
                />
              )}
            </React.Fragment>
          ))}
        </Box>
      </List>
    </Paper>
  );
}

export default React.memo(ChatHistory); 