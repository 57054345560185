import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Button, Paper, FormControl, FormLabel, Select, MenuItem, Grid, Typography, Table, TableBody, TableHead, TableRow, TableCell, TableContainer, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { BASE_URL } from '../../Constants';
import { getToken, getFarmId } from '../../auth/auth.js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import sortData from '../common/sortColumns';
import InputLabel from '@mui/material/InputLabel';

function OrderList() {

    const [orderData, setOrderData] = useState([]); 
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [modalOrderDetails, setModalOrderDetails] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [farms, setFarms] = useState([]);
    const [strains, setStrains] = React.useState([]); 
    const [selectedFarm, setSelectedFarm] = useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);
    const [selectedFilterFarm, setSelectedfilterFarm] = React.useState('');

    const filteredData = selectedFilterFarm
    ? orderData.filter(record => record.farm_name === selectedFilterFarm)
    : orderData;
  
    const farmNames = [...new Set(orderData.map(record => record.farm_name))];
  
    const sortedOrderData = sortData(filteredData);
  

    const token = getToken(); 
    const navigate = useNavigate();
    const farmId = getFarmId();

    const fetchOrderData = () => { 
      fetch(`${BASE_URL}/api/readfarmorders`, { 
        method: 'GET',  
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setOrderData(jsonData);
          console.log(jsonData); // debug
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }

    const fetchModalData = () => { 
        fetch(`${BASE_URL}/api/readmodalorderdetails`, { 
          method: 'GET',  
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.text(); 
        })
        .then(data => {
          try {
            const jsonData = JSON.parse(data); 
            setModalData(jsonData);
            console.log(jsonData);
          } catch(e) {
            console.error("The response is not JSON. Data:", data);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    const fetchFarms = () => { 
        return fetch(`${BASE_URL}/api/readfarms`, { 
            method: 'GET', 
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.text(); 
          })
          .then(data => {
            try {
              const jsonData = JSON.parse(data); 
              setFarms(jsonData);
    
              // Find the farm that matches the user's farmId
              const userFarm = jsonData.find(farm => farm.farm_id === farmId);
    
              // If found, set that as the selectedFarm
              if (userFarm) {
                setSelectedFarm(userFarm.farm_id);
              }
            } catch(e) {
              console.error("The response is not JSON. Data:", data);
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
    }

    const fetchStrains = () => {
        fetch(`${BASE_URL}/api/readstrains`, { 
            method: 'GET', 
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.text(); 
          })
          .then(data => {
            try {
              const jsonData = JSON.parse(data); 
              setStrains(jsonData);
            } catch(e) {
              console.error("The response is not JSON. Data:", data);
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
    }
    
    useEffect(() => {
    const fetchData = async () => {
        await fetchFarms();
    };
        fetchData();
    }, []);

    useEffect(() => {
        fetchOrderData(); 
        fetchModalData();   
        fetchStrains();
    }, []);  

    // format date bc of JavaScript time zone conversions
    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${monthNames[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`;
    };

    const handleClickOpen = (row) => {
        console.log("Clicked row data: ", row); 
        const filteredData = modalData.filter(item => item.order_id === row.order_id);

        // Log the filtered data to see if farm_id is included
        console.log("Filtered data: ", filteredData);

        setModalOrderDetails(filteredData);
        setOpen(true);

        // If filteredData contains any records, take farm_id from the first record
        if (filteredData.length > 0) {
            const farmId = filteredData[0].farm_id;
            setSelectedRow({
            ...row,
            farm_id: farmId
            });
        } else {
            setSelectedRow({
            ...row
            });
        }
        // Store filteredData in a variable to be used in the Ship button onClick
        row.modalOrderDetails = filteredData;
    };

    const handleClose = () => {
        setOpen(false);
        setIsEditMode(false);
    };  

    const deleteOrder = (id) => { 
        setSelectedId(id);
    
        const rowToBeDeleted = orderData.find(order => order.order_id === id);
        if (rowToBeDeleted) {
            const filteredData = modalData.filter(item => item.order_id === id);
    
            setModalOrderDetails(filteredData);
    
            // If filteredData contains any records, take farm_id from the first record
            if (filteredData.length > 0) {
                const farmId = filteredData[0].farm_id;
                setSelectedRow({
                    ...rowToBeDeleted,
                    farm_id: farmId
                });
            } else {
                setSelectedRow({
                    ...rowToBeDeleted
                });
            }
        }
        setOpenDialog(true); 
    }
    
    const handleConfirmDelete = () => {
        setOpenDialog(false);
    
        console.log(selectedId);
      
        fetch(`${BASE_URL}/api/deleteorder/${selectedId}`, { 
          method: 'PUT', 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          } else {
            toast.success('Order successfully deleted', {
              autoClose: 1000, // Toast notification will close in 1 second
            });
            // Refresh the data
            return fetchOrderData(); 
          }
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error('An error occurred while trying to delete the order');
        });
    }
      
    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    
    const handleSelectedRowChange = (e, fieldName) => {
        if (fieldName === 'farm_id') {
            const selectedFarmId = e.target.value;
            const farmObj = farms.find(farm => farm.farm_id == selectedFarmId); // using double equals because `e.target.value` returns a string
            setSelectedRow(prevState => ({
                ...prevState,
                farm_id: selectedFarmId,
                farm_name: farmObj.farm_name
            }));
        } else {
            setSelectedRow(prevState => ({
                ...prevState,
                [fieldName]: e.target.value
            }));
        }
    };

    const handleEditChange = (e, index, fieldName) => {
        let newModalOrderDetails = [...modalOrderDetails]; // create shallow copy of the modalOrderDetails array using the spread operator
        newModalOrderDetails[index][fieldName] = fieldName === 'num_of_blocks' ? parseInt(e.target.value, 10) : e.target.value;
        setModalOrderDetails(newModalOrderDetails);
    };

    const handleAddStrain = () => {
        const orderId = modalOrderDetails.length > 0 ? modalOrderDetails[0].order_id : null; 
        const farmId = modalOrderDetails.length > 0 ? modalOrderDetails[0].farm_id : null; 
        
        const newStrain = {
            order_id: orderId,
            farm_id: farmId,
            strain_name: '', 
            num_of_blocks: 0,
            isNew: true
        };
        
        setModalOrderDetails([...modalOrderDetails, newStrain]);
    };

    const handleRemoveStrain = (orderLineIdToRemove) => {
        const updatedModalOrderDetails = modalOrderDetails.map(item => {
            if (item.order_line_id === orderLineIdToRemove) {
                return { ...item, isDeleted: true };
            }
            return item;
        });
    
        setModalOrderDetails(updatedModalOrderDetails);
    };
    
    const handleSubmitEdits = async () => {
        console.log('Farm ID sent:', selectedRow.farm_id);

        // Map strain_name to strain_id
        const updatedModalOrderDetails = modalOrderDetails.map(detail => {
            const strain = strains.find(s => s.strain_name === detail.strain_name);
            return {
                ...detail,
                strain_id: strain ? strain.strain_id : null, // Fallback to null if not found
            };
        });

        const payload = {
            shipDate: selectedRow.ship_date,
            farmId: selectedRow.farm_id,
            modalOrderDetails: updatedModalOrderDetails
          };

        console.log("modalOrderDetails after mapping strains:", updatedModalOrderDetails);
        
        const response = await fetch(`${BASE_URL}/api/updateblockorder/${selectedRow.order_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        });
        const result = await response.json();
        console.log(result.message);
        setIsEditMode(false);
        
        // Refresh the data
        fetchOrderData();
        fetchModalData();
    };

    const handleShip = (row) => {
        const modalDetails = modalData.filter(item => item.order_id === row.order_id);
        navigate('/addshipment', { 
            state: { 
                orderDetails: row, 
                modalOrderDetails: modalDetails, // Assuming this is the data you want to pass
            } 
        });
    };

    const handleFarmChange = (event) => {
        setSelectedfilterFarm(event.target.value);
    };
     
    return (
        <Box
            sx={{
                backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'auto',
            }}
          
        >
            <CssBaseline />

            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>

                        <Box 
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(2, max-content) 1fr auto', // Three columns: button, title, empty space
                                gap: 1, 
                                alignItems: 'center',
                                marginBottom: 2, 
                            }}
                        >

                            {/* Title */}
                            <Box 
                                sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', // Centers the title
                                gridColumnStart: 3, // Title starts at the third column
                                gridColumnEnd: 3, // Title ends at the third column
                                }}
                            >
                                <Typography variant="h6" component="h2" gutterBottom>
                                    All block orders
                                </Typography>
                            </Box>

                            {/* Farm filter */}
                            <FormControl fullWidth sx={{ gridColumnStart: 4, width: '145px' }}>
                                <InputLabel id="farm-select-label">Filter farm</InputLabel>
                                <Select
                                    labelId="farm-select-label"
                                    id="farm-select"
                                    value={selectedFarm}
                                    label="Farm"
                                    onChange={handleFarmChange}
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>

                                    {farmNames.map(farmName => (
                                        <MenuItem key={farmName} value={farmName}>{farmName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>
                            <Table aria-label="order list table" stickyHeader>

                            <TableHead>
                                <TableRow>
                                <TableCell>Date to ship</TableCell>
                                <TableCell>Farm</TableCell>
                                <TableCell>Total blocks</TableCell>
                                <TableCell>Notes</TableCell>
                                <TableCell>Ship</TableCell>
                                <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {sortedOrderData.map((row, index) => (
                                    <TableRow key={index} onClick={() => handleClickOpen(row)}>

                                        <TableCell>
                                            {formatDate(row.ship_date.split('T')[0])}
                                        </TableCell>

                                        <TableCell>
                                            {row.farm_name}
                                        </TableCell>

                                        <TableCell>    
                                            {row.total_blocks}
                                        </TableCell>

                                        <TableCell>  
                                            {row.order_notes}
                                        </TableCell>

                                        <TableCell>  
                                            <Button 
                                                variant="contained" 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleShip(row, row.modalOrderDetails);
                                                }}
                                            >
                                                Ship
                                            </Button>
                                        </TableCell>

                                        <TableCell>  
                                            <IconButton aria-label="delete" onClick={(e) => { e.stopPropagation(); deleteOrder(row.order_id); }}>
                                                <DeleteIcon />
                                            </IconButton> 
                                        </TableCell>

                                    </TableRow>
                                ))} 
                            </TableBody>

                            </Table>
                        </TableContainer>

                    </Grid>
                </Grid>
            </Container>

            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>
                    {isEditMode ? (
                        <div>
                            <label>Farm: </label>
                            <select 
                                value={selectedRow ? selectedRow.farm_id : ''} 
                                onChange={(e) => handleSelectedRowChange(e, 'farm_id')}
                            >
                                {farms.map((farm, index) => (
                                    <option key={index} value={farm.farm_id}>{farm.farm_name}</option>
                                ))}
                            </select>
                        </div>
                    ) : (
                        selectedRow ? selectedRow.farm_name : ''
                    )}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {isEditMode ? (
                            <div>
                                <label>Ship Date: </label>
                                <input 
                                    type="date" 
                                    value={selectedRow ? new Date(selectedRow.ship_date).toISOString().split('T')[0] : ''} 
                                    onChange={(e) => handleSelectedRowChange(e, 'ship_date')} 
                                />
                            </div>
                        ) : (
                            `Ship Date: ${selectedRow ? formatDate(selectedRow.ship_date) : ''}`
                        )}
                    </DialogContentText>

                    <TableContainer component={Paper}>
                        <Table>  
                            <TableBody>
                                {modalOrderDetails.filter(item => !item.isDeleted).map((item, index) => (
                                    <TableRow key={index}>

                                        {/* Close Icon for removing strain */}
                                        {isEditMode && (
                                            <TableCell>
                                                <IconButton 
                                                    onClick={() => handleRemoveStrain(item.order_line_id)} 
                                                    aria-label="remove strain"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </TableCell>
                                        )}

                                        {/* Strain */}
                                        <TableCell>
                                            {isEditMode ? (
                                                item.isNew ? ( 
                                                    <FormControl fullWidth variant="outlined">
                                                        <FormLabel component="legend">Strain</FormLabel>
                                                        <Select
                                                            value={item.strain_name}
                                                            onChange={(e) => handleEditChange(e, index, 'strain_name')}
                                                        >
                                                            {strains.map((strain) => (
                                                                <MenuItem key={strain.strain_id} value={strain.strain_name}>
                                                                    {strain.strain_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                </FormControl>
                                                ) : (
                                                    item.strain_name
                                                )
                                            ) : (
                                                item.strain_name
                                            )}
                                        </TableCell>
                                        
                                        {/* QTY */}
                                        <TableCell>
                                            {isEditMode ? (
                                                <TextField 
                                                    sx={{ maxWidth: 75 }}
                                                    type="number" 
                                                    value={item.num_of_blocks.toString()} 
                                                    onChange={(e) => handleEditChange(e, index, 'num_of_blocks')} 
                                                />
                                            ) : (
                                                item.num_of_blocks
                                            )}
                                        </TableCell>
                                    
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>  
                    </TableContainer>
                    {isEditMode && (
                        <Box 
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '15px'
                            }}
                        >
                            <Button variant="contained" color="primary" onClick={handleAddStrain}>
                                Add strain
                            </Button>
                        </Box>
                    )}
                </DialogContent>

                <DialogActions>
                    {isEditMode ? (
                        <Button onClick={handleSubmitEdits}>Save</Button>
                    ) : (
                        <Button onClick={() => setIsEditMode(true)}>Edit</Button>
                    )}
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>

            </Dialog>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>{"Are you sure you want to delete this order?"}</DialogTitle>
                
                <DialogContent>
                    <DialogContentText>
                        <div>Farm: {selectedRow ? selectedRow.farm_name : ''}</div>
                        <div>Ship Date: {selectedRow ? new Date(new Date(selectedRow.ship_date).getTime() + (4 * 60 * 60 * 1000)).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : ''}</div>
                    </DialogContentText>
                        <TableContainer component={Paper}>
                            <Table>  
                                <TableBody>
                                    {modalOrderDetails.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.strain_name}</TableCell>
                                            <TableCell>
                                                {isEditMode ? (
                                                    <input type="number" defaultValue={item.num_of_blocks} onChange={(e) => handleEditChange(e, index, 'num_of_blocks')} />
                                                ) : (
                                                    item.num_of_blocks
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>  
                        </TableContainer>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}

export default OrderList;