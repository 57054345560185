import React, { useState, useEffect, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, Box, FormControl, FormLabel, TextField, MenuItem, Select, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';

const mdTheme = createTheme();

const ContentWrapper = styled('div')({});

function InputGroup({ index, firstStrain, state = {numOfBlocks: 1, strain: firstStrain, inoculationDate: dayjs() }, onChange, highlight, isOpen, determineDefaultInoculationDate }) {
    const { numOfBlocks = 1, strain = firstStrain } = state;
    const [localNumOfBlocks, setLocalNumOfBlocks] = useState(state.numOfBlocks);
    const numOfBlocksRef = useRef();
    const [strains, setStrains] = useState([]);
    const [inoculationDate, setInoculationDate] = useState(state.inoculationDate);

    const [isAccordionOpen, setAccordionOpen] = useState(isOpen);
    const [isHighlighted, setIsHighlighted] = useState(false);

    const token = getToken(); 

    useEffect(() => {
        setLocalNumOfBlocks(numOfBlocks); // To sync with parent state on mount and on parent state updates
    }, [numOfBlocks]);

    //strain mappings
    useEffect(() => {
        const fetchStrains = async () => {
          try {
            const response = await fetch(`${BASE_URL}/api/mappings`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            });
      
            const data = await response.json();
            setStrains(data.strain);
          } catch (error) {
            console.error('Failed to fetch strains', error);
          }
        };
      
        fetchStrains();
    }, []);      

    useEffect(() => {
        setInoculationDate(state.inoculationDate);
    }, [state.inoculationDate]);

    useEffect(() => {
        if (highlight) {
            setIsHighlighted(true);
            setTimeout(() => {
                setIsHighlighted(false);
            }, 250);
        }
    }, [highlight]);

    useEffect(() => { 
        setAccordionOpen(isOpen);
    }, [isOpen]);

    // Select the content of the numOfBlocks input when focused
    useEffect(() => {
        if (numOfBlocksRef.current) {
            numOfBlocksRef.current.addEventListener('focus', () => {
                numOfBlocksRef.current.select();
            });
        }
    }, []);

    const handleChangeNumOfBlocks = (event) => {
        setLocalNumOfBlocks(event.target.value);
    };

    const handleBlurNumOfBlocks = () => {
        onChange(index, { numOfBlocks: localNumOfBlocks, strain, inoculationDate });
    };

    const handleChangeStrain = (event) => {
        const newStrain = event.target.value;
        // const newInoculationDate = determineDefaultInoculationDate(newStrain);
        
        // setInoculationDate(newInoculationDate); // update local state
        onChange(index, { numOfBlocks: localNumOfBlocks, strain: newStrain, inoculationDate }); // pass new state back to parent
    };
      
    const handleInoculationDateChange = (date) => {
        if (date) {
            onChange(index, { numOfBlocks: localNumOfBlocks, strain, inoculationDate: date });
        }
    }; 

    return (
        <ThemeProvider theme={mdTheme}> 

            <CssBaseline />

            <ContentWrapper highlighted={highlight}>
                <Accordion expanded={isAccordionOpen} onChange={(onToggle) => setAccordionOpen(!isAccordionOpen)}
                    sx={{ 
                    backgroundColor: isHighlighted ? '#d5ebd0' : 'default color',
                    transition: 'background-color 1s ease-out'
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    > 
                        <Typography>{strain}, blocks: {numOfBlocks} <br/> 
                        Inoc date: {inoculationDate ? inoculationDate.format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY')}</Typography> 
                    </AccordionSummary>

                    <AccordionDetails>
                        
                        {/* Num of blocks */}
                        <Box margin="1em">
                            <TextField
                                type="number"
                                id="numOfBlocks"
                                value={localNumOfBlocks}
                                onChange={handleChangeNumOfBlocks}
                                label="Number of blocks"
                                variant="outlined"
                                fullWidth
                                keyboardtype="numeric" 
                                onBlur={handleBlurNumOfBlocks}
                                inputRef={numOfBlocksRef}
                            />
                        </Box>

                        {/* Strain */}
                        <Box margin="1em">
                            <FormControl fullWidth variant="outlined">
                                <FormLabel component="legend">Strain</FormLabel>
                                <Select
                                    value={strain}
                                    onChange={handleChangeStrain}
                                    defaultValue={firstStrain}
                                >
                                    {strains.map((strainObj, index) => (
                                        <MenuItem key={index} value={strainObj.strain_name}>{strainObj.strain_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        {/* Inoculation date */}
                        <Box margin="1em">
                            <FormControl variant="outlined" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Inoculation date"
                                        value={inoculationDate}
                                        closeOnSelect={true}
                                        onChange={handleInoculationDateChange}
                                    >
                                        <TextField />
                                    </DatePicker>
                                </LocalizationProvider>
                            </FormControl>
                        </Box>
                        
                    </AccordionDetails>
                </Accordion>

            </ContentWrapper>
        </ThemeProvider>
    );
}

export default InputGroup;