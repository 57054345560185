import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { getToken } from '../../auth/auth.js';
import { BASE_URL } from '../../Constants';
import { AimOutlined } from '@ant-design/icons';

function TotalYieldsGrid() { 

  const token = getToken();
  const [averageYield, setAverageYield] = React.useState([]);
  const [expectedYield, setExpectedYield] = React.useState([]);
  const [averageYield30Days, setExpectedYield30Days] = React.useState([]);

  const strainToColor = {
    "Lion's Mane": "#FFA500",
    "Pink Oyster": "#FF6699",
    "Blue Oyster": "#2196F3",
    "Yellow Oyster": "#FFD700",
    "Black Pearl": "#000000",
    "Chestnut": "#8B4513",
    "Shiitake": "#E53935",
    "King Trumpet": "#66C18C",
    "Golden Enoki": "#9C27B0",
  };

  const fetchAverageYields = () => {
    fetch(`${BASE_URL}/api/averageyields`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log('Average Yield Data:', data); // debug
      setAverageYield(data);
    })
    .catch(error => console.error('Error:', error));
  }

  const fetchExpectedYields = () => {
    fetch(`${BASE_URL}/api/expectedyields`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => response.json())
    .then(data => setExpectedYield(data))
    .catch(error => console.error('Error:', error));
  }

  const fetchAverageYields30Days = () => {
    fetch(`${BASE_URL}/api/averageyield30days`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => response.json())
    .then(data => {
      setExpectedYield30Days(data)
      console.log('Average yeild last 30 days from server', data);
    })
    .catch(error => console.error('Error:', error));
  }

  React.useEffect(() => {
    fetchAverageYields();
    fetchExpectedYields();
    fetchAverageYields30Days();
  }, []);

  return (
    <>
      <Typography variant="h6" sx={{ paddingBottom: 1, paddingLeft: 0.6 }}>Average Yields per Block</Typography>

      <Grid container spacing={3}>
        {averageYield.map((item, index) => {
          const currentStrainExpectedYield = expectedYield.find(
            (yieldData) => yieldData.strain_name === item.strain_name
          );

          const currentStrainAverageYield30Days = averageYield30Days.find(
            (yieldData) => yieldData.strain_name === item.strain_name
          );

          const chipColor = strainToColor[item.strain_name] || "#2196F3"; // Fallback to "default" if the strain name is not in the mapping

          return (
            <Grid item xs={4} key={index}>
              <Paper elevation={0}
                sx={{
                  padding: 3,
                  height: '135px',
                  position: 'relative',
                  borderRadius: '10px',
                  border: '1px solid #d9d9d9',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    height: '100%',
                  }}
                >
                  <Typography color="textSecondary" sx={{ mt:0, fontSize: '0.85rem' }}>{item.strain_name}</Typography>

                  <Grid container alignItems="center" spacing={1} >
                    <Grid item>
                      <Typography
                        variant="h9"
                        style={{ fontFamily: 'Inter', fontWeight: 'bold' }}
                      >
                        {parseFloat(item.average_yield_per_block).toFixed(1)} lbs
                      </Typography>
                    </Grid>

                    <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Chip
                        label={`${currentStrainExpectedYield ? currentStrainExpectedYield.expected_yield.toFixed(1) : 'N/A'} lbs`}
                        style={{ backgroundColor: chipColor }}
                        sx={{ ml: 1, color: 'white', borderRadius: '4px' }}
                        size="small"
                        icon={<AimOutlined style={{ fontSize: '1rem', color: 'white' }} />}
                      />
                    </Grid>
                  </Grid>

                  <Box sx={{ pt: 0 }}>
                    <Typography variant="caption" color="textSecondary" sx={{fontSize: '10px', lineHeight: '0.5' }}>
                      Average yield past 30 days:{' '}
                      <Typography component="span" variant="caption" sx={{ color: "#2196F3" }}>
                        {currentStrainAverageYield30Days 
                          ? (currentStrainAverageYield30Days.average_yield_per_block_last_30_days !== null 
                            ? parseFloat(currentStrainAverageYield30Days.average_yield_per_block_last_30_days).toFixed(1) 
                            : '0') 
                          : 'N/A'} 
                      </Typography>{' '}
                      lbs
                    </Typography>
                  </Box>

                </div>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </>
  );

}

export default TotalYieldsGrid;