import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { ToastContainer, toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';
import sortData from '../common/sortColumns';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function ManageFarms() {
  const [farmData, setFarmData] = React.useState([]); 
  const [editingId, setEditingId] = React.useState(null);
  const [originalData, setOriginalData] = React.useState({});
  const [tempData, setTempData] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [newFarmData, setNewFarmData] = React.useState({ farm_name: "", farm_region: "" });
  const [sortField, setSortField] = React.useState('');

  const [sortDirection, setSortDirection] = React.useState({
    farm_name: 'desc',
    farm_code: 'desc',
    farm_region: 'desc',
  }); 

  const sortedFarmData = sortData(farmData, sortField, sortDirection[sortField]);

  const navigate = useNavigate();
  const token = getToken(); 

  const fetchFarms = () => { 
    fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setFarmData(jsonData);
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  React.useEffect(() => {
    fetchFarms(); 
  }, []); // fetch farms

  const editFarm = (id) => {
    setEditingId(id);
  }

  const cancelEditing = () => {
    setEditingId(null);
    setTempData({});
  };

  React.useEffect(() => {
    if (editingId !== null) {
      const rowData = farmData.find(farm => farm.farm_id === editingId);
      setOriginalData(rowData || {});
      setTempData(rowData || {});
    }
  }, [editingId, farmData]);

  const saveChanges = async (id) => {
    // Merge originalData with tempData, tempData values will overwrite originalData
    const dataToSend = {...originalData, ...tempData};
    try {
      const response = await fetch(`${BASE_URL}/api/updatefarm/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend), // the updated data
      });
  
      const result = await response.json();
  
      if (result.message === 'Data updated successfully') {
        // If update was successful
        setEditingId(null);
        setTempData({});
        fetchFarms(); // refresh data
      } else {
        // If the update failed, handle it here
        console.error('Failed to update farm data', result.error);
      }
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  async function handleAddFarm() {
    const { farm_name, farm_region } = newFarmData;
    try {
      let response = await fetch(`${BASE_URL}/api/addfarm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ farm_name, farm_region, addRegion: false })
      });
      
      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(responseData.error);
      }
  
      const data = await response.json();
      console.log(data);
  
    } catch (error) {
      if (error.message.includes('Region does not exist, would you like to add it?')) {
        if (window.confirm('The region does not exist, would you like to add it?')) {
          try {
            let response = await fetch(`${BASE_URL}/api/addfarm`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({ farm_name, farm_region, addRegion: true })
            });
  
            if (!response.ok) {
              const responseData = await response.json();
              throw new Error(responseData.error);
            }
  
            // const data = await response.json();
            toast.success("Farm successfully added", {duration: 1000});
            setIsAdding(false);
            fetchFarms();
          } catch (error) {
            console.error('Error adding farm and region', error);
          }
        }
      } else {
        console.error('Error adding farm', error);
      }
    }
  }

  // Function to navigate to farm detail page
  const goToFarmPage = (farmId) => {
    navigate(`/farm/${farmId}`);
  };

  const handleSort = (field) => {
    // Toggle the sort direction for the specific field
    const newDirection = sortDirection[field] === 'asc' ? 'desc' : 'asc';
  
    // Update the sort directions state
    setSortDirection({
      ...sortDirection,
      [field]: newDirection
    });
  
    // Set the sort field
    setSortField(field);
  };
  
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />
        <ToastContainer position="top-center" />
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>

              <Box sx={{
                display: 'flex',
                justifyContent: 'center', 
              }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  All farms
                </Typography>
              </Box>

              <TableContainer component={Paper} style={{ maxHeight: '70vh', overflow: 'auto' }}>
                <Table aria-label="farm table" stickyHeader>

                  <TableHead>
                    <TableRow>
                      
                      {/* Farm name */}
                      <TableCell
                        onClick={() => handleSort('farm_name')}
                      >
                        Farm{sortDirection['farm_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      {/* Farm name */}
                      <TableCell
                        onClick={() => handleSort('farm_code')}
                      >
                        Farm code{sortDirection['farm_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      {/* Region */}
                      <TableCell
                        onClick={() => handleSort('farm_region')}
                      >
                        Region {sortDirection['farm_region'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      <TableCell>Edit</TableCell>
                      {/* <TableCell>Delete</TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {sortedFarmData.map((row, index) => (
                      <TableRow key={index}>

                        <TableCell onClick={() => goToFarmPage(row.farm_id)}>
                          {editingId === row.farm_id ?
                            <input 
                              value={tempData?.farm_name ?? row.farm_name} 
                              onChange={e => setTempData({...tempData, farm_name: e.target.value})}
                            /> :                              
                            row.farm_name
                          }
                        </TableCell>

                        <TableCell onClick={() => goToFarmPage(row.farm_id)}>
                          {editingId === row.farm_id ?
                            <input 
                              value={tempData?.farm_code ?? row.farm_code} 
                              onChange={e => setTempData({...tempData, farm_code: e.target.value})}
                            /> :                              
                            row.farm_code
                          }
                        </TableCell>

                        <TableCell onClick={() => goToFarmPage(row.farm_id)}>
                          {editingId === row.farm_id ?
                            <input 
                              value={tempData?.farm_region ?? row.farm_region} 
                              onChange={e => setTempData({...tempData, farm_region: e.target.value})}
                            /> :                              
                            row.farm_region
                          }
                        </TableCell>

                        <TableCell>  
                          {editingId === row.farm_id ? 
                            <>
                              <Button onClick={() => saveChanges(row.farm_id)}>Save</Button>
                              <Button onClick={() => cancelEditing()}>Cancel</Button>
                            </> :
                            <>
                              <IconButton aria-label="edit" onClick={() => editFarm(row.farm_id)}>
                                <EditIcon />
                              </IconButton>
                            </>
                          }
                        </TableCell>
                      </TableRow>
                    ))} 

                    {isAdding && (
                      <TableRow>
                        <TableCell>
                          <input 
                            value={newFarmData.farm_name} 
                            onChange={e => setNewFarmData({ ...newFarmData, farm_name: e.target.value })}
                          />
                        </TableCell>
                        <TableCell>
                          <input 
                            value={newFarmData.farm_region || ""} 
                            onChange={e => setNewFarmData({ ...newFarmData, farm_region: e.target.value })}
                          />
                        </TableCell>
                        <TableCell>
                          <Button onClick={handleAddFarm}>Save</Button>
                          <Button onClick={() => setIsAdding(false)}>Cancel</Button>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}

                  </TableBody>
                  
                </Table>
              </TableContainer>

              {/* Add farm button */}
              <Box margin="1em" display="flex" justifyContent="flex-end">
                {!isAdding && (
                  <Button 
                    variant="contained" 
                    onClick={() => setIsAdding(prev => !prev)} 
                  >
                    Add farm
                  </Button>
                )}
              </Box>

            </Grid>
          </Grid>
        </Container>

    </Box>
  );
}

export default ManageFarms; 