import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { BASE_URL } from '../../Constants';
import { getToken, getFarmId } from '../../auth/auth.js';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import FormControl from '@mui/material/FormControl'; 
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'; 
import sortData from '../common/sortColumns';

function ManageCustomers() {

    const [customerData, setCustomerData] = React.useState([]); 
    const [farms, setFarms] = React.useState([]);
    const [editingId, setEditingId] = React.useState(null);
    const [originalData, setOriginalData] = React.useState({});
    const [tempData, setTempData] = React.useState({});
    const [selectedFarm, setSelectedFarm] = React.useState('');

    const farmNames = [...new Set(customerData.map(record => record.farm_name))];

    const filteredData = selectedFarm
    ? customerData.filter(record => record.farm_name === selectedFarm)
    : customerData;

    const sortedCustomerData = sortData(filteredData);

    const [isAdding, setIsAdding] = React.useState(false);
    const [newCustomerData, setNewCustomerData] = React.useState({ 
        name: "", 
        address: "", 
        contact: "", 
        phone: "",
        email: "",
        delivery_notes: "",
        current_dish: "",
        delivery_time: "",
        farm_id: ""
    });

    const token = getToken(); 
    const farmId = getFarmId();
    const navigate = useNavigate();

    const fetchCustomers = () => { 
        fetch(`${BASE_URL}/api/readcustomers`, { 
            method: 'GET', 
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
        })
        .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
        })
        .then(data => {
        try {
            const jsonData = JSON.parse(data); 
            setCustomerData(jsonData);
            console.log(jsonData);
        } catch(e) {
            console.error("The response is not JSON. Data:", data);
        }
        })
        .catch(error => {
        console.error('Error:', error);
        });
    }

    const fetchFarms = () => { 
        return fetch(`${BASE_URL}/api/readfarms`, { 
            method: 'GET', 
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.text(); 
          })
          .then(data => {
            try {
              const jsonData = JSON.parse(data); 
              setFarms(jsonData);
            } catch(e) {
              console.error("The response is not JSON. Data:", data);
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
    }

    React.useEffect(() => {
        fetchCustomers(); 
        fetchFarms();
    }, []);

    const handleAddCustomer = () => {
        console.log(newCustomerData); // debug

        fetch(`${BASE_URL}/api/addcustomer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            name: newCustomerData.name,
            address: newCustomerData.address, 
            contact: newCustomerData.contact, 
            phone: newCustomerData.phone,
            email: newCustomerData.email,
            delivery_notes: newCustomerData.delivery_notes,
            current_dish: newCustomerData.current_dish,
            delivery_time: newCustomerData.delivery_time,
            farm_id: newCustomerData.farm_id
          }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.customer_id) {
                newCustomerData([...customerData, { customer_id: data.customer_id, ...newCustomerData }]);
            }
            setIsAdding(false);
            setNewCustomerData({         
                name: "", 
                address: "", 
                contact: "", 
                phone: "",
                email: "",
                delivery_notes: "",
                current_dish: "",
                delivery_time: "",
                farm_id: "" 
            });
            fetchCustomers();
        })
        .catch(error => {
        console.error('Error:', error);
        });
    };

    const editCustomer = (id) => {
        setEditingId(id);
    }

    const cancelEditing = () => {
        setEditingId(null);
        setTempData({});
    };

    const saveChanges = async (id) => {
        // Merge originalData with tempData, tempData values will overwrite originalData
        const dataToSend = {...originalData, ...tempData};
        console.log(dataToSend, id);
        try {
          const response = await fetch(`${BASE_URL}/api/updatecustomer/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(dataToSend), // the updated data
          });
      
          const result = await response.json();
      
          if (result.message === 'Data updated successfully') {
            // If update was successful
            setEditingId(null);
            setTempData({});
            fetchCustomers(); // refresh data
          } else {
            // If the update failed, handle it here
            console.error('Failed to update user data', result.error);
          }
        } catch (err) {
          console.error('An error occurred:', err);
        }
    };

    const navigateToCustomer = (customerId) => {
        navigate(`/customer/${customerId}`); 
    };

    const handleFarmChange = (event) => {
        setSelectedFarm(event.target.value);
    };

    return (
        <Box
            sx={{
                backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'auto',
            }}
        >
            <CssBaseline />

            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>

                    <Box 
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, max-content) 1fr auto', // Three columns: button, title, empty space
                            gap: 1, 
                            alignItems: 'center',
                            marginBottom: 2, 
                        }}
                    >
                        {/* Title */}
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', // Centers the title
                                gridColumnStart: 3, // Title starts at the third column
                                gridColumnEnd: 3, // Title ends at the third column
                            }}
                        >
                            <Typography variant="h6" component="h2" gutterBottom>
                                All customers
                            </Typography>
                        </Box>

                        {/* Farm filter */}
                        <FormControl fullWidth sx={{ gridColumnStart: 4, width: '145px' }}>
                            <InputLabel id="farm-select-label">Filter farm</InputLabel>
                            <Select
                                labelId="farm-select-label"
                                id="farm-select"
                                value={selectedFarm}
                                label="Farm"
                                onChange={handleFarmChange}
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                {farmNames.map(farmName => (
                                    <MenuItem key={farmName} value={farmName}>{farmName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <TableContainer component={Paper} style={{ maxHeight: '70vh', overflow: 'auto' }}>
                        <Table aria-label="customer table" stickyHeader>

                            <TableHead>
                                <TableRow>
                                    <TableCell>Restaurant</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>Contact/Position</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Delivery Notes</TableCell>
                                    <TableCell>Current Dish</TableCell>
                                    <TableCell>Delivery Time</TableCell>
                                    <TableCell>Farm</TableCell>
                                    <TableCell>Edit</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {sortedCustomerData.map((row, index) => (
                                    <TableRow 
                                        key={index}                             
                                        onClick={() => {
                                            // Check if the row is in edit mode before navigating
                                            if(editingId !== row.customer_id) {
                                                navigateToCustomer(row.customer_id);
                                            }
                                        }} 
                                        hover
                                        style={{ cursor: 'pointer' }}
                                    >

                                        <TableCell>
                                            {editingId === row.customer_id ? (
                                                <input 
                                                    value={tempData?.name ?? row.name}
                                                    onChange={e => setTempData({...tempData, name: e.target.value})}
                                                    placeholder="Name"
                                                />
                                            ) : (
                                                row.name
                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {editingId === row.customer_id ? (
                                                <input 
                                                    value={tempData?.address ?? row.address}
                                                    onChange={e => setTempData({...tempData, address: e.target.value})}
                                                    placeholder="Address"
                                                />
                                            ) : (
                                                row.address
                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {editingId === row.customer_id ? (
                                                <input 
                                                    value={tempData?.contact ?? row.contact}
                                                    onChange={e => setTempData({...tempData, contact: e.target.value})}
                                                    placeholder="Contact"
                                                />
                                            ) : (
                                                row.contact
                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {editingId === row.customer_id ? (
                                                <input 
                                                    value={tempData?.phone ?? row.phone}
                                                    onChange={e => setTempData({...tempData, phone: e.target.value})}
                                                    placeholder="Phone"
                                                />
                                            ) : (
                                                row.phone
                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {editingId === row.customer_id ? (
                                                <input 
                                                    value={tempData?.email ?? row.email}
                                                    onChange={e => setTempData({...tempData, email: e.target.value})}
                                                    placeholder="Email"
                                                />
                                            ) : (
                                                row.email
                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {editingId === row.customer_id ? (
                                                <input 
                                                    value={tempData?.delivery_notes ?? row.delivery_notes}
                                                    onChange={e => setTempData({...tempData, delivery_notes: e.target.value})}
                                                    placeholder="Delivery Notes"
                                                />
                                            ) : (
                                                row.delivery_notes
                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {editingId === row.customer_id ? (
                                                <input 
                                                value={tempData?.current_dish ?? row.current_dish}
                                                onChange={e => setTempData({...tempData, current_dish: e.target.value})}
                                                placeholder="Current Dish"
                                                />
                                            ) : (
                                                row.current_dish
                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {editingId === row.customer_id ? (
                                                <input 
                                                    value={tempData?.delivery_time ?? row.delivery_time}
                                                    onChange={e => setTempData({...tempData, delivery_time: e.target.value})}
                                                    placeholder="Delivery Time"
                                                />
                                            ) : (
                                                row.delivery_time
                                            )}
                                        </TableCell>

                                        <TableCell>
                                            {editingId === row.customer_id ? (
                                                <select 
                                                    value={tempData?.farm_id ?? row.farm_id}
                                                    onChange={e => setTempData({...tempData, farm_id: e.target.value})}
                                                >
                                                {/* Assuming you have a list of farms in your state to populate this dropdown */}
                                                {farms.map(farm => (
                                                    <option key={farm.farm_id} value={farm.farm_id}>{farm.farm_name}</option>
                                                ))}
                                                </select>
                                            ) : (
                                                farms.find(f => f.farm_id === row.farm_id)?.farm_name || 'Unknown Farm'
                                            )}
                                        </TableCell>
                                        
                                        {/* Edit */}
                                        <TableCell>  
                                            {editingId === row.customer_id ? 
                                                <>
                                                    <Button onClick={(event) => {
                                                        event.stopPropagation(); // Prevent row click
                                                        saveChanges(row.customer_id);
                                                    }}>Save</Button>
                                                    <Button onClick={(event) => {
                                                        event.stopPropagation(); // Prevent row click
                                                        cancelEditing();
                                                    }}>Cancel</Button>
                                                </> :
                                                <>
                                                    <IconButton 
                                                        aria-label="edit" 
                                                        onClick={(event) => {
                                                            event.stopPropagation(); // Prevent row click
                                                            editCustomer(row.customer_id);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </>
                                            }
                                        </TableCell>

                                    </TableRow>
                                ))}

                                {isAdding && (
                                    <TableRow>

                                        <TableCell>
                                            <input 
                                                value={newCustomerData.name} 
                                                onChange={e => setNewCustomerData({ ...newCustomerData, name: e.target.value })}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <input 
                                                value={newCustomerData.address} 
                                                onChange={e => setNewCustomerData({ ...newCustomerData, address: e.target.value })}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <input 
                                                value={newCustomerData.contact} 
                                                onChange={e => setNewCustomerData({ ...newCustomerData, contact: e.target.value })}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <input 
                                                value={newCustomerData.phone} 
                                                onChange={e => setNewCustomerData({ ...newCustomerData, phone: e.target.value })}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <input 
                                                value={newCustomerData.email} 
                                                onChange={e => setNewCustomerData({ ...newCustomerData, email: e.target.value })}
                                            />
                                        </TableCell>         

                                        <TableCell>
                                            <input 
                                                value={newCustomerData.delivery_notes} 
                                                onChange={e => setNewCustomerData({ ...newCustomerData, delivery_notes: e.target.value })}
                                            />
                                        </TableCell>  

                                        <TableCell>
                                            <input 
                                                value={newCustomerData.current_dish} 
                                                onChange={e => setNewCustomerData({ ...newCustomerData, current_dish: e.target.value })}
                                            />
                                        </TableCell>  

                                        <TableCell>
                                            <input 
                                                value={newCustomerData.delivery_time} 
                                                onChange={e => setNewCustomerData({ ...newCustomerData, delivery_time: e.target.value })}
                                            />
                                        </TableCell>       

                                        <TableCell>
                                            <select
                                                value={newCustomerData.farm_id}
                                                onChange={e => setNewCustomerData({ ...newCustomerData, farm_id: e.target.value })}
                                            >
                                                <option value="">Select a farm</option>
                                                {farms.map((farm, index) => (
                                                    <option key={index} value={farm.farm_id}>
                                                        {farm.farm_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </TableCell>

                                        <TableCell>
                                            <Button onClick={handleAddCustomer}>Save</Button>
                                            <Button onClick={() => setIsAdding(false)}>Cancel</Button>
                                        </TableCell>

                                        <TableCell></TableCell>

                                    </TableRow>
                                )} 

                            </TableBody>
                        
                        </Table>
                    </TableContainer>

                    {/* Add customer button */}
                    <Box margin="1em" display="flex" justifyContent="flex-end">
                        {!isAdding && (
                            <Button 
                                variant="contained" 
                                onClick={() => setIsAdding(prev => !prev)} 
                            >
                                Add customer
                            </Button>
                        )}
                    </Box>

                    </Grid>
                </Grid>
            </Container>

        </Box>
    );
}

export default ManageCustomers;