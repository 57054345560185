import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { ToastContainer, toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button'; 
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';
import sortData from '../common/sortColumns';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function ManageRegions() {
  const [regionData, setRegionData] = React.useState([]); 
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [editingId, setEditingId] = React.useState(null);
  const [originalData, setOriginalData] = React.useState({});
  const [tempData, setTempData] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [newRegionData, setNewRegionData] = React.useState({ region_name: "" });
  const [sortField, setSortField] = React.useState('');

  const [sortDirection, setSortDirection] = React.useState({
    farm_region: 'desc',
  }); 

  const sortedRegionData = sortData(regionData, sortField, sortDirection[sortField]);

  const token = getToken(); 

  const fetchRegions = () => {
    fetch(`${BASE_URL}/api/readregions`, { 
        method: 'GET', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setRegionData(jsonData);
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error); 
      });
  }

  React.useEffect(() => {
    fetchRegions(); 
  }, []); // fetch regions

  // const deleteRegion = (id) => { 
  //   setSelectedId(id);
  //   setOpenDialog(true); 
  // }

  // const handleConfirmDelete = () => {
  //   setOpenDialog(false);

  //   console.log(selectedId);
  
  //   fetch(`${BASE_URL}/api/deleteregion/${selectedId}`, { 
  //     method: 'PUT', 
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`
  //     },
  //   })
  //   .then(response => {
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     } else {
  //       toast.success('Region successfully deleted!', {
  //         autoClose: 1000, // Toast notification will close in 1 second
  //       });
  //       // Refresh the data
  //       return fetchRegions(); 
  //     }
  //   })
  //   .catch(error => {
  //     console.error('Error:', error);
  //     toast.error('An error occurred while trying to delete the record');
  //   });
  // }
  
  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // }

  const editRegion = (id) => {
    setEditingId(id);
  }

  const cancelEditing = () => {
    setEditingId(null);
    setTempData({});
  };

  React.useEffect(() => {
    if (editingId !== null) {
      const rowData = regionData.find(region => region.region_id === editingId);
      setOriginalData(rowData || {});
      setTempData(rowData || {});
    }
  }, [editingId, regionData]);

  const saveChanges = async (id) => {
    // Merge originalData with tempData, tempData values will overwrite originalData
    const dataToSend = {...originalData, ...tempData};
    try {
      const response = await fetch(`${BASE_URL}/api/updateregion/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend), // the updated data
      });
  
      const result = await response.json();
  
      if (result.message === 'Data updated successfully') {
        // If update was successful
        setEditingId(null);
        setTempData({});
        fetchRegions(); // refresh data
      } else {
        // If the update failed, handle it here
        console.error('Failed to update region data', result.error);
      }
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  const handleAddRegion = () => {
    fetch(`${BASE_URL}/api/addregion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        region_name: newRegionData.region_name,
      }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.region_id) {
        setRegionData([...regionData, { region_id: data.region_id, ...newRegionData }]);
      }
      setIsAdding(false);
      setNewRegionData({ region_name: "" });
      fetchRegions()
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const handleSort = (field) => {
    // Toggle the sort direction for the specific field
    const newDirection = sortDirection[field] === 'asc' ? 'desc' : 'asc';
  
    // Update the sort directions state
    setSortDirection({
      ...sortDirection,
      [field]: newDirection
    });
  
    // Set the sort field
    setSortField(field);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />
        <ToastContainer position="top-center" />
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>

              <Box sx={{
                display: 'flex',
                justifyContent: 'center', 
              }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  All regions
                </Typography>
              </Box>

              <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>
                <Table aria-label="region table" stickyHeader>
                  
                  <TableHead>
                    <TableRow>

                      <TableCell
                        onClick={() => handleSort('region_name')}
                      >
                        Region{sortDirection['region_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      <TableCell>Edit</TableCell>
                      {/* <TableCell>Delete</TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {sortedRegionData.map((row, index) => (
                      <TableRow key={index}>

                        <TableCell>
                          {editingId === row.region_id ?
                            <input 
                              value={tempData?.region_name ?? row.region_name} 
                              onChange={e => setTempData({...tempData, region_name: e.target.value})}
                            /> : 
                            row.region_name
                          }
                        </TableCell>


                        <TableCell>  
                          {editingId === row.region_id ? 
                            <>
                              <Button onClick={() => saveChanges(row.region_id)}>Save</Button>
                              <Button onClick={() => cancelEditing()}>Cancel</Button>
                            </> :
                            <>
                              <IconButton aria-label="edit" onClick={() => editRegion(row.region_id)}>
                                <EditIcon />
                              </IconButton>
                            </>
                          }
                        </TableCell>

                        {/* <TableCell>  
                          <IconButton aria-label="delete" onClick={() => deleteRegion(row.region_id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell> */}

                      </TableRow>
                    ))} 

                    {isAdding && (
                      <TableRow>
                        <TableCell>
                          <input 
                            value={newRegionData.region_name} 
                            onChange={e => setNewRegionData({ ...newRegionData, region_name: e.target.value })}
                          />
                        </TableCell>
                        <TableCell>
                          <Button onClick={handleAddRegion}>Save</Button>
                          <Button onClick={() => setIsAdding(false)}>Cancel</Button>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}

                  </TableBody>
                </Table>
              </TableContainer>

              {/* Add region button */}
              <Box margin="1em" display="flex" justifyContent="flex-end">
                {!isAdding && (
                  <Button 
                    variant="contained" 
                    onClick={() => setIsAdding(prev => !prev)} 
                  >
                    Add region
                  </Button>
                )}
              </Box>

            </Grid>
          </Grid>
        </Container>

        {/* <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle>{"Delete region"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this region?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
      </Dialog> */}

    </Box>
  );
}

export default ManageRegions;