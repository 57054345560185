const numericSort = (a, b, field, direction) => {
    return direction === 'asc' ? a[field] - b[field] : b[field] - a[field];
};

const dateSort = (a, b, field, direction) => {
    const dateA = new Date(a[field]);
    const dateB = new Date(b[field]);

    return direction === 'asc' ? dateA - dateB : dateB - dateA;
};

const stringSort = (a, b, field, direction) => {
    if (direction === 'asc') {
        return a[field].localeCompare(b[field]);
    } else {
        return b[field].localeCompare(a[field]);
    }
};

// Main sorting handler
const sortData = (data, field, direction) => {
    if (!field) return [...data]; 
    
    const sortFunction = sortFunctions[field] || numericSort;  // default to numericSort if no specific function is found
    return [...data].sort((a, b) => sortFunction(a, b, field, direction));
};

const sortFunctions = {
    'num_of_blocks': numericSort,
    'num_of_blocks': numericSort,
    'flush_number': numericSort,
    'inoculation_date': dateSort,
    'cut_date': dateSort,
    'flush_date': dateSort,
    'strain_name': stringSort,
    'farm_name': stringSort,
    'shipment_date': stringSort,
    'received_date': stringSort,
    'last_name': stringSort,
    'farm_region': stringSort,
    'email': stringSort,
    'region_name': stringSort,
    'source_name': stringSort,
};
  
export default sortData;