import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { ToastContainer, toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';
import sortData from '../common/sortColumns';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function ManageUsers() {
  const [userData, setUserData] = React.useState([]); 
  const [farms, setFarms] = React.useState([]); // array to hold farm data from API
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [editingId, setEditingId] = React.useState(null);
  const [originalData, setOriginalData] = React.useState({});
  const [tempData, setTempData] = React.useState({});
  const [sortField, setSortField] = React.useState('');

  const [sortDirection, setSortDirection] = React.useState({
    last_name: 'desc',
    farm_name: 'desc',
    farm_region: 'desc',
    email: 'desc',
  }); 

  const sortedUserData = sortData(userData, sortField, sortDirection[sortField]);

  const navigate = useNavigate();
  const token = getToken(); 

  const fetchUsers = () => {
    fetch(`${BASE_URL}/api/readusers`, { 
        method: 'GET', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data);
          const usersWithFarmData = jsonData.map(user => ({
            ...user,
            farm_name: farms.find(farm => farm.id === user.farm_id)?.name || 'N/A',
            farm_region: farms.find(farm => farm.id === user.farm_id)?.region || 'N/A', // Assuming you have 'region' in your farms data
          }));
          setUserData(usersWithFarmData);
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  React.useEffect(() => {
    fetchUsers();   
  }, []); // fetch users

  React.useEffect(() => {
    fetch(`${BASE_URL}/api/readfarms`, {
      headers: {
        Authorization: `Bearer ${token}` 
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      const mappedFarms = data.map(farm => ({id: farm.farm_id, name: farm.farm_name, region: farm.farm_region}));
      setFarms(mappedFarms);
    })
    .catch(error => console.error('Error:', error));
  }, []); // fetch farms

  React.useEffect(() => {
    if (farms.length > 0) {
      fetchUsers();
    }
  }, [farms]);

  const deleteUser = (id) => { 
    setSelectedId(id); 
    setOpenDialog(true); 
  }

  const handleConfirmDelete = () => {
    setOpenDialog(false);
  
    fetch(`${BASE_URL}/api/deleteuser/${selectedId}`, { 
      method: 'PUT', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        toast.success('User successfully deleted!', {
          autoClose: 1000, // Toast notification will close in 1 second
        });
        // Refresh the data
        return fetchUsers(); 
      }
    })
    .catch(error => {
      console.error('Error:', error);
      toast.error('An error occurred while trying to delete the user');
    });
  }
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const editUser = (id) => {
    setEditingId(id);
  }

  const cancelEditing = () => {
    setEditingId(null);
    setTempData({});
  };

  React.useEffect(() => {
    if (editingId !== null) {
      const rowData = userData.find(user => user.user_id === editingId);
      setOriginalData(rowData || {});
      setTempData(rowData || {});
    }
  }, [editingId, userData]);

  const saveChanges = async (id) => {
    // Merge originalData with tempData, tempData values will overwrite originalData
    const dataToSend = {...originalData, ...tempData};
    try {
      const response = await fetch(`${BASE_URL}/api/updatemanageuser/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend), // the updated data
      });
  
      const result = await response.json();
  
      if (result.message === 'Data updated successfully') {
        // If update was successful
        setEditingId(null);
        setTempData({});
        fetchUsers(); // refresh data
      } else {
        // If the update failed, handle it here
        console.error('Failed to update user data', result.error);
      }
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  const handleAddUser = () => {
    navigate('/adduser');
  };

  const handleSort = (field) => {
    // Toggle the sort direction for the specific field
    const newDirection = sortDirection[field] === 'asc' ? 'desc' : 'asc';
  
    // Update the sort directions state
    setSortDirection({
      ...sortDirection,
      [field]: newDirection
    });
  
    // Set the sort field
    setSortField(field);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />
      <ToastContainer position="top-center" />
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>

              <Box sx={{
                display: 'flex',
                justifyContent: 'center', 
              }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  All users
                </Typography>
              </Box>

              <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>

                <Table aria-label="user table" stickyHeader>

                  <TableHead>
                    <TableRow>

                      {/* Name */}
                      <TableCell 
                        align="right"
                        onClick={() => handleSort('last_name')}
                      >
                        Name {sortDirection['last_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      {/* Farm */}
                      <TableCell 
                        align="right"
                        onClick={() => handleSort('farm_name')}
                      >
                        Farm {sortDirection['farm_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      {/* Location */}
                      <TableCell 
                        align="right"
                        onClick={() => handleSort('farm_region')}
                      >
                        Location {sortDirection['farm_region'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      {/* Email */}
                      <TableCell
                        onClick={() => handleSort('email')}
                      >
                        E-Mail {sortDirection['email'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>


                      <TableCell>Edit</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {sortedUserData.map((row, index) => (
                      <TableRow key={index}>

                        {/* Name */}
                        <TableCell>
                          {editingId === row.user_id ? (
                            <>
                              <input 
                                value={tempData?.first_name ?? row.first_name} 
                                onChange={e => setTempData({...tempData, first_name: e.target.value})}
                                placeholder="First Name"
                              />
                              <input 
                                value={tempData?.last_name ?? row.last_name} 
                                onChange={e => setTempData({...tempData, last_name: e.target.value})}
                                placeholder="Last Name"
                              />
                            </>
                          ) : (
                            `${row.first_name} ${row.last_name}`
                          )}
                        </TableCell>

                        {/* Farm */}
                        <TableCell>
                          {editingId === row.user_id ?
                            <select 
                              value={tempData?.farm_id ?? row.farm_id} 
                              onChange={e => setTempData({...tempData, farm_id: e.target.value})}
                              style={{ position: 'relative' }}
                            >
                              {farms.map((farm) => (
                                <option value={farm.id} key={farm.id}>
                                  {farm.name}
                                </option>
                              ))}
                            </select> :
                            (row.farm_id ? row.farm_name : "N/A") // Check if farm_id exists. If not, display "N/A".
                          }
                        </TableCell>

                        {/* Location */}
                        <TableCell>
                          {editingId === row.user_id ?
                            <input 
                              value={tempData?.farm_region ?? row.farm_region} 
                              onChange={e => setTempData({...tempData, farm_region: e.target.value})}
                            /> :                              
                            (row.farm_id ? row.farm_region : "N/A")
                          }
                        </TableCell>

                        {/* Email */}
                        <TableCell>
                          {editingId === row.user_id ?
                            <input 
                              value={tempData?.email ?? row.email} 
                              onChange={e => setTempData({...tempData, email: e.target.value})}
                            /> :                              
                            row.email
                          }
                        </TableCell>

                        {/* Edit */}
                        <TableCell>  
                          {editingId === row.user_id ? 
                            <>
                              <Button onClick={() => saveChanges(row.user_id)}>Save</Button>
                              <Button onClick={() => cancelEditing()}>Cancel</Button>
                            </> :
                            <>
                              <IconButton aria-label="edit" onClick={() => editUser(row.user_id)}>
                                <EditIcon />
                              </IconButton>
                            </>
                          }
                        </TableCell>

                        {/* Delete */}
                        <TableCell>  
                          <IconButton aria-label="delete" onClick={() => deleteUser(row.user_id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>

                      </TableRow>
                    ))} 
                  </TableBody>

                </Table>

              </TableContainer>

              {/* Add User button */}
              <Box margin="1em" display="flex" justifyContent="flex-end">
                <Button 
                  variant="contained" 
                  onClick={handleAddUser} 
                >
                  Add User
                </Button>
              </Box>

          </Grid>
        </Grid>
      </Container>

      <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      >
        <DialogTitle>{"Delete user"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ManageUsers;