import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; 
import TotalYieldsGrid from './TotalYieldsGrid';
import RecentActivity from './RecentActivity';
import InventoryPredictions from './InventoryPredictions';
import FarmYieldsLineChartv2 from './FarmYieldsLineChartv2';
import MatrixTable from './MatrixTable';
import InventoryBarChart from './InventoryBarChart';
import NewYieldPerBlock from './NewYieldPerBlock';

const mdTheme = createTheme();

function DashboardOld() {
  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
        }}
      >
        <CssBaseline />
        <Toolbar />

        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}> 
            <Grid item xs={12}> 
              <Box >
                <NewYieldPerBlock />
              </Box>
            </Grid>
            {/* <Grid item xs={12}> 
              <Box >
                <TotalYieldsGrid percentage={70.5} color="primary" />
              </Box>
            </Grid>
            <Grid item xs={12}> 
              <Box sx={{ mt: 2 }}>
                <InventoryBarChart />
              </Box>
            </Grid>
            <Grid item xs={12}> 
              <Box sx={{ mt: 2 }}>
                <FarmYieldsLineChartv2 />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <MatrixTable />
              </Box>
            </Grid>
            <Grid item xs={12}> 
              <InventoryPredictions />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <RecentActivity />
              </Box>
            </Grid> */}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default DashboardOld;