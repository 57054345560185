import jwt_decode from 'jwt-decode';

export function getToken() {
  return  sessionStorage.getItem('token') || localStorage.getItem('token');
}

export function getUserId() {
  return  sessionStorage.getItem('userId') || localStorage.getItem('userId');
} 

export function getFarmId() {
  const token = getToken();
  const decodedToken = jwt_decode(token); 
  const farmId = decodedToken.farm_id; 
  return farmId;
} 

export function verifyToken() {
  const token = getToken();

  if(!token) {
    return false;
  }

  try {
    const decoded = jwt_decode(token);

    // Check if the token has expired
    if(decoded.exp < Date.now() / 1000) {
      return false;
    }

    return true;
  } catch(err) {
    return false;
  }
}