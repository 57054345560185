import React, { useState, useEffect } from 'react';
import { 
    Alert,
    Box,
    Button, 
    Container, 
    CssBaseline, 
    Paper, 
    Snackbar, 
    TextField, 
    Toolbar, 
    Typography,
} from '@mui/material';
import { BASE_URL } from '../../Constants.js';
import { getToken, getUserId } from '../../auth/auth.js';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';

function LMSAddCourse() {
    const [courseName, setCourseName] = useState('');
    const [courseDescription, setCourseDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [editingCourseId, setEditingCourseId] = useState(null);
    const [courses, setCourses] = useState([]);
    const [page, setPage] = useState(1);
    const coursesPerPage = 3;
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');

    const fetchCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch courses');
            const data = await response.json();
            setCourses(data);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setError('Failed to fetch courses');
        }
    };

    const handleEdit = (course) => {
        setEditingCourseId(course.id);
        setCourseName(course.course_name);
        setCourseDescription(course.course_description || '');
    };

    const handleDelete = async (courseId) => {
        if (!window.confirm('Are you sure you want to delete this course?')) return;

        try {
            const response = await fetch(`${BASE_URL}/api/courses/${courseId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });

            if (!response.ok) throw new Error('Failed to delete course');
            
            setSuccess(true);
            setSuccessMessage('Course deleted successfully!');
            fetchCourses();
        } catch (error) {
            console.error('Error deleting course:', error);
            setError('Failed to delete course');
        }
    };

    const handleCourseSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess(false);

        if (!courseName.trim() || !courseDescription.trim()) {
            setError('Please fill in all fields');
            setIsLoading(false);
            return;
        }

        try {
            const url = editingCourseId 
                ? `${BASE_URL}/api/courses/${editingCourseId}`
                : `${BASE_URL}/api/submitcourses`;

            const response = await fetch(url, {
                method: editingCourseId ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify({
                    course_name: courseName.trim(),
                    course_description: courseDescription.trim(),
                    who_created: getUserId(),
                    date_created: new Date().toISOString().split('T')[0]
                })
            });

            if (!response.ok) throw new Error(editingCourseId ? 'Failed to update course' : 'Failed to add course');

            setSuccess(true);
            setSuccessMessage(editingCourseId ? 'Course edited successfully!' : 'Course added successfully!');
            setCourseName('');
            setCourseDescription('');
            setEditingCourseId(null);
            fetchCourses();
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    const buttonText = isLoading 
        ? 'Processing...' 
        : editingCourseId 
            ? 'Update Course' 
            : 'Add Course';

    const formTitle = editingCourseId ? 'Edit Course' : 'Add New Course';

    const handleCancelEdit = () => {
        setEditingCourseId(null);
        setCourseName('');
        setCourseDescription('');
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const indexOfLastCourse = page * coursesPerPage;
    const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
    const currentCourses = courses.slice(indexOfFirstCourse, indexOfLastCourse);

    return (
        <Box 
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'auto',
            }}
        >
            <CssBaseline />
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 4, mb: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        {formTitle}
                    </Typography>

                    <Box component="form" onSubmit={handleCourseSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="courseName"
                            label="Course Name"
                            name="courseName"
                            autoFocus
                            value={courseName}
                            onChange={(e) => setCourseName(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="courseDescription"
                            label="Course Description"
                            name="courseDescription"
                            multiline
                            rows={4}
                            value={courseDescription}
                            onChange={(e) => setCourseDescription(e.target.value)}
                        />

                        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={isLoading}
                            >
                                {buttonText}
                            </Button>
                            
                            {editingCourseId && (
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={handleCancelEdit}
                                    disabled={isLoading}
                                >
                                    Cancel Edit
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Paper>

                <Paper sx={{ p: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Existing Courses
                    </Typography>
                    {currentCourses.map(course => (
                        <Box 
                            key={course.id} 
                            sx={{ 
                                mb: 3, 
                                p: 3, 
                                border: '1px solid #ddd', 
                                borderRadius: 2,
                                backgroundColor: 'white'
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                {course.course_name}
                            </Typography>
                            <Typography 
                                variant="body1" 
                                color="text.secondary" 
                                sx={{ mb: 2 }}
                            >
                                {course.course_description}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button 
                                    onClick={() => handleEdit(course)} 
                                    variant="outlined" 
                                    startIcon={<EditIcon />}
                                    disabled={isLoading}
                                >
                                    Edit
                                </Button>
                                <Button 
                                    onClick={() => handleDelete(course.id)} 
                                    variant="outlined" 
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    disabled={isLoading}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Box>
                    ))}
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Pagination 
                            count={Math.ceil(courses.length / coursesPerPage)}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </Paper>
            </Container>

            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

            <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default LMSAddCourse;
