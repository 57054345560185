import React, { useState, useEffect, createRef } from 'react';
import Button from '@mui/material/Button';
import { Box, FormControl, TextField, Grid, MenuItem, Select, InputLabel } from '@mui/material';
import { styled } from '@mui/system';
import { BASE_URL } from '../../Constants';
import InputGroup from '../common/InputGroup';
import dayjs from 'dayjs';
import { getToken, getFarmId } from '../../auth/auth.js';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
 
const ContentWrapper = styled('div')({
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
});

function CutInput() {

  const token = getToken(); 
  const farmId = getFarmId();
  const navigate = useNavigate();

  const [cutDate, setCutDate] = useState(dayjs());
  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState('');
  const [inputGroupRefs, setInputGroupRefs] = useState([React.createRef()]);
  const [strainMappings, setStrainMappings] = useState({});
  const [firstStrain, setFirstStrain] = useState(''); 

  const [openIndex, setOpenIndex] = useState(0);

  const determineDefaultInoculationDate = (strain) => {
    return strain === 'Chestnut' || strain === 'Black King' ? dayjs().subtract(21, 'day') : dayjs().subtract(14, 'day');
  };
  
  const [inputGroups, setInputGroups] = useState([{ 
    numOfBlocks: 1, 
    strain: firstStrain, 
    inoculationDate: dayjs(), 
  }]);
  
  const spacerRefs = inputGroups.map(() => createRef());
 
  //strain mappings
  useEffect(() => {
    const fetchMappings = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/mappings`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
  
        const strainMappings = {};
        data.strain.forEach((record) => strainMappings[record.strain_name] = record.strain_id);
        setStrainMappings(strainMappings);

        // Update the first strain value
        if (data.strain.length > 0) {
          setFirstStrain(data.strain[0].strain_name);
        }
      } catch (error) {
        console.error("An error occurred while fetching the mappings:", error);
      }
    };
    fetchMappings();
  }, []);

  useEffect(() => {
    if (firstStrain) {  // Make sure firstStrain is not null or undefined
      setInputGroups(prevInputGroups => {
        const updatedInputGroups = [...prevInputGroups];
        updatedInputGroups[0].strain = firstStrain;
        return updatedInputGroups;
      });
    }
  }, [firstStrain]);  

  const fetchFarms = () => { 
    return fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setFarms(jsonData);

          // Find the farm that matches the user's farmId
          const userFarm = jsonData.find(farm => farm.farm_id === farmId);

          // If found, set that as the selectedFarm
          if (userFarm) {
            setSelectedFarm(userFarm.farm_id);
          }
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchFarms();
    };
    fetchData();
  }, []);

  const addInputGroup = () => { 
    setInputGroups(inputGroups => {
      const newInputGroups = [...inputGroups, {
        numOfBlocks: 1, 
        strain: firstStrain, 
        inoculationDate: dayjs(), 
      }];
      // update inputGroupRefs
      setInputGroupRefs(inputGroupRefs => [...inputGroupRefs, React.createRef()]);
      return newInputGroups;
    });
    setOpenIndex(inputGroups.length);
  }  

  const scrollToPreviousInputGroup = (indexToRemove) => {
    const indexToScrollTo = indexToRemove === 0 ? 0 : indexToRemove - 1;
    if (spacerRefs[indexToScrollTo].current) {
      spacerRefs[indexToScrollTo].current.scrollIntoView({
        behavior: 'smooth',
        block: 'end', // Scroll to the end of the spacer
        inline: 'nearest'
      });
    }
  };

  function removeInputGroup(index) {
    scrollToPreviousInputGroup(index);
    setTimeout(() => {
      setInputGroups(inputGroups.filter((_, i) => i !== index));
    }, 300); 
  }

  const handleInputChange = (index, newInputGroupState) => {
    setInputGroups(prevInputGroups => {
        const newInputGroups = [...prevInputGroups]; // Create a copy of the inputGroups state
        newInputGroups[index] = newInputGroupState; // Replace the state at the given index with the new state
        return newInputGroups; // Set the new state
    });
  }

  function handleCutDateChange(newCutDate) {
    setCutDate(newCutDate);
  }

  const handleSubmit = async () => {
    const farm_id = selectedFarm;  

    try {
      // Map inputGroups to new objects with `strain` replaced by `strain_id`
      const mappedInputGroups = inputGroups.map(group => ({
        ...group,
        strain: strainMappings[group.strain],  // Replace 'strain' with the corresponding id from 'strainMappings'
        cutDate: cutDate.format('YYYY-MM-DD'),  
        inoculationDate: group.inoculationDate.format('YYYY-MM-DD'),  
        farm_id: farm_id 
      }));
  
      const response = await fetch(`${BASE_URL}/api/cutinput`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(mappedInputGroups),  // Use the mappedInputGroups
      });
    
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.success) {
        navigate('/cutview');
        toast.success('Success!', {
          autoClose: 2000,  // Auto close after 2 seconds
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error("An error occurred while submitting the data:", error);
    }
  };   

  return (
    <Box 
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
        pt: '6em',

      }}
    
    >
      <ContentWrapper>

        {/* CUT DATE & FARM */}
        <Grid container spacing={1} justifyContent="center">

          {/* CUT DATE */}
          <Grid item xs={4} sx={{mb:4, ml: 10}}>
            <FormControl variant="outlined" fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date cut"
                    value={cutDate}
                    closeOnSelect={true}
                    onChange={handleCutDateChange}
                  >
                    <TextField />
                  </DatePicker>
                </LocalizationProvider>
            </FormControl>
          </Grid>

          {/* FARM SELECT */}
          <Grid item xs={5} sx={{mb:4}}>
            <FormControl fullWidth sx={{ width: '70%' }}>
              <InputLabel>Farm</InputLabel>
              <Select
                value={selectedFarm}
                onChange={(e) => setSelectedFarm(e.target.value)}
              >
                {farms.map((farm, index) => (
                  <MenuItem key={index} value={farm.farm_id}>  
                    {farm.farm_name}  
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

        </Grid>

        {inputGroups.map((inputGroup, index) =>
          <Box
            key={index}
            ref={inputGroupRefs[index]} 
            display="flex"
            alignItems="center"
            marginBottom="1em"
          >
            <InputGroup 
              index={index} 
              firstStrain={firstStrain}
              state={inputGroup} 
              isOpen={index === openIndex}
              // determineDefaultInoculationDate={determineDefaultInoculationDate} 
              onToggle={() => setOpenIndex(index === openIndex ? -1 : index)}
              onChange={(index, newInputGroupState) => handleInputChange(index, newInputGroupState)} 
              highlight={index === inputGroups.length - 1} 
            />
            
            {/* Remove button */}
            {inputGroups.length > 1 && (
              <Button 
                onClick={() => removeInputGroup(index)} 
                variant="outlined"
                sx={{
                  minWidth: "initial", 
                  width: 40, 
                  height: 40, 
                  borderRadius: "50%", // this is what makes it a circle
                  padding: 0, 
                  display: "flex", 
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">-</Typography>
            </Button>
          )}
          <div ref={spacerRefs[index]} style={{ height: '0px' }}></div> 
          </Box>
        )}

        {/* Add button */}
        <Button onClick={addInputGroup} variant="outlined">+Add</Button> 

        {/* Submit button */}
        <Box margin="1em">
          <Button variant="contained" 
          onClick={handleSubmit} 
          >Submit</Button>
        </Box>
        
      </ContentWrapper>

    </Box>
  );
  
}

export default CutInput;