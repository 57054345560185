import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Box, FormControl, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { BASE_URL } from '../../Constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper'; 
import dayjs from 'dayjs';
import { getToken, getFarmId } from '../../auth/auth.js';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Typography, Grid, InputLabel, Select, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const ContentWrapper = styled('div')({
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
});

function ReceivedInput() {

  const token = getToken(); 
  const farmId = getFarmId();
  const navigate = useNavigate();
  const location = useLocation();

  const [receivedDate, setReceivedDate] = useState(dayjs());
  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState('');
  const [strainMappings, setStrainMappings] = useState({});
  const [firstStrain, setFirstStrain] = useState(''); 
  const [isPrefilled, setIsPrefilled] = useState(false);

  const { shipmentDetails, modalShipmentDetails } = location.state  || {};

  const [tableRows, setTableRows] = useState([{
    numOfBlocks: 1,
    strain: firstStrain, 
    inoculationDate: dayjs(),
    receivedDate: dayjs(), // Add this if each row needs its own received date
    numOfBlocksReceived: 1 // Initialize this if needed
  }]);
  
  //strain mappings
  useEffect(() => {
    const fetchMappings = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/mappings`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
  
        const strainMappings = {};
        data.strain.forEach((record) => strainMappings[record.strain_name] = record.strain_id);
        setStrainMappings(strainMappings);

        // Update the first strain value
        if (data.strain.length > 0) {
          setFirstStrain(data.strain[0].strain_name);
        }
      } catch (error) {
        console.error("An error occurred while fetching the mappings:", error);
      }
    };
    fetchMappings();
  }, []);

  useEffect(() => {
    if (firstStrain && (!modalShipmentDetails || modalShipmentDetails.length === 0)) {
      setTableRows(prevTableRows => {
        const updatedTableRows = [...prevTableRows];
        updatedTableRows[0].strain = firstStrain;
        return updatedTableRows;
      });
    }
  }, [firstStrain]);

  const fetchFarms = () => { 
    return fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setFarms(jsonData);

          // Find the farm that matches the user's farmId
          const userFarm = jsonData.find(farm => farm.farm_id === farmId);

          // If found, set that as the selectedFarm
          if (userFarm) {
            setSelectedFarm(userFarm.farm_id);
          }
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchFarms();
    };
    fetchData();
  }, []);

  // prefill the farm when coming from shipments
  useEffect(() => {
    if (shipmentDetails && shipmentDetails.farm_id) {
      setSelectedFarm(shipmentDetails.farm_id);
    } else {
      // Set to the user's farm only if farms are already fetched
      const userFarm = farms.find(farm => farm.farm_id === farmId);
      if (userFarm) {
        setSelectedFarm(userFarm.farm_id);
      }
    }
  }, [shipmentDetails, farms]); // Dependency on shipmentDetails and farms

  // prefill the strain and blocks when coming from shipments
  useEffect(() => {
    if (modalShipmentDetails && modalShipmentDetails.length > 0) {
      console.log({modalShipmentDetails}); // debug
      const newTableRow = modalShipmentDetails.map(detail => ({
        numOfBlocks: detail.num_of_blocks,
        strain: detail.strain_name, 
        receivedDate: dayjs(),
        inoculationDate: detail.inoculation_date ? dayjs(detail.inoculation_date.split('T')[0]) : null,
        numOfBlocksReceived: detail.num_of_blocks, 
        isEditableInocDate: !detail.inoculation_date
      }));
      setTableRows(newTableRow);
      setIsPrefilled(true);
    } else {
      setIsPrefilled(false); 
    }
  }, [modalShipmentDetails]);

  const handleSubmit = async () => { 
    try {
      const farm_id = selectedFarm;  

      const emptyInoculationDate = tableRows.some(group => !group.inoculationDate);

      if (emptyInoculationDate) {
        toast.error('Please enter the inoculation date for all entries before submitting.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000, 
        });
        return; 
      }

      const mappedTableRows = tableRows.map(group => {
        const basicMapping = {
          ...group,
          strain: strainMappings[group.strain],  
          receivedDate: group.receivedDate.format('YYYY-MM-DD'),  
          inoculationDate: group.inoculationDate ? group.inoculationDate.format('YYYY-MM-DD') : null,
          farm_id: farm_id  
        };

        // Conditionally add shipment_id if it exists
        if (shipmentDetails && shipmentDetails.shipment_id) {
          return { ...basicMapping, shipment_id: shipmentDetails.shipment_id };
        } else {
          return basicMapping;
        }
      });

      console.log({mappedTableRows}) // debug

      const response = await fetch(`${BASE_URL}/api/receivedinput`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(mappedTableRows),  
      });
    
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log(responseData);
      if (responseData.success) {
        navigate('/receivedview');
        toast.success('Success!', {
          autoClose: 2000,  // Auto close after 2 seconds
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error("An error occurred while submitting the data:", error);
    }
  };  

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...tableRows];
    updatedRows[index][field] = value;
    setTableRows(updatedRows);
  };
  
  const handleAddRow = () => {
    const newRow = {
      numOfBlocks: 0,
      strain: firstStrain, // Assuming defaulting to the first strain is acceptable
      inoculationDate: dayjs(),
      receivedDate: dayjs(),
      numOfBlocksReceived: 1,
      isNew: true 
    };
    setTableRows([...tableRows, newRow]);
  };

  const handleDeleteRow = (index) => {
    setTableRows(tableRows.filter((_, i) => i !== index));
  };

  return (
    <Box 
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
        pt: '6em',

      }}
    
    >
      <ContentWrapper>

        {/* FARM SELECT */}
        <Grid item xs={5} sx={{mb:4}} container spacing={1} justifyContent="center">
          <FormControl fullWidth sx={{ width: '70%' }}>
            <InputLabel>Farm</InputLabel>
            <Select
              value={selectedFarm}
              onChange={(e) => setSelectedFarm(e.target.value)}
            >
              {farms.map((farm, index) => (
                <MenuItem key={index} value={farm.farm_id}>  
                  {farm.farm_name}  
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <TableContainer component={Paper} style={{ maxHeight: '80vh', maxWidth: '80vw', overflow: 'auto' }}>
          <Table aria-label="editable received table">

            <TableHead>
              <TableRow>
                <TableCell>Date Received</TableCell>
                <TableCell>Strain</TableCell>
                <TableCell>Qty shipped</TableCell>
                <TableCell>Qty received</TableCell>
                <TableCell>Inoculation Date</TableCell>
                <TableCell>Remove</TableCell> 
              </TableRow>
            </TableHead>

            <TableBody>
              {tableRows.map((row, index) => (
                <TableRow key={index}>

                  {/* Received date */}      
                  <TableCell component="th" scope="row">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={row.receivedDate}
                        onChange={(newDate) => handleInputChange(index, 'receivedDate', newDate)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </TableCell>

                  {/* Strain */} 
                  <TableCell>
                    {row.isNew || !isPrefilled ? (
                      <TextField
                        value={row.strain}
                        onChange={(e) => handleInputChange(index, 'strain', e.target.value)}
                        select
                        fullWidth
                      >
                        {Object.keys(strainMappings).map((strain) => (
                          <MenuItem key={strain} value={strain}>
                            {strain}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      row.strain
                    )}
                  </TableCell>

                  {/* Qty Shipped */}   
                  <TableCell>
                    {row.isNew || !isPrefilled ? (
                      <TextField
                        sx={{ width: '65px'}}
                        type="number"
                        value={row.numOfBlocks}
                        onChange={(e) => handleInputChange(index, 'numOfBlocks', parseInt(e.target.value, 10))}
                        inputProps={{ min: 0 }}  // Set minimum number of blocks to 1
                        onFocus={(event) => event.target.select()}
                      />
                    ) : (
                      row.numOfBlocks
                    )}
                  </TableCell>


                  {/* Qty Received */}      
                  <TableCell>
                    <TextField
                      sx={{ width: '65px'}}
                      type="number"
                      value={row.numOfBlocksReceived || 0 }
                      onChange={(e) => handleInputChange(index, 'numOfBlocksReceived', parseInt(e.target.value, 10) || 0)}
                      inputProps={{ autoFocus: true, min: 0 }}
                      onFocus={(event) => event.target.select()}
                    />
                  </TableCell>
                  
                  {/* Inoc date */}
                  <TableCell>
                    {row.isNew || !isPrefilled || row.isEditableInocDate ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={row.inoculationDate}
                          onChange={(newDate) => handleInputChange(index, 'inoculationDate', newDate)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    ) : (
                      row.inoculationDate ? row.inoculationDate.format('MMM D YYYY') : ''
                    )}
                  </TableCell>

                  {/* Delete Button */}
                  <TableCell>
                    <IconButton 
                      aria-label="delete" 
                      onClick={() => handleDeleteRow(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

          </Table>
        </TableContainer>

        {/* Submit & add button */}
        <Box 
          margin="1em"
          sx={{
            display: 'flex',
            flexDirection: 'column',  // Stack items vertically
            alignItems: 'center',     // Align items in the center horizontally
            margin: '1em'
          }}
        >
          <Button variant="outlined" onClick={handleAddRow} sx={{ mb: 1 }}>Add Row</Button>
          <Button variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
        
      </ContentWrapper>

    </Box>
  );
  
}

export default ReceivedInput;