import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import StrainsPerFarmLineChart from './StrainsPerFarmLineChart';

function FarmDashboard() {

  const token = getToken(); 
  const { farmId } = useParams();  // get the farm id from the url parameters

  const [orderData, setOrderData] = useState([]); 
  const [farmOperatorData, setFarmOperatorData] = useState([]); 
  const [farmName, setFarmName] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalOrderDetails, setModalOrderDetails] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState('');

  useEffect(() => {
    if (farmId) {
      fetchOrderData(farmId);  
      fetchFarmOperatorData(farmId);
    }
  }, [farmId]);

  const fetchFarmsAndSetSelected = async () => { 
    try {
      const response = await fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const farms = await response.json();
      const selectedFarm = farms.find(farm => farm.farm_id.toString() === farmId);
  
      if (selectedFarm) {
        setFarmName(selectedFarm.farm_name);
        // Here you can also set other details about the farm if needed
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  
  useEffect(() => {
    fetchFarmsAndSetSelected();
  }, [farmId]); // Only re-run the effect if farmId changes
  

  const fetchOrderData = (farmId) => { 
    fetch(`${BASE_URL}/api/readfarmorders/${farmId}`, { 
      method: 'GET',  
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (response.ok) {  
        return response.json(); 
      }
      throw new Error(`HTTP error! status: ${response.status}`); 
    })
    .then(data => {
      setOrderData(data);  
      if (data.length > 0) {
        setFarmName(data[0].farm_name);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const fetchModalData = () => { 
    fetch(`${BASE_URL}/api/readmodalorderdetails`, { 
      method: 'GET',  
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); 
    })
    .then(data => {
      try {
        const jsonData = JSON.parse(data); 
        setModalData(jsonData);
      } catch(e) {
        console.error("The response is not JSON. Data:", data);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const fetchFarmOperatorData = (farmId) => { 
    fetch(`${BASE_URL}/api/readfarmoperators/${farmId}`, { 
      method: 'GET',  
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (response.ok) {  
        return response.json(); 
      }
      throw new Error(`HTTP error! status: ${response.status}`); 
    })
    .then(data => {
      setFarmOperatorData(data);  
      // console.log(farmOperatorData);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  useEffect(() => {
    fetchModalData();   
  }, []); 

  const handleClickOpen = (row) => {
    console.log("Clicked row data: ", row); 
    const filteredData = modalData.filter(item => item.order_id === row.order_id);

    // Log the filtered data to see if farm_id is included
    console.log("Filtered data: ", filteredData);

    setModalOrderDetails(filteredData);
    setOpen(true);

    // If filteredData contains any records, take farm_id from the first record
    if (filteredData.length > 0) {
      const farmId = filteredData[0].farm_id;
      setSelectedRow({
        ...row,
        farm_id: farmId
      });
    } else {
      setSelectedRow({
        ...row
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditMode(false);
  };  

  const handleEditChange = (e, index, fieldName) => {
    let newModalOrderDetails = [...modalOrderDetails];
    newModalOrderDetails[index][fieldName] = fieldName === 'num_of_blocks' ? parseInt(e.target.value, 10) : e.target.value;
    setModalOrderDetails(newModalOrderDetails);
  };

  const handleSelectedRowChange = (e, fieldName) => {
    if (fieldName === 'farm_id') {
      const selectedFarmId = e.target.value;
      const farmObj = farms.find(farm => farm.farm_id == selectedFarmId); // using double equals because `e.target.value` returns a string
      setSelectedRow(prevState => ({
        ...prevState,
        farm_id: selectedFarmId,
        farm_name: farmObj.farm_name
      }));
    } else {
      setSelectedRow(prevState => ({
        ...prevState,
        [fieldName]: e.target.value
      }));
    }
  };

  const handleSubmitEdits = async () => {
    console.log('Farm ID sent:', selectedRow.farm_id);
    console.log("modalOrderDetails before PUT request:", modalOrderDetails);
    
    const response = await fetch(`${BASE_URL}/api/updateblockorder/${selectedRow.order_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        shipDate: selectedRow.ship_date,
        farmId: selectedRow.farm_id,
        modalOrderDetails: modalOrderDetails
      })
    });
    const result = await response.json();
    console.log(result.message);
    setIsEditMode(false);
    
    // Step 1: Close the modal
    handleClose();
    
    // Step 2: Refresh the data
    fetchOrderData(farmId);
    fetchModalData();
  };

  //format date bc of JavaScript time zone conversions
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return `${monthNames[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`;
  };

  return (
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
        }}
      
      >
        <CssBaseline />

          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>

                  {/* Farm operators title */}
                  <Box>
                    <Typography variant="h6" component="h2" gutterBottom>
                      {farmName} farm operators
                    </Typography>
                  </Box>

                  {/* Farm operators table */}
                  <TableContainer component={Paper} sx={{ maxWidth: '31%', margin: 0 }}>
                    <Table aria-label="farm operators table">
                      <TableBody>
                        {farmOperatorData.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.first_name} {row.last_name}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* Block orders title */}
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start', 
                  }}>
                    <Typography sx={{ mt: 4, ml: 1 }} variant="h6" component="h2" gutterBottom>
                      {farmName} block orders
                    </Typography>
                  </Box>

                  {/* Block orders table */}
                  <TableContainer component={Paper} sx={{ mb:5 }} >
                    <Table aria-label="orders table">

                      <TableHead>
                          <TableRow>
                            <TableCell>Date to ship</TableCell>
                            <TableCell>Farm</TableCell>
                            <TableCell>Total blocks</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell>Ship</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                      </TableHead>

                      <TableBody>
                        {orderData.map((row, index) => (

                          <TableRow key={index} onClick={() => handleClickOpen(row)}>

                              <TableCell>
                                {formatDate(row.ship_date.split('T')[0])}
                              </TableCell>

                              <TableCell>
                                {row.farm_name}
                              </TableCell>

                              <TableCell>    
                                {row.total_blocks}
                              </TableCell>

                              <TableCell>  
                                {row.order_notes}
                              </TableCell>

                              <TableCell>  
                                <Button 
                                  variant="contained" 
                                  // onClick={handleShip}
                                >
                                  Ship
                                </Button>
                              </TableCell>

                              <TableCell>  
                                <IconButton 
                                  aria-label="delete" 
                                  // onClick={(e) => { e.stopPropagation(); deleteOrder(row.order_id); }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>

                          </TableRow>

                        ))} 
                      </TableBody>

                    </Table>
                  </TableContainer>

                  <StrainsPerFarmLineChart selectedFarm={farmName} />

              </Grid>
            </Grid>
          </Container>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {selectedRow ? selectedRow.farm_name : ''}
            </DialogTitle>

            <DialogContent>
              <DialogContentText>
                {isEditMode ? (
                  <div>
                    <label>Ship Date: </label>
                    <input 
                      type="date" 
                      value={new Date(new Date(selectedRow.ship_date).getTime() + (4 * 60 * 60 * 1000)).toLocaleDateString('en-CA')} 
                      onChange={(e) => handleSelectedRowChange(e, 'ship_date')} 
                    />
                  </div>
                ) : (
                  `Ship Date: ${selectedRow ? new Date(new Date(selectedRow.ship_date).getTime() + (4 * 60 * 60 * 1000)).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : ''}`
                )}
              </DialogContentText>

              <TableContainer component={Paper}>
                <Table>  
                  <TableBody>
                    {modalOrderDetails.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.strain_name}</TableCell>
                        <TableCell>
                          {isEditMode ? (
                            <input type="number" defaultValue={item.num_of_blocks} onChange={(e) => handleEditChange(e, index, 'num_of_blocks')} />
                          ) : (
                            item.num_of_blocks
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>  
              </TableContainer>
            </DialogContent>

            <DialogActions>
              {isEditMode ? (
                <Button onClick={handleSubmitEdits}>Save</Button>
              ) : (
                <Button onClick={() => setIsEditMode(true)}>Edit</Button>
              )}
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>

          </Dialog>

      </Box>
  );
}

export default FarmDashboard;