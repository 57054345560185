import React, { createContext, useReducer } from "react";

// Define the initial state of the NavBar and the subheadings
const initialState = {
  openNavBar: false,
  openShipments: false,
  openReceived: false,
  openCut: false,
  openYield: false,
};

// Define a reducer function to handle state changes
const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_NAVBAR':
      return { ...state, openNavBar: !state.openNavBar };
    case 'TOGGLE_SUBHEADING':
      return { ...state, [action.payload]: !state[action.payload] };
    default:
      return state;
  }
};

export const NavBarContext = createContext();

export const NavBarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleNavBar = () => dispatch({ type: 'TOGGLE_NAVBAR' });
  const toggleSubheading = (subheading) => dispatch({ type: 'TOGGLE_SUBHEADING', payload: subheading });

  return (
    <NavBarContext.Provider value={{ ...state, toggleNavBar, toggleSubheading }}>
      {children}
    </NavBarContext.Provider>
  ); 
};
