import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { ToastContainer, toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';
import sortData from '../common/sortColumns';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function ManageSources() {
  const [sourceData, setSourceData] = React.useState([]); 
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [editingId, setEditingId] = React.useState(null);
  const [originalData, setOriginalData] = React.useState({});
  const [tempData, setTempData] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [newSourceData, setNewSourceData] = React.useState({ source_name: "" });
  const [sortField, setSortField] = React.useState('');

  const [sortDirection, setSortDirection] = React.useState({
    source_name: 'desc',
  }); 

  const sortedSourceData = sortData(sourceData, sortField, sortDirection[sortField]);

  const navigate = useNavigate();
  const token = getToken(); 

  const fetchSources = () => {
    fetch(`${BASE_URL}/api/readsources`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setSourceData(jsonData);
          console.log (sourceData); // debug
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  React.useEffect(() => {
    fetchSources(); 
  }, []); // fetch Source

  const deleteSource = (id) => { 
    setSelectedId(id);
    setOpenDialog(true); 
  }

  const handleConfirmDelete = () => {
    setOpenDialog(false);
  
    fetch(`${BASE_URL}/api/deletesource/${selectedId}`, { 
      method: 'PUT', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        toast.success('Source successfully deleted!', {
          autoClose: 1000, // Toast notification will close in 1 second
        });
        // Refresh the data
        return fetchSources(); 
      }
    })
    .catch(error => {
      console.error('Error:', error);
      toast.error('An error occurred while trying to delete the record');
    });
  }
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const editSource = (id) => {
    setEditingId(id);
  }

  const cancelEditing = () => {
    setEditingId(null);
    setTempData({});
  };

  React.useEffect(() => {
    if (editingId !== null) {
      const rowData = sourceData.find(source => source.source_id === editingId);
      setOriginalData(rowData || {});
      setTempData(rowData || {});
    }
  }, [editingId, sourceData]);

  const saveChanges = async (id) => {
    // Merge originalData with tempData, tempData values will overwrite originalData
    const dataToSend = {...originalData, ...tempData};
    try {
      const response = await fetch(`${BASE_URL}/api/updatesource/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend), // the updated data
      });
  
      const result = await response.json();
  
      if (result.message === 'Data updated successfully') {
        // If update was successful
        setEditingId(null);
        setTempData({});
        fetchSources(); // refresh data
      } else {
        // If the update failed, handle it here
        console.error('Failed to update source data', result.error);
      }
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  const handleAddSource = () => {
    fetch(`${BASE_URL}/api/addsource`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        source_name: newSourceData.source_name,
      }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.source_id) {
        setSourceData([...sourceData, { source_id: data.source_id, ...newSourceData }]);
      }
      setIsAdding(false);
      setNewSourceData({ source_name: "" });
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const handleSort = (field) => {
    // Toggle the sort direction for the specific field
    const newDirection = sortDirection[field] === 'asc' ? 'desc' : 'asc';
  
    // Update the sort directions state
    setSortDirection({
      ...sortDirection,
      [field]: newDirection
    });
  
    // Set the sort field
    setSortField(field);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />
        <ToastContainer position="top-center" />
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>

              <Box sx={{
                display: 'flex',
                justifyContent: 'center', 
              }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  All sources
                </Typography>
              </Box>

              <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>
                <Table aria-label="source table" stickyHeader>

                  <TableHead>
                    <TableRow>
                      <TableCell
                        onClick={() => handleSort('source_name')}
                      >
                        Source {sortDirection['source_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      <TableCell>Edit</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>

                    {sortedSourceData.map((row, index) => (
                      <TableRow key={index}>

                        <TableCell>
                          {editingId === row.source_id ?
                            <input 
                              value={tempData?.source_name ?? row.source_name} 
                              onChange={e => setTempData({...tempData, source_name: e.target.value})}
                            /> :                              
                            row.source_name
                          }
                        </TableCell>

                        <TableCell>  
                          {editingId === row.source_id ? 
                            <>
                              <Button onClick={() => saveChanges(row.source_id)}>Save</Button>
                              <Button onClick={() => cancelEditing()}>Cancel</Button>
                            </> :
                            <>
                              <IconButton aria-label="edit" onClick={() => editSource(row.source_id)}>
                                <EditIcon />
                              </IconButton>
                            </>
                          }
                        </TableCell>

                        <TableCell>  
                          <IconButton aria-label="delete" onClick={() => deleteSource(row.source_id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))} 

                    {isAdding && (
                      <TableRow>
                        <TableCell>
                          <input 
                            value={newSourceData.source_name} 
                            onChange={e => setNewSourceData({ ...newSourceData, source_name: e.target.value })}
                          />
                        </TableCell>
                        <TableCell>
                          <Button onClick={handleAddSource}>Save</Button>
                          <Button onClick={() => setIsAdding(false)}>Cancel</Button>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}

                  </TableBody>

                </Table>
              </TableContainer>

              {/* Add User button */}
              <Box margin="1em" display="flex" justifyContent="flex-end">
                {!isAdding && (
                  <Button 
                    variant="contained" 
                    onClick={() => setIsAdding(prev => !prev)} 
                  >
                    Add Source
                  </Button>
                )}
              </Box>

            </Grid>
          </Grid>
        </Container>

        <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        >
          <DialogTitle>{"Delete source"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this source?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

    </Box>
  );
}

export default ManageSources;