import React, { useState, useEffect } from 'react';
import { Box, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { BASE_URL } from '../../Constants';
import { getToken, getUserId } from '../../auth/auth.js';

const videos = [
  {id: 1, title: "Morning Walkthrough", url: "https://www.youtube.com/embed/o9svMksFOKM", duration: 930},
  {id: 2, title: "Block Cutting (BO, YO, PO, BK, CN)", url: "https://www.youtube.com/embed/ciQMyezk1To", duration: 990},
  {id: 3, title: "Block Cutting Lions Mane (V1)", url: "https://www.youtube.com/embed/qfAtu_fi0gs", duration: 550},
  {id: 4, title: "Block Lions Mane (V2)", url: "https://www.youtube.com/embed/942TZYF05Pw", duration: 680},
  {id: 5, title: "Harvesting + Recognizing SOG - BK", url: "https://www.youtube.com/embed/2R5efkMVZm4", duration: 790},
  {id: 6, title: "Harvesting + Recognizing SOG - YO", url: "https://www.youtube.com/embed/T7uoVeCsS8c", duration: 750},
  {id: 7, title: "Harvesting + Recognizing SOG - BO", url: "https://www.youtube.com/embed/JBG5doQ1V5o", duration: 570},
  {id: 8, title: "Harvesting + Recognizing SOG - LM", url: "https://www.youtube.com/embed/06BbowDSqQg", duration: 750},
  {id: 9, title: "Harvesting + Recognizing SOG - CN", url: "https://www.youtube.com/embed/X3p8D1Z0i4s", duration: 480},
  {id: 10, title: "Harvesting + Recognizing SOG - PO", url: "https://www.youtube.com/embed/emgxJ5amg1c", duration: 130},
  {id: 11, title: "Packaging for Storage - LM", url: "https://www.youtube.com/embed/SzoRmv8FQW8", duration: 960},
  {id: 12, title: "Packaging for Storage - (YO, BO, LM, PO, KO, CN)", url: "https://www.youtube.com/embed/BJmYfM6anCs", duration: 605},
  {id: 13, title: "Wholesale Packaging", url: "https://www.youtube.com/embed/fqv6CqOd2Nc", duration: 620},
  {id: 14, title: "Retail Packaging - Lions Mane", url: "https://www.youtube.com/embed/_60T2d2FE1s", duration: 120},
  {id: 15, title: "Retail Packaging (YO)", url: "https://www.youtube.com/embed/TedczYQVuPs", duration: 35}, // WRONG DURATION
  {id: 16, title: "HVAC filter cleaning - 1 of 3", url: "https://www.youtube.com/embed/EO0qNNDecAM", duration: 35},
  {id: 17, title: "Cleaning HVAC Filter 2 of 3", url: "https://www.youtube.com/embed/9yE9s3wmvXM", duration: 240},
  {id: 18, title: "Cleaning HVAC Filter 3 of 3", url: "https://www.youtube.com/embed/Zfhv4unREFQ", duration: 70},
  {id: 19, title: "Top Down Cleaning 1", url: "https://www.youtube.com/embed/oc8oN3DIvUg", duration: 390},
  {id: 20, title: "Top Down Cleaning 2", url: "https://www.youtube.com/embed/EfLcQ6icqZY", duration: 310},
  {id: 21, title: "Top Down Cleaning 3", url: "https://www.youtube.com/embed/xgLfUqfkReM", duration: 250},
  {id: 22, title: "Top Down Cleaning 4", url: "https://www.youtube.com/embed/YuqvIHoESTo", duration: 65},
  {id: 23, title: "Top Down Cleaning 5", url: "https://www.youtube.com/embed/Ai2yjGxelpE", duration: 70}
];

function LMSVideoActivityLog() {
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserLog, setSelectedUserLog] = useState(null);

  const token = getToken();
  const userId = getUserId();

  useEffect(() => {
    fetchActivityData();
  }, []);

  const fetchActivityData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/getvideoprogress`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      
      // Group data by user
      const groupedData = data.reduce((acc, item) => {
        if (!acc[item.user_id]) {
          acc[item.user_id] = {
            user_id: item.user_id,
            first_name: item.first_name,
            last_name: item.last_name,
            videos: [],
            lastActivity: new Date(0)
          };
        }
        acc[item.user_id].videos.push(item);
        const itemDate = new Date(item.last_watched);
        if (itemDate > acc[item.user_id].lastActivity) {
          acc[item.user_id].lastActivity = itemDate;
        }
        return acc;
      }, {});

      // Calculate watched ratio and format data
      const formattedData = Object.values(groupedData).map(user => {
        const watchedVideos = user.videos.filter(v => {
          const video = videos.find(vid => vid.id === v.video_id);
          return video && v.watched_duration >= video.duration * 0.9; // Consider a video watched if 90% complete
        }).length;
        return {
          ...user,
          watchedRatio: `${watchedVideos}/${videos.length}`,
          lastActivity: user.lastActivity.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
        };
      });

      setActivityData(formattedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching video progress data:', error);
      setLoading(false);
    }
  };

  const handleViewLog = (user) => {
    setSelectedUserLog(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
      <Box
        sx={{
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'auto',
        }}

      >
        <CssBaseline />

        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <TableContainer component={Paper}>
                <Table>

                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Watched</TableCell>
                      <TableCell>Last Activity</TableCell>
                      <TableCell>Log</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {activityData.map((user) => (
                      <TableRow key={user.user_id}>
                        <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                        <TableCell>{user.watchedRatio}</TableCell>
                        <TableCell>{user.lastActivity}</TableCell>
                        <TableCell>
                          <Button onClick={() => handleViewLog(user)}>View Log</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                </Table>
              </TableContainer>
            )}
        </Container>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{`Video Log for ${selectedUserLog?.first_name} ${selectedUserLog?.last_name}`}</DialogTitle>
          
          <DialogContent>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Video Title</TableCell>
                    <TableCell>Watched Duration</TableCell>
                    <TableCell>Last Watched</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {selectedUserLog?.videos.map((video) => {
                    const videoInfo = videos.find(v => v.id === video.video_id);
                    return (
                      <TableRow key={video.video_progress_id}>
                        <TableCell>{videoInfo?.title || 'Unknown Video'}</TableCell>
                        <TableCell>{`${Math.round(video.watched_duration / 60)} / ${Math.round(videoInfo?.duration / 60 || 0)} minutes`}</TableCell>
                        <TableCell>{new Date(video.last_watched).toLocaleString()}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

          </DialogContent>
          
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>

      </Box>
  );
}

export default LMSVideoActivityLog;